import NoiseIcon from "components/Icon/icons/NoiseIcon";
import PollutionIcon from "components/Icon/icons/PollutionIcon";
import DamageIcon from "components/Icon/icons/DamageIcon";
import QuestionIcon from "components/Icon/icons/QuestionIcon";
import RequestIcon from "components/Icon/icons/RequestIcon";
import OtherIcon from "components/Icon/icons/OtherIcon";
import StatsSMS from "components/Icon/icons/StatsSMS";
import SupportIcon from "components/Icon/icons/SupportIcon";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import {
  CalendarDaysIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhotoIcon,
  PlusCircleIcon,
  TrashIcon,
  UserGroupIcon,
  UserIcon,
  XCircleIcon,
  PencilSquareIcon,
  CheckCircleIcon,
  CheckBadgeIcon,
  BellAlertIcon,
  UsersIcon,
  ChatBubbleOvalLeftIcon,
  ClockIcon,
  ExclamationCircleIcon,
  UserCircleIcon,
  ChatBubbleLeftRightIcon,
  WalletIcon,
  BuildingOfficeIcon,
  PhoneIcon,
  HandRaisedIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  QuestionMarkCircleIcon,
  EyeSlashIcon,
  EyeIcon,
  EllipsisVerticalIcon,
  DocumentDuplicateIcon,
  TagIcon as TagIconOutline,
  MapIcon,
} from "@heroicons/react/24/outline";
import {
  CheckIcon,
  HandThumbUpIcon as HandThumbUpIconSolid,
  InformationCircleIcon,
  PencilIcon,
  XMarkIcon,
  TagIcon,
} from "@heroicons/react/24/solid";
import { IoIosAttach, IoIosSend, IoMdMegaphone } from "react-icons/io";
import {
  AiFillClockCircle,
  AiFillStar,
  AiOutlineDownload,
  AiOutlineFile,
} from "react-icons/ai";
import {
  TbBellSearch,
  TbBellPlus,
  TbCalendarEvent,
  TbGauge,
  TbZoomPan,
  TbFileImport,
} from "react-icons/tb";
import {
  BsCardChecklist,
  BsClipboardX,
  BsFileEarmark,
  BsFiles,
  BsListCheck,
  BsPhoneVibrate,
  BsPinMap,
  BsTextIndentLeft,
  BsFiletypeHtml,
  BsFiletypeJpg,
  BsFiletypePng,
  BsFiletypeXls,
  BsFiletypeXlsx,
  BsFiletypePdf,
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeGif,
  BsFiletypeSvg,
  BsStar,
  BsStarFill,
  BsStarHalf,
} from "react-icons/bs";
import { VscSettings } from "react-icons/vsc";
import {
  RiChatPrivateLine,
  RiDoubleQuotesL,
  RiDoubleQuotesR,
} from "react-icons/ri";
import { GiBrokenWall, GiMineTruck, GiSpill } from "react-icons/gi";
import { BiCommentError, BiDotsHorizontal, BiDownload } from "react-icons/bi";
import {
  MdList,
  MdOutlineCategory,
  MdOutlineChecklist,
  MdOutlineUnpublished,
  MdOutlineVisibility,
} from "react-icons/md";
import {
  IoChevronForwardCircle,
  IoLocationOutline,
  IoRefreshCircle,
} from "react-icons/io5";
import { CgSpinner } from "react-icons/cg";
import { TiPin, TiPinOutline } from "react-icons/ti";

export type IconNames =
  | null
  | "NoiseIcon"
  | "PollutionIcon"
  | "DamageIcon"
  | "RequestIcon"
  | "QuestionIcon"
  | "QuestionMarkCircleIcon"
  | "OtherIcon"
  | "SMSIcon"
  | "SupportIcon"
  | "WorkSiteIcon"
  | "CalendarDaysIcon"
  | "CalendarDaysIcon"
  | "MapPinIcon"
  | "PhotoIcon"
  | "PlusCircleIcon"
  | "TrashIcon"
  | "UserGroupIcon"
  | "UserIcon"
  | "XCircleIcon"
  | "IoIosAttach"
  | "IoIosSend"
  | "AiOutlineDownload"
  | "CheckIcon"
  | "PencilIcon"
  | "XMarkIcon"
  | "PencilSquareIcon"
  | "TbZoomPan"
  | "CheckCircleIcon"
  | "IoMdMegaphone"
  | "HandThumbUpIconSolid"
  | "CheckBadgeIcon"
  | "BellAlertIcon"
  | "MdOutlineVisibility"
  | "InformationCircleIcon"
  | "AiFillStar"
  | "UsersIcon"
  | "ChatBubbleOvalLeftIcon"
  | "ClockIcon"
  | "ExclamationCircleIcon"
  | "UserCircleIcon"
  | "ChatBubbleLeftRightIcon"
  | "AiOutlineFile"
  | "BsPhoneVibrate"
  | "BsListCheck"
  | "BsFiles"
  | "BsPinMap"
  | "RiDoubleQuotesL"
  | "RiDoubleQuotesR"
  | "BsTextIndentLeft"
  | "WalletIcon"
  | "BuildingOfficeIcon"
  | "PhoneIcon"
  | "BsCardChecklist"
  | "GiBrokenWall"
  | "GiSpill"
  | "HandRaisedIcon"
  | "BiDotsHorizontal"
  | "RiChatPrivateLine"
  | "ChevronLeftIcon"
  | "ChevronRightIcon"
  | "MdOutlineUnpublished"
  | "MdOutlineChecklist"
  | "GiMineTruck"
  | "VcsSettings"
  | "MdList"
  | "BsClipboardX"
  | "IoChevronForwardCircle"
  | "AiFillClockCircle"
  | "BsFileEarmark"
  | "BsFiletypeHtml"
  | "BsFiletypeJpg"
  | "BsFiletypePng"
  | "BsFiletypeGif"
  | "BsFiletypeSvg"
  | "BsFiletypeXls"
  | "BsFiletypeXlsx"
  | "BsFiletypeDoc"
  | "BsFiletypeDocx"
  | "BsFiletypePdf"
  | "BiDownload"
  | "IoRefreshCircle"
  | "CgSpinner"
  | "EnvelopeIcon"
  | "BsStar"
  | "BsStarFill"
  | "BsStarHalf"
  | "EyeSlashIcon"
  | "EyeIcon"
  | "TbBellSearch"
  | "TbBellPlus"
  | "TbCalendarEvent"
  | "IoLocationOutline"
  | "TbGauge"
  | "Type"
  | "BiCommentError"
  | "EllipsisVerticalIcon"
  | "DocumentDuplicateIcon"
  | "TiPin"
  | "TiPinOutline"
  | "TagIconOutline"
  | "TagIcon"
  | "TbFileImport"
  | "MapIcon";

export type IconProps = {
  name: IconNames;
  className?: string;
  style?: React.CSSProperties;
};

function Icon({ name, ...rest }: IconProps) {
  switch (name) {
    case "TiPin":
      return <TiPin {...rest} />;
    case "TiPinOutline":
      return <TiPinOutline {...rest} />;
    case "TagIconOutline":
      return <TagIconOutline {...rest} />;
    case "TagIcon":
      return <TagIcon {...rest} />;
    case "DocumentDuplicateIcon":
      return <DocumentDuplicateIcon {...rest} />;
    case "EllipsisVerticalIcon":
      return <EllipsisVerticalIcon {...rest} />;
    case "EyeSlashIcon":
      return <EyeSlashIcon {...rest} />;
    case "EyeIcon":
      return <EyeIcon {...rest} />;
    case "NoiseIcon":
      return <NoiseIcon {...rest} />;
    case "PollutionIcon":
      return <PollutionIcon {...rest} />;
    case "DamageIcon":
      return <DamageIcon {...rest} />;
    case "QuestionIcon":
      return <QuestionIcon {...rest} />;
    case "RequestIcon":
      return <RequestIcon {...rest} />;
    case "OtherIcon":
      return <OtherIcon {...rest} />;
    case "SMSIcon":
      return <StatsSMS {...rest} />;
    case "SupportIcon":
      return <SupportIcon {...rest} />;
    case "WorkSiteIcon":
      return <WorkSiteIcon {...rest} />;
    case "CalendarDaysIcon":
      return <CalendarDaysIcon {...rest} />;
    case "QuestionMarkCircleIcon":
      return <QuestionMarkCircleIcon {...rest} />;
    case "MapPinIcon":
      return <MapPinIcon {...rest} />;
    case "MapIcon":
      return <MapIcon {...rest} />;
    case "PhotoIcon":
      return <PhotoIcon {...rest} />;
    case "PlusCircleIcon":
      return <PlusCircleIcon {...rest} />;
    case "TrashIcon":
      return <TrashIcon {...rest} />;
    case "UserGroupIcon":
      return <UserGroupIcon {...rest} />;
    case "UserIcon":
      return <UserIcon {...rest} />;
    case "XCircleIcon":
      return <XCircleIcon {...rest} />;
    case "IoIosAttach":
      return <IoIosAttach {...rest} />;
    case "IoIosSend":
      return <IoIosSend {...rest} />;
    case "AiOutlineDownload":
      return <AiOutlineDownload {...rest} />;
    case "CheckIcon":
      return <CheckIcon {...rest} />;
    case "PencilIcon":
      return <PencilIcon {...rest} />;
    case "XMarkIcon":
      return <XMarkIcon {...rest} />;
    case "PencilSquareIcon":
      return <PencilSquareIcon {...rest} />;
    case "TbZoomPan":
      return <TbZoomPan {...rest} />;
    case "CheckCircleIcon":
      return <CheckCircleIcon {...rest} />;
    case "IoMdMegaphone":
      return <IoMdMegaphone {...rest} />;
    case "HandThumbUpIconSolid":
      return <HandThumbUpIconSolid {...rest} />;
    case "CheckBadgeIcon":
      return <CheckBadgeIcon {...rest} />;
    case "BellAlertIcon":
      return <BellAlertIcon {...rest} />;
    case "InformationCircleIcon":
      return <InformationCircleIcon {...rest} />;
    case "AiFillStar":
      return <AiFillStar {...rest} />;
    case "UsersIcon":
      return <UsersIcon {...rest} />;
    case "ChatBubbleOvalLeftIcon":
      return <ChatBubbleOvalLeftIcon {...rest} />;
    case "ClockIcon":
      return <ClockIcon {...rest} />;
    case "ExclamationCircleIcon":
      return <ExclamationCircleIcon {...rest} />;
    case "UserCircleIcon":
      return <UserCircleIcon {...rest} />;
    case "ChatBubbleLeftRightIcon":
      return <ChatBubbleLeftRightIcon {...rest} />;
    case "AiOutlineFile":
      return <AiOutlineFile {...rest} />;
    case "BsPhoneVibrate":
      return <BsPhoneVibrate {...rest} />;
    case "BsListCheck":
      return <BsListCheck {...rest} />;
    case "BsFiles":
      return <BsFiles {...rest} />;
    case "BsPinMap":
      return <BsPinMap {...rest} />;
    case "RiDoubleQuotesL":
      return <RiDoubleQuotesL {...rest} />;
    case "RiDoubleQuotesR":
      return <RiDoubleQuotesR {...rest} />;
    case "BsTextIndentLeft":
      return <BsTextIndentLeft {...rest} />;
    case "WalletIcon":
      return <WalletIcon {...rest} />;
    case "BuildingOfficeIcon":
      return <BuildingOfficeIcon {...rest} />;
    case "PhoneIcon":
      return <PhoneIcon {...rest} />;
    case "BsCardChecklist":
      return <BsCardChecklist {...rest} />;
    case "GiBrokenWall":
      return <GiBrokenWall {...rest} />;
    case "GiSpill":
      return <GiSpill {...rest} />;
    case "HandRaisedIcon":
      return <HandRaisedIcon {...rest} />;
    case "BiDotsHorizontal":
      return <BiDotsHorizontal {...rest} />;
    case "RiChatPrivateLine":
      return <RiChatPrivateLine {...rest} />;
    case "ChevronRightIcon":
      return <ChevronRightIcon {...rest} />;
    case "ChevronLeftIcon":
      return <ChevronLeftIcon {...rest} />;
    case "MdOutlineUnpublished":
      return <MdOutlineUnpublished {...rest} />;
    case "MdOutlineChecklist":
      return <MdOutlineChecklist {...rest} />;
    case "GiMineTruck":
      return <GiMineTruck {...rest} />;
    case "VcsSettings":
      return <VscSettings {...rest} />;
    case "MdList":
      return <MdList {...rest} />;
    case "BsClipboardX":
      return <BsClipboardX {...rest} />;
    case "IoChevronForwardCircle":
      return <IoChevronForwardCircle {...rest} />;
    case "AiFillClockCircle":
      return <AiFillClockCircle {...rest} />;
    case "BsFileEarmark":
      return <BsFileEarmark {...rest} />;
    case "BsFiletypeHtml":
      return <BsFiletypeHtml {...rest} />;
    case "BsFiletypeJpg":
      return <BsFiletypeJpg {...rest} />;
    case "BsFiletypePng":
      return <BsFiletypePng {...rest} />;
    case "BsFiletypeGif":
      return <BsFiletypeGif {...rest} />;
    case "BsFiletypeSvg":
      return <BsFiletypeSvg {...rest} />;
    case "BsFiletypeXls":
      return <BsFiletypeXls {...rest} />;
    case "BsFiletypeXlsx":
      return <BsFiletypeXlsx {...rest} />;
    case "BsFiletypeDoc":
      return <BsFiletypeDoc {...rest} />;
    case "BsFiletypeDocx":
      return <BsFiletypeDocx {...rest} />;
    case "BsFiletypePdf":
      return <BsFiletypePdf {...rest} />;
    case "BiDownload":
      return <BiDownload {...rest} />;
    case "IoRefreshCircle":
      return <IoRefreshCircle {...rest} />;
    case "CgSpinner":
      return <CgSpinner {...rest} />;
    case "EnvelopeIcon":
      return <EnvelopeIcon {...rest} />;
    case "BsStar":
      return <BsStar {...rest} />;
    case "BsStarFill":
      return <BsStarFill {...rest} />;
    case "BsStarHalf":
      return <BsStarHalf {...rest} />;
    case "TbBellSearch":
      return <TbBellSearch {...rest} />;
    case "TbBellPlus":
      return <TbBellPlus {...rest} />;
    case "TbCalendarEvent":
      return <TbCalendarEvent {...rest} />;
    case "IoLocationOutline":
      return <IoLocationOutline {...rest} />;
    case "TbGauge":
      return <TbGauge {...rest} />;
    case "Type":
      return <MdOutlineCategory {...rest} />;
    case "BiCommentError":
      return <BiCommentError {...rest} />;
    case "MdOutlineVisibility":
      return <MdOutlineVisibility {...rest} />;
    case "TbFileImport":
      return <TbFileImport {...rest} />;
    default:
      return null;
  }
}

export default Icon;
