import Header from "common/components/Header/Header";
import WarningIcon from "components/Icon/icons/WarningIcon";
import { useTranslation } from "react-i18next";

function Page404() {
  const { t } = useTranslation();
  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.error")}
        subtitle={t("titleLegends.error")}
        Icon={WarningIcon}
      />

      <div className="flex gap-3 pb-8 pt-4">
        <div className="white-box w-full flex justify-center">
          <div className="flex justify-center items-center min-h-[250px]">
            <WarningIcon className="w-16 h-16 text-secondary mt-1" />

            <div className="flex flex-col ml-8 text-left text-primary max-w-[700px]">
              <p className="">{t("error.text")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page404;
