import PageLoading from "common/components/PageLoading/PageLoading";
import useRouter from "common/hooks/use-router";
import useAuth from "common/hooks/useAuth";
import useItem from "common/hooks/useItem";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import ActivityForm from "./ActivityForm";

function Activity() {
  const router = useRouter();
  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const {
    user: { id: userId },
  }: any = useAuth();

  const { item, updateItem, saveItem, error, isFetching }: any = useItem(
    "activities",
    id,
    {
      populate: ["site", "documents", "contactGroups", "attendees"],
    }
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      if (id) {
        router.navigate(`/activities/${id}`);
      } else {
        router.navigate("/activities");
      }
    }
  }, [router, error, id, setAlert, t]);

  const onSubmit = useCallback(
    async (dataActivity: any) => {
      const activity = { ...dataActivity, author: userId };

      try {
        if (activity.id) {
          await updateItem(activity.id, activity);
        } else {
          await saveItem(activity);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, saveItem, userId, back]
  );

  if (isFetching || (id && !item)) {
    return <PageLoading />;
  }

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-700 text-base">
      <ActivityForm activity={item} onSubmit={onSubmit} />
    </div>
  );
}

export default Activity;
