/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { SyntheticEvent, useCallback } from "react";
// import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "common/components/Modal/Modal";
import ReactCrop from "react-image-crop";

export type CropModalProps = {
  tmpFiles: Array<any>;
  setTmpFiles: (value: any) => void;
  confirmModal: (value: any) => void;
  closeModal: () => void;
  visible?: boolean;
};

function CropModal({
  tmpFiles,
  setTmpFiles,
  confirmModal,
  closeModal,
  visible = true,
}: CropModalProps) {
  const handleCropChange = useCallback(
    (index: number, crop: any) => {
      // Bug React Crop Image, qui appelle OnChange avec crop vide
      if (crop.width === 0 && crop.x === 0 && crop.y === 0) return;

      // eslint-disable-next-line no-param-reassign
      tmpFiles[index].crop = {
        ...crop,
        aspect: tmpFiles[index].crop.aspect,
      };
      setTmpFiles([...tmpFiles]);
    },
    [tmpFiles, setTmpFiles]
  );

  const handleImageLoaded = useCallback(
    (file: any, image: any) => {
      let width;
      let height;

      const imageRatio = image.width / image.height;

      if (!file.crop.aspect) {
        height = image.height;
        width = image.width;
      } else if (imageRatio > file.crop.aspect) {
        height = image.height;
        width = Math.round(image.height * file.crop.aspect);
      } else {
        width = image.width;
        height = Math.round(image.width / file.crop.aspect);
      }

      const crop = { ...file.crop };

      crop.width = width;
      crop.height = height;
      crop.x = Math.round((image.width - width) / 2);
      crop.y = Math.round((image.height - height) / 2);
      // eslint-disable-next-line no-param-reassign
      tmpFiles[file.index].crop = crop;
      // eslint-disable-next-line no-param-reassign
      tmpFiles[file.index].image = image;
      setTmpFiles([...tmpFiles]);
    },
    [tmpFiles, setTmpFiles]
  );

  return (
    <Modal
      onConfirmModal={confirmModal}
      onCloseModal={closeModal}
      visible={visible}
    >
      <div style={{ height: "100%", overflowY: "scroll" }}>
        {tmpFiles.map((file, key) => {
          return (
            <ReactCrop
              key={key}
              crop={file.crop}
              onChange={(newCrop: any) => handleCropChange(file.index, newCrop)}
              aspect={file.crop.aspect}
            >
              <img
                alt="crop"
                src={file.url}
                onLoad={(event: SyntheticEvent<HTMLImageElement, Event>) =>
                  handleImageLoaded(file, event.currentTarget)
                }
              />
            </ReactCrop>
          );
        })}
      </div>
    </Modal>
  );
}

export default CropModal;
