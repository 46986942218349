import {
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
  UserGroupIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import DatesRange from "common/components/DatesRange/DatesRange";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import StickyWrapper from "common/components/StickyWrapper/StickyWrapper";
import FilteredList from "common/components/Table/FilteredList";
import useAuth from "common/hooks/useAuth";
import useList from "common/hooks/useList";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMonitor } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Contact, SiteUser, User } from "types";
import getWording from "common/utils/wording";
import useOrganization from "common/hooks/useOrganization";

function SiteUserItem({ item }: any) {
  const { t } = useTranslation();

  const { jobPosition, site } = item as SiteUser;
  const detailLink = `/sites/${site?.id}`;
  const iconStyle = "w-4 h-4 text-white";

  return (
    <Link to={detailLink}>
      <div className="flex px-4 py-4 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
        <div className="flex relative w-14 h-14">
          <div className="absolute -top-2 -left-2">
            <WorkSiteIcon iconStyle={iconStyle} />
          </div>
          <div className="rounded-lg overflow-hidden">
            <ImageComponent
              image={
                item.site?.logo || item.site?.mainImage
                  ? ImageFromStrapiMedia(item.site.logo ?? item.site.mainImage)
                      ?.uri
                  : undefined
              }
            />
          </div>
        </div>

        <div className="flex flex-col flex-1 mt-2 justify-between pl-4 pr-6 mb-2">
          <div className="flex gap-3">
            <div className="flex-1">
              <h4 className="text-xl font-bold text-primary leading-4">
                {site?.name}
              </h4>
              <div className="flex items-center gap-1 text-sm mt-2 mb-3">
                <WalletIcon className="w-6 h-6" />
                <span className="text-lg">{jobPosition?.name}</span>
              </div>

              <div className="grid-1or2 gap-2 mt-4">
                {jobPosition?.canEditSite && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <WorkSiteIcon className="w-8 h-8 text-purple-800" />
                    <div className="flex flex-col pl-4">
                      <span className="text-sm my-1">
                        {t("rights.toEditSite", {
                          wording: getWording(true),
                        })}
                      </span>
                    </div>
                  </div>
                )}

                {(jobPosition?.canSubmitPost || jobPosition?.canManagePost) && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <CalendarDaysIcon className="w-8 h-8 text-purple-800" />
                    <div className="flex flex-col pl-4">
                      {jobPosition?.canSubmitPost && (
                        <span className="text-sm my-1">
                          {t("rights.toSubmitPost")}
                        </span>
                      )}
                      {jobPosition?.canManagePost && (
                        <span className="text-sm my-1 mt-2">
                          {t("rights.toManagePost")}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {(jobPosition?.canComment || jobPosition?.canAnswerMessage) && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <ChatBubbleLeftRightIcon className="w-8 h-8 text-purple-800" />
                    <div className="flex flex-col pl-4">
                      {jobPosition?.canComment && (
                        <span className="text-sm my-1">
                          {t("rights.toComment")}
                        </span>
                      )}
                      {jobPosition?.canAnswerMessage && (
                        <span className="text-sm my-1">
                          {t("rights.toAnswerMessage")}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {jobPosition?.canManageActivity && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <AiOutlineMonitor className="w-8 h-8 text-purple-800" />
                    <div className="flex flex-col pl-4">
                      <span className="text-sm my-1">
                        {t("rights.toManageActivity")}
                      </span>
                    </div>
                  </div>
                )}

                {jobPosition?.canManageContact && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <UserGroupIcon className="w-8 h-8 text-purple-800" />
                    <div className="flex flex-col pl-4">
                      <span className="text-sm my-1">
                        {t("rights.toManageContact")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {(site?.startDate || site?.endDate) && (
          <div className="flex flex-col justify-start items-end text-xs md:border-l pl-4 min-w-[22em] border-slate-150 gap-2">
            <div className="flex w-full flex-col text-sm text-gray-500 gap-1">
              <DatesRange
                startDate={
                  site?.startDate ? dayjs(site?.startDate).toDate() : undefined
                }
                endDate={
                  site?.endDate ? dayjs(site?.endDate).toDate() : undefined
                }
              />
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}

type SiteUserProps = {
  user?: User;
  contact?: Contact;
};

function SiteUserList({ user, contact }: SiteUserProps) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const {
    /* fetchItems: fetchSu, */
    items: siteUsers,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    /* error, */
    pageIndex,
    itemsCount,
    itemsPerPage,
  } = useList<SiteUser>("site-users", {
    defaultFilters: user
      ? {
          user: user.id,
        }
      : contact
      ? {
          contact: contact.id,
        }
      : {},
    populate: [
      "jobPosition",
      "site",
      "site.mainImage",
      "site.logo",
      "site.organization",
      "user",
      "contact",
    ],
    cachePrefix: `SiteUserList_${organization?.id || "all"}`,
  });

  const columnsSu = useMemo(
    () => [
      {
        Header: t("forms.name"),
      },
    ],
    [t]
  );
  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <StickyWrapper isHeader>
        <div className="white-box mb-6">
          <div className="relative flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center border border-primary w-14 h-14 rounded-lg">
                <WalletIcon className="text-primary w-7 h-7" />
              </div>
              <div className="flex flex-col justify-center">
                <h1 className="text-3xl font-bold text-primary leading-9">
                  {currentUser?.id === user?.id
                    ? t("titlePages.myJobPositions", {
                        wording: getWording(true),
                      })
                    : t("titlePages.sites", {
                        wording: getWording(true, true),
                      })}
                </h1>
                <span className="text-xxs uppercase">
                  {currentUser?.id === user?.id
                    ? t("titleLegends.myJobPositions", {
                        wording: getWording(),
                      })
                    : t("titleLegends.attributedJobPositions", {
                        wording: getWording(),
                      })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </StickyWrapper>

      {(user || contact) && (
        <FilteredList
          data={siteUsers ?? []}
          columns={columnsSu} // used as filter items
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
          RenderItem={SiteUserItem}
          onDelete={null}
          onNavigate={null}
          itemsCount={itemsCount}
          itemsPerPage={itemsPerPage}
          noHeader
        />
      )}
    </div>
  );
}

export default SiteUserList;
