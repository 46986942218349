import SectionTitle from "common/components/SectionTitle/SectionTitle";
import Select from "common/components/Select/Select";
import getWording from "common/utils/wording";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import fetchJSON from "common/utils/fetchJSON";
import useOrganization from "common/hooks/useOrganization";
import { format, isFirstDayOfMonth, startOfYear } from "date-fns";
import { fr as french } from "date-fns/locale";

import Icon from "components/Icon/Icon";
import DateRangePicker from "common/components/DatePicker/DateRangePicker";
import useWindowDimensions from "common/utils/Dimensions";

export default function SiteStatistics() {
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});
  const [, setIsFetching] = useState<boolean>(false);
  const { organization } = useOrganization();
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 768;

  const maxDate = new Date();
  const defaultStartDate = startOfYear(maxDate);
  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultStartDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(maxDate);

  const [step, setStep] = useState("month");
  const [site, setSite] = useState(undefined);

  const buildUrl = useMemo(() => {
    if (startDate && endDate && organization) {
      if (site) {
        return `sites/stats/${organization.id}/${site}/${format(
          startDate as Date,
          "yyyy-MM-dd"
        )}/${format(endDate as Date, "yyyy-MM-dd")}/${step}`;
      }
      return `sites/stats/${organization.id}/${format(
        startDate as Date,
        "yyyy-MM-dd"
      )}/${format(endDate as Date, "yyyy-MM-dd")}/${step}`;
    }
    return null;
  }, [startDate, endDate, site, organization, step]);

  useEffect(() => {
    if (buildUrl) {
      const getData = async () => {
        setIsFetching(true);
        const res = await fetchJSON({
          url: buildUrl,
          method: "GET",
        });
        setIsFetching(false);
        setData(res);
      };

      getData();
    }
  }, [buildUrl]);

  const stats = useMemo(
    () => [
      {
        name: t("stats.nbSite", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.sites ? data.general.sites : 0,
      },
      {
        name: t("stats.nbThreads"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.threads
            ? data.general.threads
            : 0,
      },
    ],
    [data, t]
  );

  const siteStats = useMemo(
    () => [
      {
        name: t("stats.nbThreads"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.threads
            ? data.general.threads
            : 0,
      },
      {
        name: t("stats.nbPosts", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.posts ? data.general.posts : 0,
      },
      {
        name: t("stats.nbSubscribers"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.followers
            ? data.general.followers
            : 0,
      },
    ],
    [data, t]
  );

  return (
    <>
      <div className="mt-4 white-box">
        <SectionTitle title={t(`stats.selectFilters`)} className="text-lg" />
        <div className="grid grid-cols-3 gap-8 mt-2">
          <div>
            <SectionTitle
              title={t(`titlePages.sites`, { wording: getWording() })}
              icon="WorkSiteIcon"
              className="text-base font-normal"
            />
            <SiteSelect
              value={site}
              onChange={(s: any) => {
                setSite(s);
              }}
            />
          </div>
          <div>
            <SectionTitle
              title={t(`stats.selectDateFrom`, {
                wording: getWording(),
              })}
              icon="ClockIcon"
              className="text-base font-normal"
            />
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartChange={setStartDate}
              onEndChange={setEndDate}
            />
          </div>
          <div>
            <SectionTitle
              title={t(`stats.selectInterval`, { wording: getWording() })}
              icon="CalendarDaysIcon"
              className="text-base font-normal"
            />
            <Select
              value={step}
              onChange={setStep}
              options={[
                { value: "month", label: t("stats.month") },
                { value: "week", label: t("stats.week") },
                { value: "day", label: t("stats.day") },
              ]}
              clearable={false}
            />
          </div>
        </div>
      </div>

      <dl className="mt-3 grid md:grid-flow-col gap-3 mb-3">
        {site
          ? siteStats.map((item) => (
              <div
                key={item?.name}
                className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    {item?.icon}
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    {item?.name}
                  </p>
                </dt>
                <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                  <p className="text-2xl font-semibold text-gray-900">
                    {item?.stat}
                  </p>
                </dd>
              </div>
            ))
          : stats.map((item) => (
              <div
                key={item?.name}
                className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    {item?.icon}
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    {item?.name}
                  </p>
                </dt>
                <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                  <p className="text-2xl font-semibold text-gray-900">
                    {item?.stat}
                  </p>
                </dd>
              </div>
            ))}
      </dl>
      {!site ? (
        <div className="w-full flex justify-center white-box flex-1">
          <Chart
            options={{
              chart: {
                id: "nbSiteByTypes",
                stacked: true,
              },
              title: {
                text: t("stats.nbSiteByTypes", { wording: getWording() }),
                align: "center",
                margin: 18,
                style: {
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#263238",
                },
              },
              labels:
                data && data.general && data.general.nbSiteByTypes
                  ? Object.keys(data.general.nbSiteByTypes).map((type) =>
                      t(`sites.type_${type}`)
                    )
                  : [],
              plotOptions: {
                pie: {
                  expandOnClick: false,
                },
              },
              legend: {
                position: "bottom",
              },
            }}
            series={
              data && data.general && data.general.nbSiteByTypes
                ? (Object.values(data.general.nbSiteByTypes) as number[])
                : []
            }
            type="pie"
            width="100%"
            style={{
              width: isMobileScreen ? "100%" : "60%",
            }}
          />
        </div>
      ) : (
        <div className="w-full flex flex-col white-box">
          <Chart
            options={{
              chart: {
                id: "nbThreadsByTypes",
                stacked: true,
              },
              title: {
                text: t("stats.nbThreadsByTypes", { wording: getWording() }),
                align: "center",
                margin: 18,
                style: {
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#263238",
                },
              },
              // plotOptions: {
              //   bar: {
              //     horizontal: false,
              //     columnWidth: "55%",
              //     endingShape: "rounded",
              //   },
              // },
              legend: {
                position: "bottom",
              },
              xaxis: {
                categories:
                  data?.filtered?.map((stepData: any) => {
                    const d = new Date(stepData?.date[0]);
                    if (startOfYear(d) === d) {
                      return format(d, "yyyy-MMM-dd");
                    }
                    if (isFirstDayOfMonth(d)) {
                      return format(d, "MMM-dd", { locale: french });
                    }
                    return format(d, "dd");
                  }) || [],
              },
              yaxis: {
                tickAmount: 5,
                labels: {
                  formatter: (value) => Math.round(value).toString(),
                },
              },
            }}
            series={
              data && data.filtered && data.filtered.length > 0
                ? (() => {
                    const omitProps = ["date", "week", "month"];
                    const keys = new Set<string>();

                    data.filtered?.forEach((d: any) => {
                      Object.keys(d)?.forEach((key) => {
                        if (!omitProps.includes(key)) {
                          keys.add(key);
                        }
                      });
                    });

                    const ac = Array.from(keys).reduce((acc, key) => {
                      acc[key] = data.filtered?.map((d: any) =>
                        d[key] && !omitProps.includes(key) ? d[key] : 0
                      );
                      return acc;
                    }, {} as Record<string, number[]>);

                    return Object.entries(ac)?.map(([key, values]) => ({
                      name: key,
                      data: values,
                    }));
                  })()
                : []
            }
            type="bar"
            height={isMobileScreen ? "95%" : "100%"}
          />
        </div>
      )}
    </>
  );
}
