import TooltipWrapper from "common/components/TooltipWrapper/TooltipWrapper";
import { ThreadType } from "types/ThreadType";
import ImageComponent from "../ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "../ImageFromStrapiMedia/ImageFromStrapiMedia";

interface IThreadIcon {
  type: ThreadType;
  name?: string | null;
  size?: "big" | "small";
}

const getThreadIconStyle = (size: string) => {
  const iconStyle = size === "big" ? `w-16 h-16` : `w-8 h-8`;
  const sizeContainerStyle = size === "big" ? "p-4" : "p-2";

  return { iconStyle, sizeContainerStyle };
};

const iconContainerStyle =
  " rounded-md overflow-hidden overflow-hidden shadow-lg";

function ThreadIcon({ type, size = "big" }: IThreadIcon) {
  const { iconStyle, sizeContainerStyle } = getThreadIconStyle(size);

  if (type === null) {
    return null;
  }

  return (
    <TooltipWrapper content={type?.name ? <span>{type?.name}</span> : null}>
      <div
        className={`${iconContainerStyle} ${iconStyle} ${sizeContainerStyle}`}
        style={{ backgroundColor: type?.mainColor }}
      >
        <ImageComponent
          image={
            type?.imageDarkMode
              ? ImageFromStrapiMedia(type?.imageDarkMode)?.uri
              : undefined
          }
        />
      </div>
    </TooltipWrapper>
  );
}

export default ThreadIcon;
