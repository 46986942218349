import React from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Contact } from "types";
import useOrganization from "common/hooks/useOrganization";

const processOptions = (options: Contact[]) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function ContactSelect(props: any) {
  const { organization } = useOrganization();
  return (
    <RemoteSelect
      url="contacts"
      processOptions={processOptions}
      filters={[{ name: "organization", value: organization?.id }]}
      sorts={["name:ASC"]}
      {...props}
    />
  );
}

export default ContactSelect;
