import Button from "common/components/Button/Button";

import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";

function ImportItem({ item, onDelete }: any) {
  const { user: currentUser } = useAuth();

  const { fileName, entriesCreated } = item as any;

  const { t } = useTranslation();

  return (
    <div className="flex px-4 py-4 mt-2 w-full relative bg-white rounded-lg border border-slate-150 transition-all">
      <div className="flex flex-1">
        <div className="flex flex-col flex-1 justify-between pl-4 pr-6 mb-2">
          <div className="flex mt-4 mb-2 gap-3">
            <div className="flex-1">
              <h4 className="text-xl font-bold text-primary leading-4">
                {fileName}
              </h4>
              <span className="font-bold">{`Nombre d'entrées ajoutées : ${entriesCreated}`}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-end items-end text-xs lg:border-l lg:pl-4 lg:min-w-[22em] border-slate-150 gap-2">
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!currentUser?.isSuperadmin}
                confirmMessage={t("actions.confirmDelete")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportItem;
