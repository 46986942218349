import Icon, { IconNames } from "components/Icon/Icon";

interface IStepItem {
  item: any;
  index: number;
  current: boolean;
  isLast: boolean;
}

function StepItem({ item, index, current, isLast }: IStepItem) {
  const { name, description, done } = item;

  const lineHeight = index === 0 || isLast ? "h-12" : "";
  let alignHeight = index === 0 ? "self-end" : "";
  alignHeight = isLast ? "self-start" : alignHeight;

  let iconBG = done ? "text-blue-800" : "text-slate-800/50";
  iconBG = current ? "text-green-500" : iconBG;

  const opacity = !done && !current ? "opacity-60 hover:opacity-100" : "";
  const borderColor = current
    ? "border-text-blue-800 bg-white shadow shadow-lg"
    : "border-slate-800/20";

  let stepIcon: IconNames = done ? "CheckCircleIcon" : "AiFillClockCircle";
  stepIcon = current ? "IoChevronForwardCircle" : stepIcon;

  return (
    <div className={`relative flex ${opacity}`}>
      <Icon
        name={stepIcon}
        className={`absolute w-5 h-5 -left-2 top-[calc(50%-10px)] bg-blue-100 rounded-xl ${iconBG}`}
      />
      <div className={`w-1 bg-slate-800/20 ${lineHeight} ${alignHeight}`} />
      <div className="py-1 pl-3 flex-1">
        <div
          className={`flex flex-col gap-1 border rounded-lg p-4 ${borderColor}`}
        >
          <div className="text-sm leading-tight font-bold uppercase text-blue-900">
            {name}
          </div>
          {description && (
            <div className="text-slate-800/50 leading-5  italic">
              {description}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StepItem;
