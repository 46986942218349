/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import useOrganization from "common/hooks/useOrganization";

import {
  IMenuConf,
  superadminRootMenu,
  superadminFilteredMenu,
  proMenu,
} from "conf/menu";

import OrganizationSelect from "components/select/OrganizationSelect/OrganizationSelect";
import useAuth from "common/hooks/useAuth";
import useRouter from "common/hooks/use-router";
import getWording from "common/utils/wording";
import ImageComponent from "../ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "../ImageFromStrapiMedia/ImageFromStrapiMedia";

interface IMenu {
  currentId: string;
  setSidebarOpen?: any;
}

const menuStyle = "text-white hover:bg-purple-500/20";

function Menu({ currentId, setSidebarOpen }: IMenu) {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { user: currentUser, signout } = useAuth();

  const { refreshOrganization, organization } = useOrganization();

  const handleOrganizationChange = useCallback(
    async (id: any, row: any) => {
      await refreshOrganization(row?.value || null);
      navigate("/");
    },
    [navigate, refreshOrganization]
  );

  useEffect(() => {
    const effect = async () => {
      if (currentUser && !currentUser.isAdmin && !currentUser.isSuperadmin) {
        await refreshOrganization(currentUser?.organizations?.[0]?.id);
      }
    };
    effect();
  }, [currentUser, refreshOrganization]);

  const renderMenu = (currentMenu: IMenuConf) => {
    return Object.keys(currentMenu).map((key: string, index: number) => {
      const { label, children } = currentMenu[key];

      const childrenList = children?.map(
        ({ id, Icon, label: childLabel, useWordingPlural }) => {
          const isCurrent = currentId?.includes(id);
          return (
            <Link
              key={id}
              to={`/${id}`}
              className={classNames(
                isCurrent ? "bg-gray-100 text-gray-900" : menuStyle,
                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              )}
              onClick={setSidebarOpen ? () => setSidebarOpen(false) : () => {}}
            >
              <Icon
                className={classNames(
                  isCurrent
                    ? "text-orange-500"
                    : "text-white group-hover:text-ternary",
                  "mr-3 flex-shrink-0 h-4 w-4"
                )}
                aria-hidden="true"
              />
              {useWordingPlural
                ? t(`titlePages.${childLabel}`, {
                    wording: getWording(true, true),
                  })
                : t(`titlePages.${childLabel}`)}
            </Link>
          );
        }
      );
      return (
        <div key={`menu-group${index}`}>
          <div
            className="mt-4 mb-2 text-sm text-white font-semibold"
            key={`menu-${index}`}
          >
            {t(`titleDividers.${label}`)}
          </div>
          {childrenList}
        </div>
      );
    });
  };

  return (
    <div className="sidebar rounded-lg flex flex-col flex-1 h-full">
      <div className="flex flex-col px-4 pt-0 md:pt-6 pb-0 md:pb-1 ">
        <img
          src={`/templates/${import.meta.env.VITE_TEMPLATE}/menuLogo.png`}
          className="object-contain"
          alt="Logo"
        />

        <div className="flex items-center gap-4 my-4">
          <div className="flex relative w-8 h-8 shrink-0">
            <div className="rounded-full overflow-hidden w-full">
              <ImageComponent
                image={
                  currentUser?.avatar
                    ? ImageFromStrapiMedia(currentUser.avatar)?.uri
                    : undefined
                }
              />
            </div>
          </div>
          <p className="text-base text-white text-pretty break-words m-0 max-w-[calc(100%-5rem)]">
            {t("common.welcome")} <br />
            <b> {currentUser?.name}</b>
          </p>
        </div>

        {
          // keep this condition for later
          // currentUser?.isSuperadmin && !isTrue("VITE_IS_DEDICATED_APP") && (
          <OrganizationSelect
            className="organizationMenuSelect bg-white rounded-lg"
            id={uniqueId()}
            clearable={currentUser?.isSuperadmin}
            disabled={!currentUser?.isSuperadmin}
            onChange={handleOrganizationChange}
            value={organization?.id}
          />
          /* ) */
        }
      </div>

      <div
        className="overflow-y-auto flex-1 px-4 pb-6
        scrollbar-thin scrollbar-thumb-purple-800/90 scrollbar-track-purple-200 scrollbar-thumb-rounded scrollbar-track-rounded"
      >
        {renderMenu(
          currentUser?.isAdmin || currentUser?.isSuperadmin
            ? organization?.id
              ? superadminFilteredMenu
              : superadminRootMenu
            : proMenu
        )}

        <button
          type="button"
          className="group flex items-center px-2 py-2 text-sm font-medium rounded-md mt-2  text-white bg-purple-500/20 hover:bg-purple-500/30
        w-full "
          onClick={() => {
            signout();
            navigate("/login");
          }}
        >
          <BiLogOut
            className={classNames(
              "text-white group-hover:text-ternary",
              "mr-3 flex-shrink-0 h-4 w-4"
            )}
            aria-hidden="true"
          />
          {t("titlePages.logout")}
        </button>
      </div>

      {import.meta.env.VITE_TEMPLATE !== "informez-moi" && (
        <div className="flex flex-col align-center justify-center pt-6 px-3 pb-1">
          <p className="text-white/80 text-xs text-center">
            {t("common.propulsedBy")}
          </p>
          <img
            src="/templates/informez-moi/menuLogo.png"
            className="object-contain h-16 mt-[-0.8rem]"
            alt="Logo InformezMoi"
          />
        </div>
      )}
    </div>
  );
}

export default Menu;
