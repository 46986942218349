import Header from "common/components/Header/Header";
import SupportIcon from "components/Icon/icons/SupportIcon";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Support() {
  const { t } = useTranslation();
  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.support")}
        subtitle={t("titleLegends.support")}
        Icon={SupportIcon}
      />

      <div className="flex gap-3 pb-8 pt-4">
        <div className="white-box w-full flex justify-center">
          <div className="flex justify-center items-center min-h-[250px]">
            <SupportIcon className="w-16 h-16 text-slate-400 mt-1" />

            <div className="flex flex-col ml-8 text-left text-primary max-w-[700px]">
              <p className="text-secondary">{t("support.text")}</p>
              <Link
                to="#"
                onClick={(e) => {
                  window.location.href = "mailto:support@informez-moi.fr";
                  e.preventDefault();
                }}
                className="text-xl font-bold"
              >
                {t("support.link")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
