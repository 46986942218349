import { CheckIcon } from "@heroicons/react/24/outline";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

function ValidItem() {
  return (
    <div className="bg-slate-400 group-hover:bg-primary h-4 w-4 rounded-full flex items-center justify-center transition-colors">
      <CheckIcon className="w-3 h-3 text-white" />
    </div>
  );
}

function CurrentItem() {
  return (
    <div className="bg-white h-4 w-4 rounded-full border group-hover:border-secondary border-slate-200 flex items-center justify-center">
      <div className="h-2 w-2 bg-slate-400 group-hover:bg-secondary rounded-full" />
    </div>
  );
}

function ComingItem() {
  return (
    <div className="bg-white h-4 w-4 rounded-full border border-slate-200 group-hover:border-secondary-50" />
  );
}

interface ITimelineItem {
  type: "current" | "valid" | "coming";
  step: string;
  title: string;
}

function TimelineItem({ type, step, title }: ITimelineItem) {
  let icon = null;
  switch (type) {
    case "current":
      icon = <CurrentItem />;
      break;
    case "valid":
      icon = <ValidItem />;
      break;
    case "coming":
      icon = <ComingItem />;
      break;
    default:
      break;
  }

  const content = (
    <div className="flex gap-2 leading-tight">
      <span className="font-bold">{title}</span>
      <span className="text-xs text-secondary-100 flex items-end">{step}</span>
    </div>
  );

  return <TooltipWrapper content={content}>{icon}</TooltipWrapper>;
}

function Timeline({ steps }: any) {
  const stepsDone = (steps || []).filter((s: any) => s.done);
  const stepsTodo = (steps || []).filter((s: any) => !s.done);

  const currentStep = stepsTodo[0];
  const currentStepIndex = stepsDone.length + 1;
  const stepsLength = steps?.length;
  const progressBarWidth = ((currentStepIndex - 1) / (stepsLength - 1)) * 100;

  return (
    <div className="relative h-full w-full">
      <div className="absolute h-1 w-[98%] ml-1">
        <div
          className="absolute h-1 bg-slate-300 group-hover:bg-secondary z-10 transition-colors duration-300"
          style={{ width: `${progressBarWidth}%` }}
        />
        <div className="absolute w-full group-hover:bg-secondary-50 bg-slate-100 h-1 flex delay-100 items-center justify-between z-0 transition-colors duration-300" />
      </div>
      <div className="h-1 flex items-center justify-between z-10 relative">
        {stepsDone?.map((step: any, index: number) => {
          return (
            <TimelineItem
              type="valid"
              step={`${index + 1}/${stepsLength}`}
              title={step.name}
            />
          );
        })}

        {/* current */}
        <TimelineItem
          type="current"
          step={`${currentStepIndex}/${stepsLength}`}
          title={currentStep?.name}
        />

        {stepsTodo?.map((step: any, index: number) => {
          return step.id !== currentStep.id ? (
            <TimelineItem
              type="coming"
              step={`${index + 1}/${stepsLength}`}
              title={step.name}
            />
          ) : null;
        })}
      </div>
    </div>
  );
}

export default Timeline;
