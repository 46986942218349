/* eslint-disable no-console */
import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import fetchJSON from "common/utils/fetchJSON";
import { Organization } from "types/Organization";
import useAuth from "common/hooks/useAuth";
import usePersistedState from "common/hooks/usePersistedState";

type AuthOrga = {
  organization?: Organization;
  refreshOrganization: (organizationId: any) => Promise<Organization>;
  isFetching: boolean;
};

export const OrganizationContext = createContext<AuthOrga>({} as AuthOrga);

type Props = {
  children: React.ReactNode;
};

export function OrganizationProvider({ children }: Props) {
  const [organization, setOrganization] = usePersistedState(
    null,
    "organization"
  );
  const [isFetching, setIsFetching] = useState(false);

  const { user } = useAuth();

  const refreshOrganization = useCallback(
    async (organizationId: any) => {
      try {
        setIsFetching(true);

        if (organizationId) {
          const res = await fetchJSON({
            url: `organizations/data/${organizationId}`,
            method: "GET",
          });
          if (res) {
            setOrganization(
              res
                ? {
                    ...res,
                    isMonoActivity: res?.siteTypes?.length < 2,
                  }
                : undefined
            );

            return res;
          }
        } else {
          setOrganization(undefined);
        }

        return false;
      } catch (e) {
        console.log(e);
        // if users/me is not accessible then the token expired
        return false;
      } finally {
        setIsFetching(false);
      }
    },
    [setOrganization]
  ); // pushToken

  useEffect(() => {
    const firstLoad = async () => {
      if (user) {
        if ((user.isAdmin || user.isPro) && user?.organizations?.[0]) {
          await refreshOrganization(user.organizations[0].id);
        }
      }
    };

    firstLoad();
  }, [user, refreshOrganization]);

  const value: AuthOrga = useMemo(
    () => ({
      organization,
      setOrganization,
      refreshOrganization,
      isFetching,
    }),
    [organization, setOrganization, refreshOrganization, isFetching]
  );

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
}
