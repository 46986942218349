import dayjs from "dayjs";
import Icon from "components/Icon/Icon";
import { useTranslation } from "react-i18next";

interface IPublicationHeaderInfos {
  publishedDate: Date;
  endPublishedDate?: Date;
  authorName?: string;
  label?: string;
}

function PublicationHeaderInfos({
  publishedDate,
  endPublishedDate,
  authorName,
  label,
}: IPublicationHeaderInfos) {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="flex items-center text-xs gap-1 field-title">
        <Icon name="CalendarDaysIcon" className="w-4 h-4" />
        {label || t("item.post")}
      </div>
      <div className="responsive-flex items-center gap-3">
        <div className="flex items-center">
          <Icon name="ClockIcon" className="w-4 h-4 mr-1" />
          <span className="font-bold">
            {dayjs(publishedDate).format("LLL")}
          </span>
        </div>

        {endPublishedDate && (
          <div className="flex items-center">
            <Icon name="ExclamationCircleIcon" className="w-4 h-4 mr-1" />
            <span className="font-bold">
              {dayjs(endPublishedDate).format("LLL")}
            </span>
          </div>
        )}
        {authorName && (
          <div className="flex items-center md:border-l border-slate-200 pl-3">
            <Icon name="UserCircleIcon" className="w-4 h-4 mr-1" />
            <span className="font-bold">{authorName}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default PublicationHeaderInfos;
