interface IPublicationField {
  Icon?: React.ElementType;
  title: string;
  value: string;
}

function PublicationField({ Icon, title, value }: IPublicationField) {
  return value ? (
    <div className="flex items-center gap-1">
      {Icon && <Icon className="w-3 h-3 shrink-0" />}
      <span className="shrink-0">{title} :</span>
      <span className="font-bold truncate flex-1">{value}</span>
    </div>
  ) : null;
}

export default PublicationField;
