import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

function Alert({ alert }: any) {
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowAlert(false), 3000);

    return () => clearTimeout(timer);
  }, [setShowAlert]);

  const background = () => {
    if (alert.type === "info") {
      return "bg-blue-500";
    }
    if (alert.type === "success") {
      return "bg-emerald-500";
    }
    if (alert.type === "warning") {
      return "bg-yellow-500";
    }
    if (alert.type === "danger") {
      return "bg-red-500";
    }
    return "bg-slate-500";
  };

  // here message could be either a string or an object (message = {message: string})
  const alertType = alert.type;
  const alertMessage = alert.message?.message || alert.message;

  return (
    <Transition.Root show={showAlert} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        // onExited={() => clearAlert(alert.id)}
      >
        <div
          className={`${background()} rounded-lg px-4 py-4 flex items-center`}
        >
          {alertType === "danger" || alertType === "warning" ? (
            <ExclamationCircleIcon className="text-white/60 w-4 h-4 mr-2" />
          ) : (
            <InformationCircleIcon className="text-white/60 w-4 h-4 mr-2" />
          )}
          <p className="text-white flex-1">{alertMessage}</p>
        </div>
      </Transition.Child>
    </Transition.Root>
  );
}

export default Alert;
