import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import JobPositionForm from "./JobPositionForm";

function JobPosition() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    "job-positions",
    id
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/job-positions");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataJobPosition: any) => {
      const jobPosition = { ...dataJobPosition };

      try {
        if (jobPosition.id) {
          await updateItem(jobPosition.id, jobPosition);
        } else {
          await saveItem(jobPosition);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <JobPositionForm onSubmit={onSubmit} jobPosition={item} />
  );
}

export default JobPosition;
