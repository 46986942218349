import {
  SparklesIcon,
  ClipboardDocumentListIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { ClockIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import { useTranslation } from "react-i18next";
import { BiGhost, BiHourglass, BiMobile } from "react-icons/bi";
import { BsClipboardX, BsStarFill, BsStarHalf } from "react-icons/bs";

interface IStatus {
  status?: string | null;
  label?: string;
  color?: string | null;
  icon?: any;
  small?: boolean;
}

function Status({ status, label, color, icon, small = false }: IStatus) {
  const { t } = useTranslation();

  if (!status) {
    return null;
  }

  let style = "bg-slate-500";
  let text = label;
  let Icon = icon;

  // success
  if (status === "online") {
    style = "bg-green-500";
    text = t("status.online");
    Icon = SparklesIcon;
  }

  if (status === "confirmed") {
    style = "bg-green-500";
    text = t("forms.confirmed");
    Icon = SparklesIcon;
  }

  if (status === "answered") {
    style = "bg-green-500";
    text = t("status.answered");
    Icon = SparklesIcon;
  }

  // pending
  if (status === "pending_moderation") {
    style = "bg-yellow-500";
    text = t("status.pending_moderation");
    Icon = ClipboardDocumentListIcon;
  }

  if (status === "waiting_answer") {
    style = "bg-yellow-500";
    text = t("threads.pendingSinceDuration", { duration: text });
    Icon = ClockIcon;
  }

  if (status === "unconfirmed") {
    style = "bg-yellow-500";
    text = t("forms.unconfirmed");
    Icon = ClipboardDocumentListIcon;
  }

  // promise
  if (status === "pending_publication") {
    style = "bg-blue-500";
    text = t("status.pending_publication");
    Icon = ClockIcon;
  }

  // cancel

  if (status === "closed") {
    style = "bg-slate-500";
    text = t("threads.closed");
    Icon = BsClipboardX;
  }

  if (status === "offline") {
    style = "bg-slate-500";
    text = t("status.offline");
    Icon = BiHourglass;
  }

  // roles
  if (status === "Authenticated") {
    style = "bg-blue-300";
    text = t("roles.Authenticated");
    Icon = BiMobile;
  }

  if (status === "Pro") {
    style = "bg-blue-500";
    text = t("roles.Pro");
    Icon = BsStarHalf;
  }

  if (status === "Administrateur") {
    style = "bg-blue-700";
    text = t("roles.Administrateur");
    Icon = BsStarFill;
  }

  if (status === "SuperAdmin") {
    style = "bg-blue-900";
    text = t("roles.SuperAdmin");
    Icon = BsStarFill;
  }

  // audiance

  if (status === "public") {
    style = "bg-blue-900";
    text = t("audiance.public");
    Icon = BiMobile;
  }

  if (status === "noAudiance") {
    style = "bg-slate-900";
    text = t("audiance.noAudiance");
    Icon = BiGhost;
  }

  // site type

  if (
    status === "usine" ||
    status === "worksite" ||
    status === "worksiteWithZones" ||
    status === "site" ||
    status === "theme" ||
    status === "project"
  ) {
    style = `bg-${color}-500`;
    text = t(`sites.type_${status}`);
    // Icon = icon;
  }

  if (status === "zone") {
    style = `bg-slate-800`;
    text = t(`sites.type_zone_text`, { name: text });
    // Icon = icon;
  }

  if (status === "usine" || status === "worksite" || status === "zone") {
    Icon = WorkSiteIcon;
  }

  if (status === "site" || status === "theme") {
    Icon = InformationCircleIcon;
  }

  if (status === "project") {
    Icon = UserGroupIcon;
  }

  const baseStyle = small
    ? "py-1 px-2 h-6 text-[0.8rem]"
    : "py-2 px-4 h-8 text-[1rem]";

  return (
    <div
      className={`flex items-center gap-1 font-normal leading-[0] text-center text-white rounded-full ${baseStyle} ${style} shrink-0`}
    >
      {Icon && <Icon className="w-3 h-3" />} {text}
    </div>
  );
}

export default Status;
