import { TbCalendarEvent } from "react-icons/tb";
import { BsPinMap } from "react-icons/bs";
import {
  HiOutlineBellAlert,
  HiOutlineInformationCircle,
} from "react-icons/hi2";

import Button from "common/components/Button/Button";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAuth from "common/hooks/useAuth";
import { PostType } from "types/PostType";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";

function PostTypeItem({ item, onNavigate }: any) {
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();

  const canEditItem = currentUser?.isSuperadmin;

  const {
    name,
    subtitle,
    shouldHavePlanning,
    shouldHavePerturbation,
    shouldHaveLocation,
    shouldHaveAction,
    shouldHaveAdvice,
    color,
    imageDarkMode,
  } = item as PostType;

  const editLink = `/post-types/edit/${item.id}`;

  return (
    <div className="responsive-flex-lg px-4 py-4 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem ? "pointer-events-none flex flex-1" : "flex flex-1"
        }
      >
        <div className="hidden lg:grid relative w-12 h-12 mt-2">
          <div
            className="rounded-full lg:rounded-lg overflow-hidden p-2"
            style={{ backgroundColor: color }}
          >
            <ImageComponent
              image={
                imageDarkMode
                  ? ImageFromStrapiMedia(imageDarkMode)?.uri
                  : undefined
              }
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 justify-between lg:pl-4 lg:pr-6 mb-2">
          <div className="flex mt-4 mb-2 gap-3">
            <div className="flex-1">
              <h4 className="text-xl font-bold text-primary leading-4">
                {name}
              </h4>
              <span className="text-xs font-bold text-slate-500 leading-4 text-transform: uppercase">
                {subtitle}
              </span>

              <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 mt-4">
                {shouldHavePlanning && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <TbCalendarEvent className="w-8 h-8 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      <span className="text-sm my-1">
                        {t("forms.shouldHavePlanning")}
                      </span>
                      <p className="italic text-sm">
                        {t("forms.shouldHavePlanningLegend")}
                      </p>
                    </div>
                  </div>
                )}
                {shouldHaveLocation && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <BsPinMap className="w-7 h-7 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      <span className="text-sm my-1">
                        {t("forms.shouldHaveLocation")}
                      </span>
                      <p className="italic text-sm">
                        {t("forms.shouldHaveLocationLegend")}
                      </p>
                    </div>
                  </div>
                )}
                {shouldHavePerturbation && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <HiOutlineBellAlert className="w-9 h-9 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      <span className="text-sm my-1">
                        {t("forms.shouldHavePerturbation")}
                      </span>
                      <p className="italic text-sm">
                        {t("forms.shouldHavePerturbationLegend")}
                      </p>
                    </div>
                  </div>
                )}

                {(shouldHaveAction || shouldHaveAdvice) && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <HiOutlineInformationCircle className="w-8 h-8 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      {shouldHaveAction && (
                        <span className="text-sm my-1">
                          {t("forms.shouldHaveAction")}
                        </span>
                      )}
                      {shouldHaveAdvice && (
                        <span className="text-sm my-1">
                          {t("forms.shouldHaveAdvice")}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-end items-end text-xs lg:border-l pl-4  border-slate-150 gap-2">
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostTypeItem;
