import AvatarsStack from "common/components/AvatarsStack/AvatarsStack";
import TooltipWrapper from "common/components/TooltipWrapper/TooltipWrapper";
import Icon, { IconNames } from "components/Icon/Icon";
import { User } from "types";
import { Tag } from "types/Tag";

interface IHeaderInfos {
  text?: string;
  textPlace?: string;
  isTextClickable?: boolean;
  textRedirectionLink?: string;
  textPlaceRedirectionLink?: string;
  assignedUsers?: User[];
  tags?: Tag[];
  positionIcon?: IconNames;
}

function HeaderInfos({
  text,
  textPlace,
  assignedUsers,
  tags,
  positionIcon = "WorkSiteIcon",
  isTextClickable = false,
  textRedirectionLink,
  textPlaceRedirectionLink,
}: IHeaderInfos) {
  return (
    <div className="flex gap-3 items-center">
      {text && (
        <div className="flex gap-1 items-center font-semibold text-neutral-600">
          <Icon name={positionIcon} className="w-4 h-4" />
          {isTextClickable ? (
            <a target="_blank" rel="noreferrer" href={textRedirectionLink}>
              {text}
            </a>
          ) : (
            text
          )}
        </div>
      )}

      {textPlace && textPlaceRedirectionLink && (
        <div className="flex gap-1 items-center font-semibold text-emerald-700 hover:text-emerald-600">
          <Icon name="MapIcon" className="w-4 h-4" />

          <a target="_blank" rel="noreferrer" href={textPlaceRedirectionLink}>
            {textPlace}
          </a>
        </div>
      )}

      {tags && tags?.length > 0 && (
        <div
          className={`flex items-center gap-1 ${
            text && "border-l border-slate-200 pl-3"
          }`}
        >
          <TooltipWrapper
            place="bottom"
            className="mr-1 my-1"
            content={
              tags?.length > 3 ? (
                <div className="flex flex-col gap-2">
                  {tags?.map((tag: Tag) => (
                    <span>{tag.label}</span>
                  ))}
                </div>
              ) : null
            }
          >
            <div className="flex flex-row items-center">
              <Icon name="TagIcon" className="w-4 h-4 mr-2" />
              {tags?.map((tag: Tag, index: number) =>
                index < 3 ? (
                  <span>{(index > 0 ? ", " : "") + tag.label}</span>
                ) : null
              )}

              {tags?.length > 3 && <div>...</div>}
            </div>
          </TooltipWrapper>
        </div>
      )}

      {assignedUsers && assignedUsers?.length > 0 && (
        <div
          className={`flex items-center gap-1 ${
            text && "border-l border-slate-200"
          }`}
        >
          <AvatarsStack users={assignedUsers} size="8" />
        </div>
      )}
    </div>
  );
}

export default HeaderInfos;
