import { Message } from "types/Message";

const getIsWaitingAnswer = (messages: Message[]) => {
  if (messages.length === 0) {
    return true;
  }
  const lastMessage = messages[messages.length - 1];
  return messages[0].author?.id === lastMessage.author?.id;
};

export default getIsWaitingAnswer;
