import { useEffect, useCallback, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";

export type ModalProps = {
  onConfirmModal?: (value: any) => void;
  onCloseModal?: () => void;
  children: JSX.Element;
  visible?: boolean;
  title?: string;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  showCloseModalFooter?: boolean;
  disableConfirmBtn?: boolean;
};

function Modal({
  onConfirmModal,
  onCloseModal = () => {},
  children,
  visible = true,
  title = "",
  confirmBtnLabel = "",
  cancelBtnLabel = "",
  showCloseModalFooter = true,
  disableConfirmBtn = false,
}: ModalProps) {
  const { t } = useTranslation();

  const actionButtonRef = useRef(null);

  const handleEscape = useCallback(
    (e: any) => {
      if (e.keyCode === 27) {
        onCloseModal();
      }
    },
    [onCloseModal]
  );

  useEffect(() => {
    const keyup: any = document.addEventListener("keyup", handleEscape);

    return () => document.removeEventListener("keyup", keyup);
  }, [handleEscape]);

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-1000"
        initialFocus={actionButtonRef}
        onClose={onCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 w-full md:min-w-[400px]"
              enterTo="opacity-100 translate-y-0 sm:scale-100 w-full md:min-w-[400px]"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100 w-full md:min-w-[400px]"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 w-full md:min-w-[400px]"
            >
              <Dialog.Panel
                className="relative bg-slate-100 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all 
              sm:my-8 sm:max-w-[700px] sm:w-full sm:p-6
              max-height-ninety-vh
              overflow-y-auto 
              scrollbar-thin scrollbar-thumb-purple-800/90 scrollbar-track-purple-200 scrollbar-thumb-rounded scrollbar-track-rounded
              "
              >
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    <div className="mt-2">{children}</div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  {onConfirmModal && (
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border enabled:border-transparent disabled:border-indigo-600 shadow-sm px-4 py-2 enabled:bg-indigo-600 disabled:bg-gray-50 text-base font-medium enabled:text-white disabled:text-indigo-600 enabled:hover:bg-indigo-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm"
                      onClick={onConfirmModal}
                      ref={actionButtonRef}
                      disabled={disableConfirmBtn}
                    >
                      {confirmBtnLabel || t("actions.sayOk")}
                    </button>
                  )}
                  {showCloseModalFooter && (
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                      onClick={onCloseModal}
                    >
                      {cancelBtnLabel || t("actions.cancel")}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
