import React from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Organization } from "types/Organization";

const processOptions = (options: Organization[]) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function OrganizationSelect(props: any) {
  return (
    <RemoteSelect
      url="organizations"
      processOptions={processOptions}
      {...props}
    />
  );
}

export default OrganizationSelect;
