// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useList from "common/hooks/useList";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WalletIcon } from "@heroicons/react/24/outline";
import Header from "common/components/Header/Header";
import { JobPosition } from "types/JobPosition";

import FilteredList from "common/components/Table/FilteredList";
import AlertsContext from "common/providers/alerts";
import PostTypeItem from "./PostTypeItem";

function PostTypes() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const {
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<JobPosition>("post-types", {
    defaultSort: "name:ASC",
    populate: ["imageDarkMode", "imageLightMode"],
    cachePrefix: `PostTypes_all`,
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const { navigate } = useRouter();
  const handleNavigate = (id: any) => {
    navigate(`/post-types/edit/${id}`);
  };
  // const { isAdmin } = useAuth();

  /* const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]); */

  const columns = useMemo(
    () => [
      {
        Header: t("forms.name"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "w-1/2 mb-2",
      },
      /* {
        Header: t("rights.toEditSite", {
                        wording: getWording(true),
                      }),
        headerClasses: "mb-3",
        filterName: "canEditSite",
        filterComponent: (props: any) => (
          <ToggleSwitch rightLabel={t("rights.canEditSite", {
                        wording: getWording(true),
                      })} {...prjobops} />
        ),
        columnClasses: "w-1/3 mb-2",
      },
      {
        Header: t("rights.toManagePost"),
        headerClasses: "mb-3",
        filterName: "canManagePost",
        filterComponent: (props: any) => (
          <ToggleSwitch rightLabel={t("rights.canManagePost")} {...props} />
        ),
        columnClasses: "w-1/3 mb-2",
      },
      {
        Header: t("rights.toManageActivity"),
        headerClasses: "mb-3",
        filterName: "canManageActivity",
        filterComponent: (props: any) => (
          <ToggleSwitch rightLabel={t("rights.canManageActivity")} {...props} />
        ),
        columnClasses: "w-1/3",
      },
      {
        Header: t("rights.toManageContact"),
        headerClasses: "mb-3",
        filterName: "canManageContact",
        filterComponent: (props: any) => (
          <ToggleSwitch rightLabel={t("rights.canManageContact")} {...props} />
        ),
        columnClasses: "w-1/3",
      },
      {
        Header: t("rights.toAnswerMessage"),
        headerClasses: "mb-3",
        filterName: "canAnswerMessage",
        filterComponent: (props: any) => (
          <ToggleSwitch rightLabel={t("rights.canAnswerMessage")} {...props} />
        ),
        columnClasses: "w-1/3",
      }, */
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.postTypes")}
        subtitle={t("titleLegends.postTypes")}
        Icon={WalletIcon}
        addLinkDisabled
        isBeforeFilters
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={PostTypeItem}
        onDelete={() => {}}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default PostTypes;
