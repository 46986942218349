// eslint-disable-next-line import/no-extraneous-dependencies
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";

export type MentionTextInputProps = {
  value?: { content: string; mentions: any[] };
  onChange?: (val: any) => void;
  options?: any[];
};

function MentionTextInput({
  value = { content: "", mentions: [] },
  onChange,
  options,
  ...props
}: MentionTextInputProps) {
  const handleChange = (
    event: any,
    newValue: string,
    textValue: string,
    mentions: SuggestionDataItem[]
  ) => {
    if (onChange) {
      const updatedMentions = mentions.map((mention) => {
        const user = options?.find((item) => item.display === mention.display);
        return {
          ...mention,
          id: user ? user.id : mention.id,
        };
      });

      onChange({ content: newValue, mentions: updatedMentions });
    }
  };

  return (
    <MentionsInput
      {...props}
      value={value.content}
      onChange={handleChange}
      style={{
        control: {
          backgroundColor: "#fff",
          fontSize: 14,
          fontWeight: "normal",
        },
        "&multiLine": {
          control: {
            fontFamily: "monospace",
            minHeight: 63,
          },
          highlighter: {
            padding: 1,
            border: "1px solid transparent",
          },
          input: {
            padding: 9,
            border: "1px solid purlple",
          },
        },
        suggestions: {
          list: {
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.15)",
            fontSize: 14,
          },
          item: {
            padding: "5px 15px",
            borderBottom: "1px solid rgba(0,0,0,0.15)",
            "&focused": {
              backgroundColor: "#cee4e5",
            },
          },
        },
      }}
    >
      <Mention
        trigger="@"
        markup="@[__display__]"
        data={options ?? []}
        appendSpaceOnAdd
        style={{
          color: "#1072c9",
          fontWeight: "bold",
          position: "relative",
          zIndex: 10,
        }}
      />
    </MentionsInput>
  );
}

export default MentionTextInput;
