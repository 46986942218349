const getInitials = (name?: string) => {
  if (!name) {
    return "";
  }
  const names = name.split(" ");
  return names.reduce((acc: string, word: string) => {
    return `${acc}${word[0]?.toUpperCase() || ""}`;
  }, "");
};

export default getInitials;
