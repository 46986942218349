import {
  useCallback,
  useMemo,
  useRef,
  useContext,
  useState,
  useEffect,
} from "react";
import dayjs from "dayjs";
import { find, get } from "lodash";
import { useTranslation } from "react-i18next";

import { Message } from "types/Message";

import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import Status from "common/components/Status/Status";
import CommentsList from "common/components/CommentsList/CommentsList";
import ThreadIcon from "common/components/PostsAndActivities/ThreadIcon";
import { useAuth } from "common/hooks/useAuth";
import Modal from "common/components/Modal/Modal";
import AlertsContext from "common/providers/alerts";
import checkRights from "common/utils/checkRights";
import UserSelect from "components/select/UserSelect/UserSelect";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";

import Avatar from "common/components/Avatar/Avatar";
import truncateString from "common/utils/truncateString";
import { TagIcon, XMarkIcon } from "@heroicons/react/24/outline";
import useWindowDimensions from "common/utils/Dimensions";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import getWording from "common/utils/wording";
import TagSelect from "components/select/TagSelect/TagSelect";
import ThreadTypeSelect from "components/select/ThreadTypeSelect/ThreadTypeSelect";
import getIsWaitingAnswer from "./getIsWaitingAnswer";
import HeaderButtons from "./HeaderButtons";
import HeaderInfos from "./HeaderInfos";
import getLastAuthorAnswerId, {
  getLastPrivateThreadId,
} from "./getLastAuthorAnswerId";
import QAndAForm from "./QAndAForm";

interface IHeaderSubtitle {
  text: string;
}
function HeaderSubtitle({ text }: IHeaderSubtitle) {
  return <span>{text}</span>;
}

function ThreadForm() {
  const router = useRouter();
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const id = router.query.id as string;
  const {
    item,
    fetchItem,
    updateItem,
    isUpdateFetching,
    error,
    isFetching,
  }: any = useItem("threads", id, {
    populate: [
      "site",
      "type",
      "type.imageLightMode",
      "type.imageDarkMode",
      "post",
      "messages.author",
      "messages.author.avatar",
      "messages.documents",
      "messages.privateThreadIntern.documents",
      "messages.privateThreadIntern.messages",
      "messages.privateThreadIntern.messages.author",
      "messages.privateThreadIntern.messages.author.avatar",
      "messages.privateThreadIntern.messages.documents",
      "assignedUsers",
      "tags",
    ],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const closedThread = item?.status === "closed";
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 768;

  const { user: currentUser }: any = useAuth();

  const canManageThread = currentUser?.isAdmin || currentUser?.isSuperadmin;

  const canAnswerMessage =
    !closedThread &&
    checkRights(
      currentUser,
      "canAnswerMessage",
      item ? [item?.site?.id] : undefined,
      ["isAdmin"]
    );

  const canEditParams = checkRights(
    currentUser,
    "canAnswerMessage",
    item ? [item?.site?.id] : undefined,
    ["isAdmin"]
  );

  const canComment =
    !closedThread &&
    checkRights(
      currentUser,
      "canComment",
      item ? [item?.site?.id] : undefined,
      ["isAdmin"]
    );

  const canPublishQandA = checkRights(
    currentUser,
    "canEditSite",
    item ? [item?.site?.id] : undefined,
    ["isAdmin"]
  );

  const [privatePosition, setPrivatePosition] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>();
  const [lastAuthorAnswerId, setLastAuthorAnswerId] = useState<
    number | undefined
  >();
  // const [riverHeight, setRiverHeight] = useState<number | undefined>();
  const [createQAndA, setCreateQAndA] = useState<boolean>(false);
  const [assignedUsers, setAssignedUsers] = useState<any>(
    item?.assignedUsers?.map((assignedUser: any) => assignedUser.id)
  );
  const [tags, setTags] = useState<any>(item?.tags?.map((tag: any) => tag.id));
  const commentsRef = useRef<HTMLInputElement>(null);

  const messages = useMemo(() => item?.messages || [], [item]);

  useEffect(() => {
    setAssignedUsers(item?.assignedUsers?.map((assignee: any) => assignee.id));
    setTags(item?.tags?.map((tag: any) => tag.id));
  }, [item]);

  const firstItem = messages?.[0];
  const title = t("threads.messageFromName", {
    type: item?.type?.name ?? t("threads.noType"),
    name: firstItem?.author?.name,
  });
  const titleWitPost = t("threads.messageFromPost", {
    type: item?.type?.name ?? t("threads.noType"),
    name: item?.post?.title,
  });
  const subtitle = t("threads.createdOnDate", {
    date: dayjs(firstItem?.createdAt).format("LLLL"),
  });

  const firstAuthor = firstItem?.author;
  const siteName = get(item, "site.name");
  const siteId = get(item, "site.id");

  const getAvatar = useCallback(
    (name: string, avatar: any) => <Avatar name={name} image={avatar} />,
    []
  );

  const onClose = useCallback(
    async (status: string) => {
      setSelectedId(undefined);

      try {
        await updateItem(id, { status }, true, "closeThread");
      } catch (e) {
        console.error(e);
      }
    },
    [id, updateItem, setSelectedId]
  );

  const scrollToActiveThread = useCallback(() => {
    window.scrollTo({ top: privatePosition || 0, behavior: "smooth" });
  }, [privatePosition]);

  useEffect(() => {
    scrollToActiveThread();
  }, [scrollToActiveThread]);

  // Select new message
  const onOpenPrivateComments = (selected: number) => {
    if (selected === selectedId) {
      setSelectedId(undefined);
    } else {
      setSelectedId(selected);
      if (!isMobileScreen) {
        scrollToActiveThread();
      }
    }
  };

  // Move private block
  const onMovePrivateComments = (boundingElement: any) => {
    const { height } = boundingElement;
    const containerTop = commentsRef.current?.offsetTop || 0;
    const top = boundingElement.top + window.scrollY;

    setPrivatePosition(top - containerTop - 16 + height);
    // setRiverHeight(height);
  };

  const isWaitingAnswer = getIsWaitingAnswer(messages);

  const selectedMessage = isMobileScreen
    ? selectedId
      ? find(messages, (message) => message.id === selectedId)
      : undefined
    : selectedId
    ? find(messages, (message) => message.id === selectedId)
    : find(messages, (message) => message.id === lastAuthorAnswerId);

  const selectedPrivateMessages = closedThread
    ? null
    : get(selectedMessage, `privateThreadIntern.messages`);

  const selectedPrivateId = closedThread
    ? null
    : get(selectedMessage, `privateThreadIntern.id`);

  const privateCommentsStyle = useMemo(() => {
    if (!privatePosition || isMobileScreen) {
      return {};
    }

    return { transform: `translateY(${privatePosition}px)` };
  }, [privatePosition, isMobileScreen]);

  /* const riverStyle = useMemo(() => {
    if (!privatePosition) {
      return { opacity: 0 };
    }

    return {
      transform: `translateY(${privatePosition + 16}px)`,
      opacity: 1,
      height: riverHeight,
    };
  }, [privatePosition, riverHeight]); */

  useEffect(() => {
    if (getLastPrivateThreadId(messages)) {
      setLastAuthorAnswerId(getLastPrivateThreadId(messages));
    } else {
      setLastAuthorAnswerId(getLastAuthorAnswerId(messages));
    }
  }, [messages, setLastAuthorAnswerId]);

  useEffect(() => {
    if (selectedMessage && isMobileScreen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isMobileScreen, selectedMessage]);

  // new q-and-a

  const { saveItem: saveItemQAndA, error: errorQAndA }: any = useItem(
    "q-and-as",
    null
  );

  const onSubmitQAndA = async (dataQAndA: any) => {
    const qAndA = { ...dataQAndA };
    try {
      await saveItemQAndA(qAndA);
    } catch (e: any) {
      return e;
    }

    if (!errorQAndA) {
      setAlert(t("common.saved"), "success");
    } else {
      setAlert(errorQAndA, "danger");
    }
    setCreateQAndA(false);
    return true;
  };

  const handleAssigneeChange = async (value: number) => {
    try {
      await updateItem(id, { assignedUsers: value }, true, "assignUsers");
    } catch (e: any) {
      return e;
    }

    return true;
  };

  const handleTagChange = async (value: number) => {
    try {
      await updateItem(id, { tags: value }, true, "tags");
    } catch (e: any) {
      return e;
    }

    return true;
  };

  const handleTypeChange = async (value: number) => {
    try {
      await updateItem(id, { type: value }, true, "type");
    } catch (e: any) {
      return e;
    }

    return true;
  };

  const handleChangeSite = useCallback(
    (newSiteId: string) => {
      updateItem(id, { site: newSiteId });
    },
    [id, updateItem]
  );

  const getPlaceText = () => {
    if (isFetching || !item || !item?.geojson) return undefined;

    return item?.geojson?.label ?? t("threads.geolocalisationLink");
  };

  const getSiteOrPostText = () => {
    if (isFetching || !item) return undefined;

    if (siteName)
      return `${siteName} ${item?.post ? ` - ${item.post.title}` : ""}`;

    return t("threads.noSite", { wording: getWording(false, true) });
  };

  return (
    <>
      <div
        className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base"
        id="toto"
      >
        <DetailHeader
          backLink="/threads"
          TitleIcon={item?.type ? <ThreadIcon type={item?.type} /> : undefined}
          title={item?.post ? titleWitPost : title}
          HeaderInfos={
            <HeaderInfos
              text={getSiteOrPostText()}
              textPlace={getPlaceText()}
              assignedUsers={item?.assignedUsers}
              tags={item?.tags}
              textPlaceRedirectionLink={
                item?.geojson
                  ? `https://www.google.com/maps?q=${item.geojson.lat},${item.geojson.lng}`
                  : ""
              }
            />
          }
          HeaderButtons={
            <HeaderButtons
              onClose={
                canManageThread && !closedThread
                  ? () => onClose("closed")
                  : undefined
              }
              onOpenThread={
                canManageThread && closedThread
                  ? () =>
                      onClose(isWaitingAnswer ? "waiting_answer" : "answered")
                  : undefined
              }
              isClosing={isUpdateFetching("closeThread")}
              onPublish={
                canPublishQandA ? () => setCreateQAndA(true) : undefined
              }
            />
          }
          SubTitle={<HeaderSubtitle text={subtitle} />}
          Status={
            <Status
              status={item?.status}
              label={dayjs(firstItem?.createdAt).fromNow(true)}
            />
          }
        />

        <div className="white-box">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="flex flex-col gap-2">
              <SectionTitle title={t("forms.threadType")} icon="WorkSiteIcon" />
              <ThreadTypeSelect
                checkRights={["canComment"]}
                value={item?.type?.id}
                onChange={(value: any) => handleTypeChange(value)}
                disabled={!canEditParams}
              />
            </div>
            <div className="flex flex-col gap-2">
              <SectionTitle
                title={t("forms.assignedSite", {
                  wording: getWording(false, true),
                })}
                icon="WorkSiteIcon"
              />
              <SiteSelect
                checkRights={["canComment"]}
                value={item?.site?.id}
                onChange={handleChangeSite}
                disabled={!canEditParams}
              />
            </div>
            <div className="flex flex-col gap-2">
              <SectionTitle
                title={t("forms.assignedUsers")}
                icon="ChatBubbleLeftRightIcon"
              />
              <UserSelect
                multiple
                onChange={(value: any) => handleAssigneeChange(value)}
                Icon={WorkSiteIcon}
                value={assignedUsers}
                disabled={!canEditParams}
              />
            </div>
            <div className="flex flex-col gap-2">
              <SectionTitle title={t("forms.tags")} icon="TagIconOutline" />
              <TagSelect
                multiple
                onChange={(value: any) => handleTagChange(value)}
                Icon={TagIcon}
                value={tags}
                disabled={!canEditParams}
              />
            </div>
          </div>
        </div>
        <div className="responsive-flex gap-4 mt-2">
          {/* public */}
          <div className="flex-1 flex flex-col gap-2">
            <div ref={commentsRef} className="flex flex-col gap-4 white-box">
              <SectionTitle
                title={t("forms.threadPublic")}
                icon="ChatBubbleLeftRightIcon"
              />
              <CommentsList
                comments={messages}
                onAddPrivate={onOpenPrivateComments}
                onMovePrivateComments={onMovePrivateComments}
                selectedId={
                  isMobileScreen
                    ? closedThread
                      ? undefined
                      : selectedId || undefined
                    : closedThread
                    ? undefined
                    : selectedId || lastAuthorAnswerId
                }
                canAnswerMessage={canAnswerMessage}
                canComment={canComment}
                threadId={item?.id}
                refresh={fetchItem}
                siteId={siteId}
              />
            </div>
          </div>
          {/* private */}
          {(selectedId || !isMobileScreen) && !closedThread && (
            <div className="md:w-1/3 md:relative">
              {/*
              <div
                className="hidden md:flex absolute w-[42px] h-[35px] -left-[41px] bg-secondary-200 z-10"
                style={riverStyle}
          /> */}

              <div
                className="fixed bottom-0
              left-0 w-full h-[calc(100%-50px)] z-[100]
              md:relative md:bottom-auto md:left-auto md:w-full md:h-auto md:z-auto
              flex flex-col gap-4 secondary-box border-0 transition-all p-4 md:p-2 rounded-none md:rounded-lg"
                style={privateCommentsStyle}
              >
                {isMobileScreen && selectedMessage && (
                  <div className="flex justify-between items-center bg-white p-2 rounded-lg">
                    {/* left */}
                    <div className="flex-1">
                      <div className="flex items-center gap-2">
                        {getAvatar(
                          selectedMessage.author?.name,
                          selectedMessage.author?.avatar
                        )}
                        <span className="font-bold">
                          {selectedMessage.author?.name ||
                            t("threads.unknownUser")}
                        </span>
                        {selectedMessage.comment?.documents?.length > 0 && (
                          <span>
                            {t("common.nbDocuments", {
                              count: selectedMessage.comment?.documents.length,
                            })}
                          </span>
                        )}
                        <div className="italic text-xs text-black/60">
                          {dayjs(selectedMessage.comment?.createdAt).fromNow()}
                        </div>
                      </div>

                      <div className="w-full my-2 flex flex-col" key={id}>
                        <div>
                          {truncateString(selectedMessage.content, 200)}
                        </div>
                      </div>
                    </div>

                    {/* right */}

                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSelectedId(undefined)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-purple-900"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                )}

                <SectionTitle
                  title={t("forms.privateMessages")}
                  icon="RiChatPrivateLine"
                />

                <CommentsList
                  comments={selectedPrivateMessages}
                  threadId={item?.id}
                  privateThreadId={selectedPrivateId}
                  refresh={() => fetchItem(selectedPrivateId)}
                  selectedId={selectedId || lastAuthorAnswerId}
                  canComment={canComment}
                  isPrivate
                  secondary
                  siteId={siteId}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {createQAndA && (
        <Modal
          onCloseModal={() => setCreateQAndA(false)}
          showCloseModalFooter={false}
          visible={createQAndA}
        >
          <div>
            <QAndAForm
              onSubmit={onSubmitQAndA}
              onCancel={() => setCreateQAndA(false)}
              inModal
              qAndA={{
                site: item?.site ? item?.site?.id : undefined,
                post: item?.post ? item?.post?.id : undefined,
                question: item?.messages
                  ?.filter(
                    (m: Message) =>
                      !m.isInternal && m.author?.id === firstAuthor?.id
                  )
                  .map((m: Message) => m.content)
                  .join("\n"),
                answerRich: item?.messages
                  ?.filter(
                    (m: Message) =>
                      !m.isInternal && m.author?.id !== firstAuthor?.id
                  )
                  .map((m: Message) => m.content)
                  .join("\n"),
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
}

export default ThreadForm;
