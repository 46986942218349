import dayjs from "dayjs";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "common/components/Button/Button";
import DatesRange from "common/components/DatesRange/DatesRange";
import Timeline from "common/components/Timeline/Timeline";
import truncateString from "common/utils/truncateString";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";

import checkRights from "common/utils/checkRights";
import Status from "common/components/Status/Status";
import SiteTypeIcon from "common/components/PostsAndActivities/SiteTypeIcon";
import getFlattenText from "common/utils/getFlattenText";

const iconStyle = "w-4 h-4 text-white";

function SiteItem({ item, onNavigate, onDelete, onOpenThreads }: any) {
  const { user: currentUser } = useAuth();
  const { organization: currentOrganization } = useOrganization();

  const canEditItem = checkRights(
    currentUser,
    "canEditSite",
    item ? [item?.id] : undefined,
    ["isAdmin"]
  );

  // const isFinished = dayjs().isAfter(dayjs(item.endDate));
  const opacity = item?.active ? "" : "opacity-50";

  const detailLink = `/sites/${item.id}`;

  const { t } = useTranslation();

  return (
    <div
      className={`group responsive-flex-lg px-4 py-4 mt-2 w-full relative ${
        item.isDraft
          ? "bg-orange-100 border-orange-200"
          : "bg-white border-slate-150"
      } cursor-pointer rounded-lg border  hover:border-primary transition-all ${opacity}`}
    >
      <Link to={detailLink} className="w-full responsive-flex-lg">
        <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
          <div className="absolute -top-2 -left-2">
            <SiteTypeIcon
              labelType={item?.siteType?.name}
              iconStyle={iconStyle}
            />
          </div>
          <div className="rounded-lg overflow-hidden w-full">
            <ImageComponent
              image={
                item.mainImage || item.logo
                  ? ImageFromStrapiMedia(item.mainImage ?? item.logo)?.uri
                  : undefined
              }
            />
          </div>
        </div>

        <div className="flex flex-col flex-1 justify-start lg:pl-4 lg:pr-6 mb-2 mt-2 lg:mt-0">
          <div className="responsive-flex-lg mb-1 gap-3">
            <div className="flex-1">
              <div className="flex flex-row justify-between">
                <h4 className="text-xl font-bold text-primary leading-4">
                  {item.name}
                </h4>
              </div>
              {item.location && (
                <div className="flex items-center gap-1 text-sm my-3">
                  <MapPinIcon className="w-3 h-3" />
                  <span className="font-bold">{item.location}</span>
                </div>
              )}
            </div>
          </div>

          {item.steps?.length > 0 && (
            <div className="w-full my-4">
              <Timeline steps={item.steps || []} />
            </div>
          )}

          {(item.descriptionRich ||
            (item.description && item.description !== "")) && (
            <div
              className={`flex flex-col gap-2 pt-2 mt-2 border-t ${
                item.isDraft ? "border-orange-200" : "border-slate-200"
              }`}
            >
              <div className="text-sm text-slate-500  ">
                {item.descriptionRich
                  ? truncateString(getFlattenText(item.descriptionRich), 150)
                  : truncateString(item.description, 150)}
              </div>
            </div>
          )}

          {!currentOrganization?.isMonoActivity && (
            <div
              className={`flex gap-2 pt-2 mt-4 border-t ${
                item.isDraft ? "border-orange-200" : "border-slate-200"
              }`}
            >
              {!currentOrganization?.isMonoActivity && !!item.siteType && (
                <Status
                  status={item.siteType?.name}
                  color={item.siteType.color}
                  label={item.parent ? item.parent.name : undefined}
                  small
                />
              )}
            </div>
          )}
        </div>
      </Link>
      <div
        className={`flex flex-col justify-end items-end text-xs lg:border-l lg:pl-4 min-w-[22em] border-slate-150 gap-2 ${
          (item.threads?.length > 0 ||
            item.startDate ||
            item.endDate ||
            item.isDraft) &&
          "justify-between"
        }`}
      >
        {item.isDraft && (
          <div
            className="px-2 py-1 rounded-xl bg-orange-600 text-white  flex items-center text-sm w-fit"
            title={t("forms.adminOption")}
          >
            {t("forms.draft")}
          </div>
        )}
        {item.siteType?.shouldHaveDates && (item.startDate || item.endDate) && (
          <div className="flex w-full flex-col items-end text-sm text-gray-500 gap-1 my-3 lg:my-0">
            <DatesRange
              startDate={
                item.startDate ? dayjs(item.startDate).toDate() : undefined
              }
              endDate={item.endDate ? dayjs(item.endDate).toDate() : undefined}
              isCurrentDate={
                item.startDate && item.endDate
                  ? dayjs().isBetween(
                      dayjs(item.startDate),
                      dayjs(item.endDate)
                    )
                  : item.startDate
                  ? dayjs().isAfter(dayjs(item.startDate))
                  : dayjs().isBefore(dayjs(item.endDate))
              }
              hideHours
            />
          </div>
        )}

        {item.threads?.length > 0 && (
          <Button
            title={t("threads.threadsCount", {
              count: item.threads?.length,
            })}
            type="primary"
            icon="ChatBubbleLeftRightIcon"
            onClick={() => onOpenThreads(item.id)}
          />
        )}

        <div className="flex flex-col w-full items-end justify-end gap-2 text-slate-500">
          <div className="flex justify-end w-full gap-1">
            {currentUser?.isSuperadmin && (
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />
            )}

            {canEditItem && (
              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteItem;
