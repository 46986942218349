import { useCallback, useContext, useEffect, useRef } from "react";

import { Message, MessageMentionContent } from "types/Message";
import CommentItem from "common/components/CommentItem/CommentItem";
import AddComment from "common/components/AddComment/AddComment";
import useItem from "common/hooks/useItem";
import useAuth from "common/hooks/useAuth";
import { isEmpty } from "lodash";
import AlertsContext from "common/providers/alerts";
import { useTranslation } from "react-i18next";
import { Thread } from "types/Thread";

interface ICommentsList {
  comments: Message[];
  onAddPrivate?: any;
  onMovePrivateComments?: any;
  selectedId?: number;
  canAnswerMessage?: boolean;
  canComment?: boolean;
  isPrivate?: boolean;
  privateThreadId?: number;
  threadId?: number;
  postId?: number;
  siteId?: number;
  refresh?: any;
  // confirmRefresh?: boolean;
  // thirdChoiceConfirmAction?: () => void;
  secondary?: boolean;
  tertiary?: boolean;
}

function CommentsList({
  comments,
  onAddPrivate,
  onMovePrivateComments,
  selectedId,
  canAnswerMessage = false,
  canComment = false,
  isPrivate,
  privateThreadId,
  threadId,
  postId,
  siteId,
  refresh,
  secondary = false,
  tertiary = false,
}: // confirmRefresh,
// thirdChoiceConfirmAction,
ICommentsList) {
  const { t } = useTranslation();
  const threadPath = isPrivate ? "privateThread" : "thread";
  const threadUseItemPath = isPrivate ? "private-threads" : "threads";
  const { saveItem: saveMessage, error: messageError } = useItem(
    "messages",
    null
  );
  const {
    item: thread,
    saveItem: saveThread,
    updateItem: updateThread,
    error: threadError,
  } = useItem<Thread>(
    threadUseItemPath,
    isPrivate ? privateThreadId || null : threadId || null,
    {
      populate: "userType",
    }
  );
  const {
    user: { id: userId, isAdmin, isModo, isSuperadmin },
  }: any = useAuth();
  const refsList = useRef<Record<string, any>>({});
  const containerRef = useRef<null | HTMLDivElement>(null);
  const { setAlert } = useContext(AlertsContext);
  const isAdminAnswerer = isAdmin || isModo || isSuperadmin;

  useEffect(() => {
    if (messageError) {
      setAlert(messageError, "danger");
    }
  }, [messageError, setAlert]);

  useEffect(() => {
    if (threadError) {
      setAlert(threadError, "danger");
    }
  }, [threadError, setAlert]);

  const saveCommentRef = useCallback((ref: any, id: number) => {
    if (refsList.current) {
      refsList.current[id] = ref;
    }
  }, []);

  const onAddComment = useCallback(
    async (message: Message) => {
      let finalThreadId = isPrivate ? privateThreadId : threadId;

      /** Create thread if it doesn't exist */
      if (!finalThreadId) {
        const threadStatus = isAdminAnswerer
          ? {}
          : { status: "waiting_answer" };
        const threadPost = postId ? { post: postId } : {};
        try {
          let threadData = null;

          if (isPrivate && postId) {
            // Thread privée (commentaire) sur un article
            threadData = {
              id: null,
              post: postId,
              content: (message.content as MessageMentionContent)?.content,
              mentions: (message.content as MessageMentionContent)?.mentions,
            };
          } else if (isPrivate && selectedId) {
            // Thread privée sur un message d'un riverain
            threadData = {
              id: null,
              messageSource: selectedId,
              content: (message.content as MessageMentionContent)?.content,
              mentions: (message.content as MessageMentionContent)?.mentions,
            };
          } else {
            // Thread public mais on ne doit normalement jamais passé ici,
            // car le thread public est géré par le riverain :)
            threadData = { ...threadStatus, ...threadPost };
          }
          const newThread: any = await saveThread(threadData);
          finalThreadId = newThread.id;
        } catch (e: any) {
          return e;
        }
      }

      /** Save message */
      const newMessage = {
        ...message,
        author: userId,
        content:
          isPrivate && typeof message.content !== "string"
            ? message.content.content
            : message.content,
        mentions:
          isPrivate && typeof message.content !== "string"
            ? message.content.mentions
            : [],
        [threadPath]: finalThreadId,
      };

      try {
        /** Save message */
        await saveMessage(newMessage);

        /** Set thread status */
        if (!isPrivate) {
          const threadStatus = isAdminAnswerer ? "answered" : "waiting_answer";
          const threadData = { status: threadStatus };
          await updateThread(finalThreadId, threadData);
        }

        /** Refresh post */
        if (refresh) {
          await refresh();
        }
      } catch (e: any) {
        return e;
      }
      return true;
    },
    [
      threadId,
      privateThreadId,
      userId,
      saveMessage,
      refresh,
      threadPath,
      saveThread,
      isPrivate,
      selectedId,
      isAdminAnswerer,
      updateThread,
      postId,
    ]
  );

  // Move private comments on selectedId changes
  useEffect(() => {
    if (
      selectedId &&
      refsList.current &&
      refsList.current[selectedId] &&
      onMovePrivateComments
    ) {
      const boundingElement =
        refsList?.current[selectedId]?.current?.getBoundingClientRect();
      if (boundingElement) {
        onMovePrivateComments(boundingElement);
      }
    }
  }, [selectedId, onMovePrivateComments]);

  useEffect(() => {
    if (containerRef.current)
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [comments]);

  const list =
    comments?.length > 0 ? (
      (comments || []).map((comment: Message) => (
        <CommentItem
          key={comment.id}
          comment={comment}
          onAdd={() => onAddPrivate(comment.id)}
          selected={selectedId === comment.id}
          canComment={canComment}
          isPrivate={isPrivate}
          saveComment={(ref: any) => saveCommentRef(ref, comment.id)}
          siteId={siteId}
          threadUserType={thread?.userType}
        />
      ))
    ) : (
      <p>{t("forms.noPrivateMessages")}</p>
    );

  const listContent =
    isPrivate && !isEmpty(list) ? (
      <div
        ref={containerRef}
        className="flex flex-col gap-4 flex-1 md:flex-auto md:max-h-[300px] mb-[170px] md:mb-0 overflow-auto"
      >
        {list}
      </div>
    ) : (
      list
    );

  return (
    <div className="flex flex-col gap-2 flex-1 h-full md:flex-auto md:h-auto">
      <div className="mb-6">{listContent}</div>
      {((!isPrivate && canAnswerMessage) || (isPrivate && canComment)) && (
        <AddComment
          onSubmit={onAddComment}
          secondary={secondary}
          tertiary={tertiary}
          isPrivate={isPrivate}
          threadId={threadId}
          refresh={refresh}
          siteId={siteId}
          // confirmRefresh={confirmRefresh}
          // thirdChoiceConfirmAction={thirdChoiceConfirmAction}
        />
      )}
    </div>
  );
}

export default CommentsList;
