import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useContext, useEffect } from "react";
import AlertsContext from "common/providers/alerts";
import ThreadForm from "./ThreadForm";

function Thread() {
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { item, isFetching, error }: any = useItem("threads", id);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  return isFetching || (id && !item) ? null : <ThreadForm />;
}

export default Thread;
