import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";

import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import useRouter from "common/hooks/use-router";
import EditHeader from "common/components/EditHeader/EditHeader";
import getWording from "common/utils/wording";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import SectionTitle from "common/components/SectionTitle/SectionTitle";

function JobPositionForm({ onSubmit, jobPosition }: any) {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const initialValues = useMemo(
    () =>
      jobPosition?.id
        ? {
            ...jobPosition,
            organization: jobPosition.organization?.id,
          }
        : {
            name: "",
            organization: organization?.id || currentUser?.organizations[0]?.id,
            canSubmitPost: false,
            canManagePost: false,
            canComment: false,
            canEditSite: false,
            canManageActivity: false,
            canManageContact: false,
            canAnswerMessage: false,
            canSeeUserData: false,
          },
    [jobPosition, organization, currentUser]
  );

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <div className="mx-auto max-main-box py-4 px-4 text-slate-500 text-base">
          {/* Header */}
          <EditHeader
            title={t(
              jobPosition?.id ? "editItem.jobPosition" : "newItem.jobPosition"
            )}
            subtitle={values.name}
            icon="WalletIcon"
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={() => navigate("/job-positions")}
              />
            }
          />

          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />

            <div className="responsive-flex-lg gap-2 mt-2">
              <div className="lg:flex-[1]">
                <div className="flex flex-col gap-2 w-full">
                  {/* Image & desc */}
                  <div className="white-box">
                    <FormControl
                      name="name"
                      titleKey={t("forms.name")}
                      required
                      containerStyle="mb-4"
                    />
                  </div>
                </div>
              </div>
              <div className="lg:flex-[2]">
                <div className="white-box gap-2 flex flex-col">
                  <SectionTitle
                    title={t("rights.subtitle")}
                    className="text-xl normal-case"
                  />

                  <SectionTitle
                    title={t("rights.titleSites", {
                      wording: getWording(true, true),
                    })}
                  />

                  <div className="flex items-center ">
                    <FormControl type="custom" name="canEditSite">
                      <ToggleSwitch rightLabel={t("rights.canEditSite")} />
                    </FormControl>
                  </div>

                  <SectionTitle title={t("rights.titlePosts")} />
                  <div className="flex items-center ">
                    <FormControl type="custom" name="canSubmitPost">
                      <ToggleSwitch rightLabel={t("rights.canSubmitPost")} />
                    </FormControl>
                  </div>

                  <div className="flex items-center ">
                    <FormControl type="custom" name="canManagePost">
                      <ToggleSwitch rightLabel={t("rights.canManagePost")} />
                    </FormControl>
                  </div>

                  <SectionTitle title={t("rights.titleModeration")} />

                  <div className="flex items-center ">
                    <FormControl type="custom" name="canComment">
                      <ToggleSwitch rightLabel={t("rights.canComment")} />
                    </FormControl>
                  </div>
                  <div className="flex items-center ">
                    <FormControl type="custom" name="canAnswerMessage">
                      <ToggleSwitch rightLabel={t("rights.canAnswerMessage")} />
                    </FormControl>
                  </div>
                  <div className="flex items-center ">
                    <FormControl type="custom" name="canSeeUserData">
                      <ToggleSwitch rightLabel={t("rights.canSeeUserData")} />
                    </FormControl>
                  </div>

                  <SectionTitle title={t("rights.titleActivities")} />

                  <div className="flex items-center ">
                    <FormControl type="custom" name="canManageActivity">
                      <ToggleSwitch
                        rightLabel={t("rights.canManageActivity")}
                      />
                    </FormControl>
                  </div>

                  <SectionTitle title={t("rights.titleContacts")} />

                  <div className="flex items-center ">
                    <FormControl type="custom" name="canManageContact">
                      <ToggleSwitch rightLabel={t("rights.canManageContact")} />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>

            {
              // JSON.stringify(values)
            }

            <HeaderButtonsEdit
              onSubmit={handleSubmit}
              onCancel={() => navigate("/job-positions")}
            />
          </form>
        </div>
      )}
    />
  );
}
export default JobPositionForm;
