import Confirm from "common/components/Confirm/Confirm";
import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  children: any;
  message?: string | null;
  confirmMessageYes?: string | null;
  confirmMessageNo?: string | null;
  disabled?: boolean;
  thirdChoiceConfirmAction?: () => void;
  thirdChoiceConfirmLabel?: string;
};

export type ConfirmWrapProps = {
  getModalVisible: () => boolean;
};

const ConfirmWrap = forwardRef<ConfirmWrapProps, Props>(
  (
    {
      children,
      message,
      confirmMessageYes,
      confirmMessageNo,
      disabled,
      thirdChoiceConfirmAction,
      thirdChoiceConfirmLabel,
    },
    ref
  ) => {
    const [modalActive, setModalActive] = useState(false);
    const { t } = useTranslation();
    const child = React.Children.only(children);
    const confirmMessage = message ?? t("modal.confirmMessage");

    const handleShow = () => setModalActive(true);
    const closeModal = () => setModalActive(false);

    const handleConfirm = () => {
      if (child.props.onClick) child.props.onClick();
      setModalActive(false);
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          getModalVisible: () => {
            return modalActive;
          },
        };
      },
      [modalActive]
    );

    if (disabled) {
      return child;
    }

    return (
      <>
        {React.cloneElement(child, { onClick: handleShow })}
        <Confirm
          message={confirmMessage}
          show={modalActive}
          confirmModal={handleConfirm}
          closeModal={closeModal}
          confirmMessage={confirmMessageYes}
          confirmCancel={confirmMessageNo}
          thirdChoiceConfirmAction={thirdChoiceConfirmAction}
          thirdChoiceConfirmLabel={thirdChoiceConfirmLabel}
        />
      </>
    );
  }
);

ConfirmWrap.propTypes = {
  message: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  thirdChoiceConfirmAction: PropTypes.func,
  thirdChoiceConfirmLabel: PropTypes.string,
  confirmMessageYes: PropTypes.string,
  confirmMessageNo: PropTypes.string,
};

ConfirmWrap.defaultProps = {
  message: null,
  disabled: false,
  thirdChoiceConfirmAction: undefined,
  thirdChoiceConfirmLabel: undefined,
  confirmMessageYes: null,
  confirmMessageNo: null,
};

export default ConfirmWrap;
