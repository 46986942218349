import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { BsQuote } from "react-icons/bs";
import ReactQuill from "react-quill";
import { QAndA as QAndAType } from "types/QAndA";

interface IQAndA {
  item: QAndAType;
}
function QAndA({ item }: IQAndA) {
  const { question, answer, answerRich } = item;
  const { t } = useTranslation();

  const qContents = question?.split("\n");
  const aContents = answer?.split("\n");

  return (
    <div className="flex p-3 border mt-2 border-slate-200 rounded-lg gap-3 items-center">
      <div className="w-full flex flex-col gap-2">
        {item.post && (
          <p className="font-bold text-sm leading-4 text-black mb-2 ">
            {t("forms.qAndAfromPost", { title: item.post.title })}
          </p>
        )}
        <div className="flex gap-1 text-secondary  text-sm  leading-4  font-bold">
          <div className="min-w-4">
            <QuestionMarkCircleIcon className="w-4 h-4" />
          </div>

          <div>
            {qContents?.map((p: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={`q-${index}`} className="mb-4">
                {p}
              </p>
            ))}
          </div>
        </div>

        <div className="flex gap-1 text-secondary  text-sm  leading-4 ">
          <div className="min-w-4">
            <BsQuote className="w-4 h-4" />
          </div>

          <div>
            {answerRich ? (
              <div className="richtext-rendered">
                <ReactQuill theme="snow" readOnly value={answerRich} />
              </div>
            ) : (
              aContents?.map((p: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={`a-${index}`} className="mb-4">
                  {p}
                </p>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QAndA;
