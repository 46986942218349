const checkRights = (
  currentUser: any,
  rightKey: string,
  siteIds: number[] = [],
  jokers: string[] = [],
  needsSites = false
) => {
  if (
    ["isSuperadmin", ...jokers].find((jr: string) => currentUser[jr] === true)
  ) {
    return true;
  }

  if (needsSites && siteIds === undefined) {
    return false;
  }

  return !!siteIds.find((siteId: number) =>
    currentUser?.checkMyRight(siteId, rightKey, jokers)
  );
};

export default checkRights;
