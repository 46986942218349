import Button from "common/components/Button/Button";
import FormControl from "common/components/FormControl/FormControl";
import { useTranslation } from "react-i18next";
import { BiMoveVertical } from "react-icons/bi";

function DragHandle(props: any) {
  return (
    <BiMoveVertical
      {...props}
      className="w-4 h-4 cursor-move hover:text-primary border-none"
    />
  );
}

function QAndAItemForm(formValues: any, rest: any) {
  const { t } = useTranslation();

  const { index, name, fields, dragProps } = rest;

  const onDelete = () => fields.remove(index);

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-end w-full rounded-lg border border-slate-200 py-2 px-3 bg-slate-100">
      <div className="flex w-full gap-1 items-start">
        <div className="flex flex-col flex-1 gap-4">
          {formValues.qAndAs[index].post && (
            <span className="font-bold  text-sm  leading-4 text-secondary ">
              {t("forms.qAndAfromPost", {
                title: formValues.qAndAs[index].post.title,
              })}
            </span>
          )}
          <FormControl
            title={t("forms.question")}
            name={`${name}.question`}
            type="textarea"
            className="text-input-darker"
            inputContainerStyle="flex flex-1"
            containerStyle="w-full"
            labelIcon="BsTextIndentLeft"
            required
            rows={2}
          />
          <FormControl
            title={t("forms.answer")}
            name={`${name}.answerRich`}
            type="richtext"
            className="text-input-darker"
            containerStyle="w-full"
            labelIcon="BsTextIndentLeft"
            required
            rows={3}
          />
        </div>

        <div className="px-4">
          <Button
            type="error-line"
            onClick={onDelete}
            icon="TrashIcon"
            compact
            confirmMessage={t("actions.confirmDelete")}
          />
        </div>

        <div
          className="absolute w-4 h-4 right-1 top-4 cursor-pointer hover:text-primary border-none"
          {...dragProps}
        >
          <DragHandle />
        </div>
      </div>
    </div>
  );
}

export default QAndAItemForm;
