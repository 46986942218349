import StepItem from "./StepItem";

interface IStepsList {
  steps?: any;
}

function StepsList({ steps = [] }: IStepsList) {
  return steps?.map((step: any, index: number) => {
    const stepsTodo = steps.filter((s: any) => !s.done);
    const currentStep = stepsTodo[0];
    return (
      <StepItem
        item={step}
        index={index}
        current={step.id === currentStep?.id}
        isLast={index + 1 === steps?.length}
      />
    );
  });
}

export default StepsList;
