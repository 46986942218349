// eslint-disable-next-line import/prefer-default-export
export const Constants = {
  API_URL: `${
    import.meta.env.VITE_API_URL ?? "https://api-develop.informez-moi.fr"
  }/api`,
  ROOT_URL:
    import.meta.env.VITE_API_URL ?? "https://api-develop.informez-moi.fr",
  DISPLAY_IS_TEST_PLATFORM:
    !!import.meta.env.VITE_DISPLAY_IS_TEST_PLATFORM ?? false,
};
