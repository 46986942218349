import { useCallback, useMemo, useState } from "react";
import { BsFiles } from "react-icons/bs";
import arrayMutators from "final-form-arrays";
import { BiMoveVertical } from "react-icons/bi";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";

import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import Status from "common/components/Status/Status";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import Button from "common/components/Button/Button";
import FormControl from "common/components/FormControl/FormControl";
import SortableList from "common/components/SortableList/SortableList";
import FormAddButton from "common/components/FormAddButton.tsx/FormAddButton";
import MapField from "common/components/MapField/MapField";
import useAuth from "common/hooks/useAuth";
import EditHeader from "common/components/EditHeader/EditHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import JobPositionSelect from "components/select/JobPositionSelect/JobPositionSelect";
import UserSelect from "components/select/UserSelect/UserSelect";
import ContactSelect from "components/select/ContactSelect/ContactSelect";
import useOrganization from "common/hooks/useOrganization";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import RadioBlockItem, {
  IRadioBlockItem,
} from "common/components/RadioBlockItem/RadioBlockItem";
import { Transition } from "@headlessui/react";
import { SiteType } from "types/SiteType";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import { SiteUser as SiteUserType } from "types";
import useList from "common/hooks/useList";
import { JobPosition } from "types/JobPosition";
import { ThreadType } from "types/ThreadType";
import enableMapField from "common/utils/enableMapField";
import getWording from "common/utils/wording";
import QAndAItemForm from "common/components/QAndA/QAndAItemForm";
import { QAndA } from "types/QAndA";
import EmptyBlockWithCTA from "common/components/EmptyBlockWithCTA/EmptyBlockWithCTA";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import EmptyResult from "common/components/EmptyResult/EmptyResult";
import SiteUser from "common/components/SiteUser/SiteUser";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import imgSizes from "conf/imgSizes.json";
import MarkerSelect from "components/select/MarkerSelect/MarkerSelect";

const transitionAnimationProps = {
  enter: "transition-opacity ease-linear duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition-opacity ease-linear duration-300",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

const compareStepsAround = (mainStepIndex: any, form: any, formValues: any) => {
  // anticipe la valeur qui n'est pas encore à jour dans la fonction
  const newToggledStepVal = Boolean(!formValues?.steps[mainStepIndex].done);

  formValues?.steps?.forEach((_stepVal: any, stepComparedIndex: number) => {
    const stepComparedDone = `steps[${stepComparedIndex}].done`;

    if (stepComparedIndex < mainStepIndex && newToggledStepVal) {
      form.mutators.setFieldValue({
        field: stepComparedDone,
        value: true,
      });
    }

    if (stepComparedIndex > mainStepIndex && !newToggledStepVal) {
      form.mutators.setFieldValue({
        field: stepComparedDone,
        value: false,
      });
    }
  });
};

function DragHandle(props: any) {
  return (
    <BiMoveVertical
      {...props}
      className="w-4 h-4 cursor-move hover:text-primary border-none"
    />
  );
}

function LinkItem({ index, name, fields, dragProps }: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-end justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="responsive-flex w-full gap-3">
        <div className="flex md:flex-1 items-start text-xs gap-2">
          <FormControl
            name={`${name}.label`}
            titleKey={t("forms.label")}
            containerStyle="w-full"
            required
          />
        </div>
        <div className="flex md:flex-1 items-start text-xs gap-2">
          <FormControl
            name={`${name}.target`}
            titleKey={t("forms.target")}
            containerStyle="w-full"
            required
          />
        </div>
      </div>

      <div className="pr-4">
        <Button
          type="error-line"
          onClick={() => fields.remove(index)}
          icon="TrashIcon"
          className="px-3 py-3"
          compact
        />
      </div>

      <div
        className="absolute w-4 h-4 right-1 top-2 cursor-pointer hover:text-primary border-none"
        {...dragProps}
      >
        <DragHandle />
      </div>
    </div>
  );
}

function KeyIndicatorItem({ index, name, fields, dragProps }: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-end justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="responsive-flex w-full gap-3">
        <div className="flex md:flex-1 items-start text-xs gap-2">
          <FormControl
            name={`${name}.label`}
            titleKey={t("forms.label")}
            containerStyle="w-full"
            required
          />
        </div>
        <div className="flex md:flex-1 items-start text-xs gap-2">
          <FormControl
            name={`${name}.value`}
            titleKey={t("forms.value")}
            containerStyle="w-full"
            required
          />
        </div>
      </div>

      <div className="pr-4">
        <Button
          type="error-line"
          onClick={() => fields.remove(index)}
          icon="TrashIcon"
          className="px-3 py-3"
          compact
        />
      </div>

      <div
        className="absolute w-4 h-4 right-1 top-2 cursor-pointer hover:text-primary border-none"
        {...dragProps}
      >
        <DragHandle />
      </div>
    </div>
  );
}

function StepItem(form: any, formValues: any, rest: any) {
  const { t } = useTranslation();

  const { index, name, fields, dragProps } = rest;

  return (
    <div className="relative mt-2 flex flex-col gap-2 text-sm justify-start w-full rounded-lg border border-slate-200 pt-4 pb-3 px-3 bg-white/80 mb-2">
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-col text-xxs gap-1">
          <FormControl
            name={`${name}.name`}
            titleKey={t("forms.name")}
            required
          />
        </div>
        <div className="flex flex-col text-xxs gap-1">
          <FormControl
            name={`${name}.description`}
            titleKey={t("forms.description")}
          />
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <FormControl
            type="custom"
            name={`${name}.done`}
            onChange={() => compareStepsAround(index, form, formValues)}
          >
            <ToggleSwitch
              rightLabel={t("forms.stepDone")}
              value={Boolean(form?.values?.steps?.[index].done)}
            />
          </FormControl>
        </div>
        <div className="flex gap-1 items-end">
          <Button
            type="error-line"
            onClick={() => fields.remove(index)}
            icon="TrashIcon"
            compact
          />
          <div
            className="absolute w-4 h-4 right-1 top-2 cursor-pointer hover:text-primary border-none"
            {...dragProps}
          >
            <DragHandle />
          </div>
        </div>
      </div>
    </div>
  );
}

function UserItem(
  formValues: any,
  enableContacts: boolean,
  enableUsers: boolean,
  rest: any
) {
  const { index, name, fields } = rest;
  const { isUser } = formValues.siteUsers[rest.index];

  const { t } = useTranslation();

  const onDelete = () => fields.remove(index);

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-end w-full rounded-lg border border-slate-200 py-2 px-3 bg-slate-100">
      <div className="responsive-flex-lg w-full gap-2 items-start">
        {Boolean(enableContacts && enableUsers) && (
          <div className="flex flex-1 items-center pt-2 text-xs shrink-0 gap-1 px-3 my-3 xl:my-0">
            <FormControl name={`${name}.isUser`} type="custom" required>
              <ToggleSwitch
                leftLabel={t("forms.contact")}
                rightLabel={t("forms.user")}
                isDoubleActive
              />
            </FormControl>
          </div>
        )}

        <div className="flex-[2]">
          <FormControl
            name={`${name}.jobPosition`}
            type="custom"
            isSelect
            required
            placeholder={t("forms.jobPosition")}
          >
            <JobPositionSelect />
          </FormControl>
        </div>

        <div className="flex-[2]">
          {isUser ? (
            <FormControl
              name={`${name}.user`}
              type="custom"
              isSelect
              required
              placeholder={t("forms.user")}
            >
              <UserSelect noPublicUsers />
            </FormControl>
          ) : (
            <FormControl
              name={`${name}.contact`}
              type="custom"
              isSelect
              required
              placeholder={t("forms.contact")}
            >
              <ContactSelect />
            </FormControl>
          )}
        </div>

        <div className="pl-4 xl:pr-4 xl:pl-0">
          <Button
            type="error-line"
            onClick={onDelete}
            icon="TrashIcon"
            compact
          />
        </div>

        <div
          className="absolute w-4 h-4 right-1 top-2 cursor-pointer hover:text-primary border-none"
          {...rest.dragProps}
        >
          <DragHandle />
        </div>
      </div>
    </div>
  );
}

function SiteForm({ onSubmit, site }: any) {
  const { navigate } = useRouter();
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization: currentOrganization } = useOrganization();
  const [errorMessage, setErrorMessage] = useState("");

  const [siteTypeId, setSiteTypeId] = useState(
    site?.siteType?.id || currentOrganization?.siteTypes?.[0]?.id
  );

  const onSiteTypeChange = (value: any, form: any) => {
    setSiteTypeId(value);

    form.mutators.setFieldValue({
      field: "parent",
      value: null,
    });
  };

  const currentSiteType = (currentOrganization?.siteTypes || []).find(
    (st: SiteType) => st.id === siteTypeId
  );

  const { items: jobPositionsItems, isFetching: isJobPositionsFetching } =
    useList<JobPosition>("job-positions", {
      defaultFilters: currentOrganization?.id
        ? {
            organization: currentOrganization.id,
          }
        : {},
      defaultSort: "name:ASC",
      cachePrefix: `JobPositions-in-siteEdit_${
        currentOrganization?.id || "all"
      }`,
    });

  const { items: contactItems, isFetching: isContactsFetching } =
    useList<ThreadType>("contacts", {
      defaultFilters: currentOrganization?.id
        ? {
            organization: currentOrganization.id,
          }
        : {},
      cachePrefix: `Contacts-in-siteEdit_${currentOrganization?.id || "all"}`,
    });

  const { items: userItems, isFetching: isUsersFetching } =
    useList<JobPosition>("users", {
      defaultFilters: currentOrganization?.id
        ? {
            confirmed: true,
            "organizations][id": currentOrganization?.id,
            "role][name$ne": "Authenticated",
          }
        : {
            confirmed: true,
            "role][name": "Authenticated",
          },
      cachePrefix: `Users-in-siteEdit_${currentOrganization?.id || "all"}`,
    });

  const { items: threadTypes, isFetching: isThreadTypesFetching } =
    useList<ThreadType>("thread-types", {
      cachePrefix: `ThreadTypes-in-siteEdit_${
        currentOrganization?.id || "all"
      }`,
      populate: ["imageLightMode", "imageDarkMode"],
    });

  const isSomethingFetching =
    isJobPositionsFetching ||
    isUsersFetching ||
    isContactsFetching ||
    isThreadTypesFetching;

  const siteTypesOptions: IRadioBlockItem[] =
    currentOrganization?.siteTypes?.map((type: any) => ({
      id: parseInt(type.id, 10),
      icon: type.icon,
      titleKey: t(`sites.type_${type.name}`),
      subtitleKey: type.shouldBeDisplayedInApp
        ? type.isGeo
          ? t("sites.isGeo")
          : t("sites.isNotGeo")
        : t("sites.isHidden"),
      color: type.color,
    })) || [];

  const threadTypesOptions: IRadioBlockItem[] =
    threadTypes?.map((type: any) => ({
      id: type.id,
      imageLightMode: type.imageLightMode
        ? ImageFromStrapiMedia(type.imageLightMode)?.uri
        : undefined,
      imageDarkMode: type.imageDarkMode
        ? ImageFromStrapiMedia(type.imageDarkMode)?.uri
        : undefined,
      title: type.name,
      subtitle: "",
      color: type.mainColor,
    })) || [];

  const lockedForChildren = Boolean(site?.children?.length > 0);
  const initialValues = useMemo(
    () =>
      site?.id
        ? {
            ...site,
            descriptionRich: site.descriptionRich || site.description,
            geojson: {
              lat: site?.geojson?.lat,
              lng: site?.geojson?.lng,
            },
            geojsonTemp: {
              lat: site?.geojson?.lat,
              lng: site?.geojson?.lng,
            },
            organization: site?.organization?.id,
            parent: site?.parent?.id,
            siteType: site?.siteType?.id,
            siteUsers: site?.siteUsers.map((su: SiteUserType) => ({
              ...su,
              jobPosition: su.jobPosition?.id,
              user: su.user?.id,
              contact: su.contact?.id,
            })),
            threadTypes: site?.threadTypes?.map((type: any) => type.id) || [],
            qAndAs: site?.qAndAs?.map((qAndA: QAndA) => ({
              ...qAndA,
              answerRich: qAndA.answerRich || qAndA.answer,
            })),
            customMarkerSelected: site?.customMarkerSelected?.id,
            adminContact: site?.adminContact?.id,
          }
        : {
            name: "",
            active: true,
            keyIndicators: [],
            links: [],
            steps: [],
            organization:
              currentOrganization?.id || currentUser?.organizations[0]?.id,
            geojson: {
              lat: 43.6043,
              lng: 1.4437,
            },
            geojsonTemp: {
              lat: 43.6043,
              lng: 1.4437,
            },
            siteType:
              currentOrganization && currentOrganization?.siteTypes?.length > 0
                ? currentOrganization?.siteTypes?.[0]?.id
                : null,
            // ne sera pas utilisé si override threadtypes est faux
            threadTypes:
              currentOrganization?.threadTypes?.map((type: any) => type.id) ||
              [],
            qAndAs: [],
            isDraft: false,
            disableThreads: currentOrganization?.disableThreads || false,
            isCustomMarkerSelected: false,
          },
    [site, currentOrganization, currentUser]
  );

  const onSubmitWrapper = async (values: any) => {
    const data = {
      ...values,
      siteUsers: values.siteUsers || [],
      keyIndicators: values.keyIndicators || [],
      links: values.links || [],
      steps: values.steps || [],
    };

    data.geojson = enableMapField(currentOrganization, currentSiteType?.isGeo)
      ? data.geojson
      : null;

    const res = await onSubmit(data);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  const DisplayAdminSwitch = useCallback(
    (fieldValue: boolean, fieldName: string, fieldLabel: string) =>
      currentUser?.isSuperadmin || currentUser?.isAdmin ? (
        <FormControl type="custom" name={fieldName}>
          <ToggleSwitch rightLabel={fieldLabel} />
        </FormControl>
      ) : (
        <div
          className="flex flex-row gap-2 px-2 rounded-md items-center"
          title={t("forms.adminOption")}
        >
          {fieldValue ? (
            <svg
              className="h-7 w-7 text-green-600"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          ) : (
            <svg
              className="h-7 w-7 text-red-600"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {fieldLabel}
        </div>
      ),
    [currentUser, t]
  );

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        setPosition: (args: any, state: any, utils: any) => {
          const position = args[0];

          utils.changeValue(state, "geojson.lat", () => position.lat);
          utils.changeValue(state, "geojson.lng", () => position.lng);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setHelpPosition: ([_, geo], state, utils) => {
          utils.changeValue(
            state,
            "geojsonTemp.lat",
            () => geo && geo.location?.lat()
          );
          utils.changeValue(
            state,
            "geojsonTemp.lng",
            () => geo && geo.location?.lng()
          );
        },
      }}
      render={({ form, handleSubmit, values }: any) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-700 text-base">
            {/* Header */}
            <EditHeader
              title={t(site?.id ? "editItem.site" : "newItem.site", {
                wording: getWording(false, false),
              })}
              subtitle={values.name}
              status={<Status status={values.status} />}
              icon="WorkSiteIcon"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() =>
                    navigate(site && site.id ? `/sites/${site.id}` : "/sites")
                  }
                />
              }
            />

            <form onSubmit={handleSubmit} noValidate autoComplete="nop">
              <FormScrollError />

              <div className="white-box gap-4">
                <SectionTitle
                  icon="CalendarDaysIcon"
                  title={t("forms.siteType")}
                />
                {siteTypesOptions?.length > 0 ? (
                  <FormControl
                    type="custom"
                    name="siteType"
                    inputContainerStyle="flex gap-3"
                    containerStyle="w-full"
                    required
                    onChange={(value: any) => onSiteTypeChange(value, form)}
                    editable={!lockedForChildren}
                    subtitle={
                      lockedForChildren
                        ? t("forms.lockedForChildren")
                        : undefined
                    }
                  >
                    <RadioBlock
                      Component={RadioBlockItem}
                      items={siteTypesOptions}
                      horizontal
                    />
                  </FormControl>
                ) : (
                  <div className="mt-4">
                    <p>{t("forms.noSiteTypes")}</p>
                  </div>
                )}

                {Boolean(currentSiteType?.shouldSelectParent) && (
                  <FormControl
                    type="custom"
                    title={t("forms.parent")}
                    required
                    name="parent"
                    containerStyle="mt-2"
                    isSelect
                  >
                    <SiteSelect
                      filterOnParentType={currentSiteType?.parentType}
                    />
                  </FormControl>
                )}

                <div className="responsive-flex gap-4 mt-4">
                  <div className="w-full md:w-1/4">
                    <FormControl
                      name="name"
                      titleKey={t("sites.name", {
                        wording: getWording(false, false),
                      })}
                      required
                      labelIcon="AiFillStar"
                    />
                  </div>

                  {currentSiteType?.shouldHaveDates && (
                    <>
                      <div className="w-full md:w-1/4">
                        <FormControl
                          name="startDate"
                          titleKey={t("forms.startDate")}
                          labelIcon="CalendarDaysIcon"
                          type="date"
                          infoTextKey={t("forms.dateInfoText")}
                        />
                      </div>

                      <div className="w-full md:w-1/4">
                        <FormControl
                          name="endDate"
                          titleKey={t("forms.endDate")}
                          labelIcon="CalendarDaysIcon"
                          type="date"
                          infoTextKey={t("forms.dateInfoText")}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="flex flex-row gap-2 w-full">
                <div className="flex flex-col mt-2 white-box gap-4 w-1/2">
                  <div className="flex justify-between items-start gap-4">
                    <SectionTitle
                      icon="MdOutlineVisibility"
                      title={t("forms.visibility")}
                    />
                    {values.isDraft && (
                      <div
                        className="self-center px-2 py-1 rounded-xl bg-orange-600 text-white  flex items-center text-sm w-fit"
                        title={t("forms.adminOption")}
                      >
                        {t("forms.draft")}
                      </div>
                    )}
                  </div>

                  {DisplayAdminSwitch(
                    values.active,
                    "active",
                    t("forms.active")
                  )}
                  {DisplayAdminSwitch(
                    values.isDraft,
                    "isDraft",
                    t("forms.inDraft")
                  )}
                </div>

                <div className="flex flex-col justify-top mt-2 white-box gap-4 w-1/2">
                  <SectionTitle
                    icon="BellAlertIcon"
                    title={t("forms.notify")}
                  />
                  {DisplayAdminSwitch(
                    values.canNotifyAuthenticatedUsers,
                    "canNotifyAuthenticatedUsers",
                    t("forms.canNotifyAuthenticatedUsers")
                  )}
                  {DisplayAdminSwitch(
                    values.canNotifyProAndAdminUsers,
                    "canNotifyProAndAdminUsers",
                    t("forms.canNotifyProAndAdminUsers")
                  )}
                </div>
              </div>

              {/* override threadtypes */}

              <div className="white-box mt-2 flex flex-col gap-2">
                {DisplayAdminSwitch(
                  values.overrideThreadTypes,
                  "overrideThreadTypes",
                  t("forms.overrideThreadTypes")
                )}

                {values?.overrideThreadTypes && (
                  <div className="flex flex-col gap-4">
                    <FormControl type="custom" name="disableThreads">
                      <ToggleSwitch rightLabel={t("forms.disableThreads")} />
                    </FormControl>
                    {!values?.disableThreads && (
                      <FormControl
                        type="custom"
                        name="threadTypes"
                        title={t("forms.threadTypes")}
                        inputContainerStyle="flex gap-3 w-full"
                        containerStyle="w-full"
                      >
                        <RadioBlock
                          Component={RadioBlockItem}
                          items={threadTypesOptions}
                          multiple
                          horizontal
                        />
                      </FormControl>
                    )}
                  </div>
                )}
              </div>

              <div className="responsive-flex gap-2 mt-2">
                <div className="md:w-2/3">
                  <div className="flex flex-col gap-2 w-full">
                    {/* Image & desc */}
                    <div className="white-box">
                      <div className="responsive-flex gap-4">
                        <div className="responsive-flex gap-4">
                          <FormControl
                            name="logo"
                            titleKey={t("forms.logo")}
                            type="image"
                            maxWidth={imgSizes.logo.width}
                            maxHeight={imgSizes.logo.height}
                            labelIcon="PhotoIcon"
                          />
                          <FormControl
                            name="mainImage"
                            titleKey={t("forms.mainImage")}
                            type="image"
                            maxWidth={imgSizes.siteImage.width}
                            maxHeight={imgSizes.siteImage.height}
                            labelIcon="PhotoIcon"
                          />
                        </div>

                        <FormControl
                          name="descriptionRich"
                          titleKey={t("forms.description")}
                          type="richtext"
                          labelIcon="BsTextIndentLeft"
                          containerStyle="w-full"
                          className="text-input"
                        />
                      </div>
                      <Transition
                        show={
                          currentOrganization?.siteTypes?.find(
                            (siteType: SiteType) =>
                              siteType.id === values.siteType
                          )?.isGeo ?? false
                        }
                        {...transitionAnimationProps}
                      >
                        <div className="w-full mt-4">
                          <FormControl
                            name="location"
                            titleKey={t("forms.location")}
                            labelIcon="MapPinIcon"
                            className="text-input"
                          />
                        </div>

                        {/* position principale du marker + todo later: polygone autour (donc multipositions) */}
                        {enableMapField(
                          currentOrganization,
                          currentSiteType?.isGeo
                        ) ? (
                          <MapField
                            position={{
                              lat: values.geojson?.lat ?? 43.6043,
                              lng: values.geojson?.lng ?? 1.4437,
                            }}
                            positionTemp={{
                              lat: values.geojsonTemp?.lat ?? 43.6043,
                              lng: values.geojsonTemp?.lng ?? 1.4437,
                            }}
                            onChange={form.mutators.setPosition}
                            editable
                            mapPrefix="siteForm"
                            form={form}
                          />
                        ) : (
                          <div className="mt-2">
                            <p>
                              {t("forms.noModuleMap", {
                                wording: getWording(true),
                              })}
                            </p>
                          </div>
                        )}
                        <FormControl
                          type="custom"
                          name="isCustomMarkerSelected"
                        >
                          <ToggleSwitch
                            rightLabel={t("forms.isCustomMarkerSelected")}
                          />
                        </FormControl>
                        {values.isCustomMarkerSelected && (
                          <FormControl
                            type="custom"
                            title={t("forms.marker")}
                            required
                            name="customMarkerSelected"
                            containerStyle="mt-2 w-1/2"
                            isSelect
                          >
                            <MarkerSelect />
                          </FormControl>
                        )}
                      </Transition>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 white-box mt-2">
                    <SectionTitle
                      title={t("forms.links")}
                      icon="BsCardChecklist"
                    />
                    <div className="flex flex-col gap-2">
                      <FieldArray name="links">
                        {({ fields }: any) => (
                          <div>
                            <SortableList
                              items={fields}
                              onMove={fields.move}
                              render={LinkItem}
                            />

                            <FormAddButton
                              label={t("newItem.link")}
                              onClick={() =>
                                fields.push({
                                  name: "",
                                  random: Math.floor(Math.random() * 10000),
                                })
                              }
                            />
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 white-box mt-2">
                    <SectionTitle
                      title={t("forms.keyIndicators")}
                      icon="BsCardChecklist"
                    />
                    <div className="flex flex-col gap-2">
                      <FieldArray name="keyIndicators">
                        {({ fields }: any) => (
                          <div>
                            <SortableList
                              items={fields}
                              onMove={fields.move}
                              render={KeyIndicatorItem}
                            />

                            <FormAddButton
                              label={t("newItem.keyIndicator")}
                              onClick={() =>
                                fields.push({
                                  name: "",
                                  random: Math.floor(Math.random() * 10000),
                                })
                              }
                            />
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1 white-box mt-2">
                    <SectionTitle
                      title={
                        currentSiteType?.shouldSelectParent
                          ? t("forms.usersParent", {
                              wording: getWording(false, false),
                            })
                          : t("forms.users")
                      }
                      icon="UserGroupIcon"
                    />

                    {currentUser?.isPro ? (
                      site.siteUsers?.length > 0 ? (
                        <div className="grid-1or2 gap-4">
                          {site.siteUsers?.map((u: SiteUserType) => (
                            <div className="grid grid-cols-1 gap-3">
                              <SiteUser item={u} />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <EmptyResult text={t("common.noSiteUser")} />
                      )
                    ) : currentSiteType?.shouldSelectParent ? (
                      site?.siteUsers?.length > 0 ? (
                        <div className="grid-1or2 gap-4">
                          {site.siteUsers?.map((u: SiteUserType) => (
                            <div className="grid grid-cols-1 gap-3">
                              <SiteUser item={u} />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <EmptyResult text={t("common.noSiteUser")} />
                      )
                    ) : !isSomethingFetching &&
                      (!jobPositionsItems?.length ||
                        (!userItems?.length && !contactItems?.length)) ? (
                      <EmptyBlockWithCTA
                        title={t("emptyDatasCta.noUsersOrContactsOrRolesYet")}
                        content={t("emptyDatasCta.forJobPositions", {
                          wording: getWording(false),
                        })}
                      />
                    ) : (
                      <div className="flex flex-col gap-1">
                        <FieldArray name="siteUsers">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  UserItem(
                                    values,
                                    Boolean(contactItems?.length),
                                    Boolean(userItems?.length),
                                    rest
                                  )
                                }
                              />

                              <FormAddButton
                                label={t("newItem.user")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    isUser: Boolean(userItems?.length),
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    )}
                  </div>
                </div>

                <div className="md:w-1/3 flex flex-col gap-2">
                  <div className="flex flex-col gap-2 secondary-box">
                    <SectionTitle
                      title={t("forms.contactLinked")}
                      icon="UserCircleIcon"
                    />
                    {currentUser?.isPro ? (
                      site?.adminContact?.name ??
                      site?.adminContact?.username ?? (
                        <EmptyResult text={t("common.noContact")} />
                      )
                    ) : (
                      <FormControl
                        name="adminContact"
                        type="custom"
                        isSelect
                        placeholder={t("forms.contact")}
                        disabled={currentUser?.isPro}
                      >
                        <UserSelect
                          noPublicUsers
                          disabled={currentUser?.isPro}
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className="document-box">
                    <div className="flex items-center text-xs mb-4 gap-1 field-title">
                      <BsFiles className="w-3 h-3" />
                      {t("forms.documents")}
                    </div>
                    <FormControl type="file" name="documents" />
                  </div>
                  <div className="flex flex-col gap-2 tertiary-box">
                    <SectionTitle
                      title={t("forms.steps")}
                      icon="CheckCircleIcon"
                    />
                    <FieldArray name="steps">
                      {({ fields }: any) => (
                        <div>
                          <SortableList
                            items={fields}
                            onMove={(fromIndex: any, toIndex: any) => {
                              fields.move(fromIndex, toIndex);
                              compareStepsAround(toIndex, form, values);
                            }}
                            render={(rest) => StepItem(form, values, rest)}
                          />

                          <FormAddButton
                            label={t("newItem.step")}
                            onClick={() =>
                              fields.push({
                                name: "",
                                random: Math.floor(Math.random() * 10000),
                              })
                            }
                          />
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  {site?.id && (
                    <div className="flex flex-col gap-1 white-box mt-2">
                      <SectionTitle
                        title={t("forms.qAndA")}
                        icon="QuestionMarkCircleIcon"
                      />
                      <div className="flex flex-col gap-1">
                        <FieldArray name="qAndAs">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) => QAndAItemForm(values, rest)}
                              />

                              <FormAddButton
                                label={t("newItem.qAndA")}
                                onClick={() =>
                                  fields.push({
                                    site: site?.id,
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() =>
                  navigate(site && site.id ? `/sites/${site.id}` : "/sites")
                }
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default SiteForm;
