import useItem from "common/hooks/useItem";

import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import HeaderButtons from "common/components/DetailHeader/HeaderButtons";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import PublicationIcon from "common/components/PostsAndActivities/PublicationIcon";
import { Activity } from "types/Activity";
import DefaultFilesList from "common/components/FileUploader/DefaultFilesList";
import PageLoading from "common/components/PageLoading/PageLoading";
import { useTranslation } from "react-i18next";
import PublicationHeaderInfos from "common/components/PostsAndActivities/PublicationHeaderInfos";
import PublicationHeaderSubTitle from "common/components/PostsAndActivities/PublicationHeaderSubTitle";
import useAuth from "common/hooks/useAuth";
import ContactGroups from "common/components/ContactGroups/ContactGroups";
import Attendees from "common/components/Attendees/Attendees";
import checkRights from "common/utils/checkRights";
import EmptyResult from "common/components/EmptyResult/EmptyResult";
import { useContext, useEffect } from "react";
import AlertsContext from "common/providers/alerts";

function ActivityDetails() {
  const router = useRouter();
  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const { item, isFetching, error }: any = useItem("activities", id, {
    populate: ["documents", "site", "contactGroups", "attendees"],
  });

  const { user: currentUser } = useAuth();

  const canManageActivity = checkRights(
    currentUser,
    "canManageActivity",
    [item?.site.id],
    ["isAdmin"]
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const {
    name,
    description,
    date,
    documents = [],
    attendees,
    site,
    contactGroups = [],
  } = (item as Activity) || {};

  const contents = description?.split("\n");

  if (isFetching || !item) {
    return <PageLoading />;
  }

  /* const extraFieldsList = [
    { title: "Localisation", value: location },
    { title: "Estimation du niveau de pertubation", value: alertLevel },
    { title: "Type de perturbation", value: alertType },
  ]; */

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <DetailHeader
        backLink="/activities"
        title={name}
        subtitleIcon="WorkSiteIcon"
        TitleIcon={
          <PublicationIcon
            labelType="activity"
            iconStyle="w-7 h-7 text-white"
          />
        }
        HeaderInfos={
          date ? (
            <PublicationHeaderInfos
              publishedDate={date}
              label={t("item.activity")}
            />
          ) : undefined
        }
        SubTitle={
          <PublicationHeaderSubTitle
            siteName={site.name}
            location={site.location}
          />
        }
        HeaderButtons={
          <HeaderButtons
            editUrl={`/activities/edit/${id}`}
            editDisabled={!canManageActivity}
          />
        }
      />

      <div className="responsive-flex gap-2 mt-2">
        <div className="flex-1">
          <div className="flex flex-col gap-2 w-full">
            {/* desc */}
            <div className="white-box">
              {description ? (
                contents?.map((p: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p key={`p-${index}`} className="mb-4">
                    {p}
                  </p>
                ))
              ) : (
                <EmptyResult text={t("common.noDescription")} />
              )}
            </div>
            <div className="flex flex-col w-full gap-4 document-box">
              <SectionTitle title={t("forms.documents")} icon="BsFiles" />
              <DefaultFilesList items={documents || []} disabled />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 md:w-1/3 items-start">
          <div className="flex w-full flex-col gap-4 white-box">
            <SectionTitle title={t("forms.recipients")} className="mb-1" />
            <ContactGroups contactGroups={contactGroups || []} />
          </div>

          <div className="flex w-full flex-col gap-4 white-box">
            <SectionTitle title={t("forms.attendees")} className="mb-1" />
            <Attendees attendees={attendees} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityDetails;
