/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import Icon from "components/Icon/Icon";
import { Constants } from "constants/Constants";
import React, {
  useState,
  useCallback,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import { Dots } from "react-activity";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import useAuth from "common/hooks/useAuth";
import Button from "../Button/Button";
import ThreadGallery from "../ThreadGallery/ThreadGallery";
import DefaultFilesList from "./DefaultFilesList";

type Props = {
  value?: any;
  accept?: string;
  children?: ReactNode;
  onChange?: (values: any) => void;
  nbCols?: number;
  label?: string;
  maxSize?: number;
  max?: number;
  name?: string;
  onUpload?: (value: any) => void;
  type?: string;
  canRenameFiles?: boolean;
};

function FileUploader({
  value,
  accept,
  onChange = () => {},
  maxSize = Infinity,
  max = Infinity,
  onUpload,
  // "data-cy": dataCy,
  type = "documents",
  canRenameFiles = true,
  ...rest
}: Props) {
  const [files, setFiles] = useState([]);
  const uploadUrl = `${Constants.API_URL}/upload`;
  const [uploadingFiles, setUploadingFiles] = useState({});
  const { token }: any = useAuth();

  const onRemoveFile = useCallback(
    (removeFile: any) => {
      const newFiles = files.filter((f: any) => {
        if (f.hash) return f.hash !== removeFile.hash;
        return f.id !== removeFile.id;
      });
      onChange(newFiles);
    },
    [files, onChange]
  );

  const sendFile = useCallback(
    async (file: any) =>
      new Promise((resolve) => {
        const req = new XMLHttpRequest();

        req.upload.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            setUploadingFiles((prevFiles: any) => ({
              ...prevFiles,
              [file.name]: {
                ...file,
                state: "pending",
                percentage: (event.loaded / event.total) * 100,
              },
            }));
          }
        });

        req.upload.addEventListener("load", () => {
          setUploadingFiles((prevFiles: any) => ({
            ...prevFiles,
            [file.name]: {
              ...file,
              state: "done",
              percentage: 100,
            },
          }));
        });

        req.upload.addEventListener("error", () => {
          setUploadingFiles((prevFiles: any) => ({
            ...prevFiles,
            [file.name]: {
              ...prevFiles[file.name],
              state: "error",
              percentage: 0,
            },
          }));
          resolve(null);
        });

        // eslint-disable-next-line func-names
        req.addEventListener("load", function () {
          try {
            // eslint-disable-next-line react/no-this-in-sfc
            const response = JSON.parse(this.response);

            const res = Array.isArray(response) ? response[0] : response;

            if (onUpload) {
              onUpload(res);
            }

            resolve({
              ...res,
              state: "done",
              percentage: 100,
              filename: res.filename ?? res.name,
              name: res.filename ?? res.name,
            });
          } catch (e) {
            console.error(e);
            resolve({
              ...file,
              state: "error",
              percentage: 0,
              filename: "titi",
            });
          }
        });

        const formData = new FormData();

        formData.append("files", file.file);
        req.onload = () => {
          // console.log(req.responseText);
        };

        req.open("POST", uploadUrl, true);
        req.setRequestHeader("Authorization", `Bearer ${token}`);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        req.send(formData);
      }),
    [uploadUrl, token, onUpload]
  );

  const handleFileSelected = useCallback(
    async (inputFiles: any) => {
      if (!inputFiles.length || files.length + inputFiles.length > max) {
        return;
      }
      const fileslist = [] as any[];
      // eslint-disable-next-line no-plusplus
      inputFiles.forEach((inputFile: File) => {
        const maxIndex = Math.max(
          ...fileslist.map((currentFile) => currentFile.index)
        );
        const file: any = {
          index: fileslist.length ? maxIndex + 1 : 0,
          inputFile,
          name: inputFile.name,
          url: URL.createObjectURL(inputFile),
        };

        if (file.inputFile.size <= maxSize) {
          fileslist.push(file);
        }
      });

      const tmpFiles = fileslist;

      const filesToUpload = {} as any;

      for (const file of tmpFiles) {
        filesToUpload[file.name] = {
          name: file.name,
          url: file.url,
          file: file.inputFile,
          type: file.inputFile.type,
          state: "pending",
          percentage: 0,
        };
      }
      setUploadingFiles(filesToUpload);

      const promiseFiles = [] as any;

      Object.values(filesToUpload).forEach((f) => {
        promiseFiles.push(sendFile(f));
      });
      const uploadedFiles = await Promise.all(promiseFiles);

      const newFiles = [...files] as any;

      uploadedFiles.forEach((file) => {
        if (file) newFiles.push(file);
      });
      setFiles(newFiles);
      onChange(newFiles);

      setUploadingFiles({});
    },
    [files, max, maxSize, onChange, sendFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileSelected,
    multiple: true,
    accept: accept ? { [accept]: [] } : undefined,
  });

  const list = useMemo(() => {
    const ufiles = Object.values(uploadingFiles) ?? [];
    const fileslist = [...files, ...ufiles];

    if (fileslist.length < 1) {
      return null;
    }

    if (type === "documents") {
      return (
        <DefaultFilesList
          items={fileslist}
          onChange={onChange}
          onRemoveFile={onRemoveFile}
          editMode={canRenameFiles}
        />
      );
    }

    return (
      <div className="pr-2 border-r border-slate-200">
        <ThreadGallery
          documents={fileslist}
          onRemoveFile={onRemoveFile}
          small
          editMode={canRenameFiles}
        />
      </div>
    );
  }, [uploadingFiles, files, type, onRemoveFile, canRenameFiles, onChange]);

  const { t } = useTranslation();

  const addIcon = useMemo(() => {
    const ufiles = Object.values(uploadingFiles) ?? [];
    const fileslist = [...files, ...ufiles];
    const isLoading = fileslist?.find(
      (item: any) => item.state === "pending"
    ) as any;

    if (type === "documents") {
      return (
        <div className="flex cursor-pointer gap-1 mb-1 text-sm items-center justify-center w-full uppercase rounded-lg border border-dashed border-orange-300 text-orange-500 hover:text-orange-600 hover:border-orange-400 py-2 px-3">
          <Icon name="PlusCircleIcon" className="w-4 h-4" />
          {t("newItem.doc")}
        </div>
      );
    }
    return (
      <div className="flex flex-col items-center justify-center">
        {isLoading ? (
          <div className="px-1 w-16 flex flex-col items-center">
            <span className="text-sm text-slate-700">
              {(isLoading.percentage || 0).toFixed(0)} %
            </span>

            <Dots />
          </div>
        ) : (
          <div>
            <Button type="neutral-line" icon="IoIosAttach" compact />
          </div>
        )}
      </div>
    );
  }, [type, files, uploadingFiles, t]);

  useEffect(() => {
    if (value) {
      setFiles(value);
    } else {
      setFiles([]);
    }
  }, [value, setFiles]);

  return (
    <div {...rest}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {addIcon}
      </div>
      {list}
    </div>
  );
}

export default FileUploader;
