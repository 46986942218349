import { User } from "types";
import Avatar from "../Avatar/Avatar";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface IAvatarsStack {
  users: User[];
  stack?: boolean;
  size?: string;
}
function AvatarsStack({ users, stack = false, size = "6" }: IAvatarsStack) {
  const list = users?.map((user) =>
    user?.name ? (
      <TooltipWrapper
        key={user.name}
        className={stack ? "-ml-2" : "mr-1"}
        content={<span>{user?.name}</span>}
      >
        <Avatar name={user?.name} image={user?.avatar} size={size} />
      </TooltipWrapper>
    ) : null
  );
  return <div className="flex pl-2 overflow-hidden">{list}</div>;
}

export default AvatarsStack;
