function LoginHeader() {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        className="mx-auto h-[130px] w-auto object-contain"
        src={`/templates/${import.meta.env.VITE_TEMPLATE}/loginLogo.png`}
        alt="Logo"
      />
    </div>
  );
}

export default LoginHeader;
