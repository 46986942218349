import Icon from "components/Icon/Icon";
import { PostType } from "types/PostType";
import { getDefaultPostTypeProps } from "conf/pubTypesData";
import ImageComponent from "../ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "../ImageFromStrapiMedia/ImageFromStrapiMedia";

interface IPublicationIcon {
  type?: PostType;
  labelType?: string;
  iconStyle: string;
}

const containerStyle =
  "flex w-full h-full items-center justify-center p-2 rounded-lg shadow-lg";

function PublicationIcon({ type, labelType, iconStyle }: IPublicationIcon) {
  return type ? (
    <div
      className={`${containerStyle}  shadow-slate-300`}
      style={{
        backgroundColor:
          type.color || getDefaultPostTypeProps(type.value)?.color,
      }}
    >
      {type?.imageDarkMode ? (
        <div className={` rounded-md overflow-hidden ${iconStyle} `}>
          <ImageComponent
            image={ImageFromStrapiMedia(type?.imageDarkMode)?.uri}
          />
        </div>
      ) : (
        <Icon
          name={type.icon || getDefaultPostTypeProps(type.value)?.icon}
          className={iconStyle}
        />
      )}
    </div>
  ) : (
    <>
      {labelType === "contact-group" && (
        <div className={`${containerStyle} bg-blue-500 shadow-blue-300`}>
          <Icon name="UserGroupIcon" className={iconStyle} />
        </div>
      )}

      {labelType === "activity" && (
        <div className={`${containerStyle} bg-blue-500 shadow-blue-300`}>
          <Icon name="InformationCircleIcon" className={iconStyle} />
        </div>
      )}
    </>
  );
}
export default PublicationIcon;
