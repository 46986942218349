import { filter, orderBy } from "lodash";
import { Message } from "types/Message";

const getLastAuthorAnswerId = (messages: Message[]) => {
  const userMessages = filter(
    messages,
    (message) => message.author?.id === messages[0].author?.id
  );

  return orderBy(userMessages, "createdAt")[0]?.id;
};

export const getLastPrivateThreadId = (messages: Message[]) => {
  const ms = [...messages];
  const lastPrivateThread = ms
    .reverse()
    .find((m: Message) => Boolean(m.privateThreadIntern));

  return lastPrivateThread?.id;
};

export default getLastAuthorAnswerId;
