import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { FiChevronsRight } from "react-icons/fi";

interface IDatesRange {
  startDate?: Date;
  endDate?: Date;
  isCurrentDate?: boolean;
  hideHours?: boolean;
}

function DatesRange({
  startDate,
  endDate,
  isCurrentDate = false,
  hideHours = false,
}: IDatesRange) {
  const startHour = startDate ? dayjs(startDate).format("HH:mm") : null;
  const startDay = startDate ? dayjs(startDate).format("DD MMM 'YY") : null;
  const endHour = endDate ? dayjs(endDate).format("HH:mm") : null;
  const endDay = endDate ? dayjs(endDate).format("DD MMM 'YY") : null;

  const isSameDay = startDay === endDay || !endDay || !startDay;

  const currentDateStyle = "bg-emerald-100";
  const baseStyle = "min-w-[120px] w-full text-center px-4 py-1 rounded-xl";
  const monoBaseStyle =
    "min-w-[120px] flex flex-col justify-center text-center px-4 py-2 rounded-xl";

  const { t } = useTranslation();

  return isSameDay ? (
    <div className="flex items-center text-slate-500 gap-1">
      <div
        className={`${monoBaseStyle} ${
          isCurrentDate ? currentDateStyle : "bg-slate-100"
        }`}
      >
        <div
          className={`w-full text-sm leading-3 font-bold ${
            isCurrentDate ? "text-emerald-900" : "text-secondary"
          }`}
        >
          {startDay || endDay}
        </div>

        {!hideHours && (
          <div className="text-xs tracking-wider leading-4 flex w-full justify-center items-center my-1">
            {startHour}
            {Boolean(startHour && endHour) && (
              <div className="bg-white p-1 rounded-full mx-1 z-10 border border-slate-200">
                <FiChevronsRight className="w-3 h-3 text-slate-500" />
              </div>
            )}
            {endHour}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="flex w-full items-center text-slate-500 gap-1">
      {startDate && (
        <div
          className={`${baseStyle} ${
            isCurrentDate ? currentDateStyle : "bg-slate-100"
          }`}
        >
          <div
            className={`w-full text-sm leading-3 font-bold my-1 ${
              isCurrentDate ? "text-emerald-900" : "text-secondary"
            }`}
          >
            {startDay}
          </div>
          {!hideHours && (
            <div className="w-full text-xs tracking-wider leading-4">
              {endDate ? t("date.from") : t("date.at")} {startHour}
            </div>
          )}
        </div>
      )}

      {Boolean(startDate && endDate) && (
        <div className="bg-white p-1 rounded-full -mr-3 -ml-3 z-10 border border-slate-200">
          <FiChevronsRight className="w-3 h-3 text-slate-500" />
        </div>
      )}

      {endDate && (
        <div
          className={`${baseStyle} ${
            isCurrentDate ? currentDateStyle : "bg-slate-100"
          }`}
        >
          <div
            className={`w-full text-sm leading-3 font-bold my-1 ${
              isCurrentDate ? "text-emerald-900" : "text-secondary"
            }`}
          >
            {endDay}
          </div>
          {!hideHours && (
            <div className="w-full text-xs tracking-wider leading-4">
              {t("date.to")} {endHour}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DatesRange;
