/* eslint-disable react/jsx-curly-brace-presence */
import { useCallback, useContext, useEffect } from "react";
import dayjs from "dayjs";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

import { User } from "types";
import { Post } from "types/Post";
import { QAndA as QAndAType } from "types/QAndA";

import useItem from "common/hooks/useItem";
import CommentsList from "common/components/CommentsList/CommentsList";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import DatesRange from "common/components/DatesRange/DatesRange";
import ContactGroups from "common/components/ContactGroups/ContactGroups";
import HeaderButtons from "common/components/DetailHeader/HeaderButtons";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import PublicationField from "common/components/PostsAndActivities/PublicationField";
import DefaultFilesList from "common/components/FileUploader/DefaultFilesList";
import PageLoading from "common/components/PageLoading/PageLoading";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import PublicationIcon from "common/components/PostsAndActivities/PublicationIcon";
import PublicationHeaderSubTitle from "common/components/PostsAndActivities/PublicationHeaderSubTitle";
import PublicationHeaderInfos from "common/components/PostsAndActivities/PublicationHeaderInfos";
import MapField from "common/components/MapField/MapField";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import EmptyResult from "common/components/EmptyResult/EmptyResult";
import QAndA from "common/components/QAndA/QAndA";
import Icon from "components/Icon/Icon";
import Status from "common/components/Status/Status";
import checkRights from "common/utils/checkRights";
import getCommentsNumber from "common/utils/getCommentsNumber";
import getWording from "common/utils/wording";
// import ReactMarkdown from "react-markdown";
import AlertsContext from "common/providers/alerts";
import enableMapField from "common/utils/enableMapField";
import ReactQuill from "react-quill";
import { ContactGroup } from "../../types/ContactGroup";

function PostDetails() {
  const router = useRouter();
  const id = router.query.id as string;
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();
  const { organization: currentOrganization } = useOrganization();

  const {
    item,
    fetchItem,
    isFetching,
    updateItem,
    isUpdateFetching,
    error,
  }: any = useItem("posts", id, {
    populate: [
      "type",
      "type.imageLightMode",
      "type.imageDarkMode",
      "site",
      "site.siteType",
      "author",
      "mainImage",
      "documents",
      "contactGroups",
      "upVotes",
      "privateThread",
      "privateThread.messages",
      "privateThread.messages.documents",
      "privateThread.messages.author",
      "qAndAs",
      "alertType",
      "plannedNotifications",
    ],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const canManagePost = checkRights(
    currentUser,
    "canManagePost",
    item ? [item?.site?.id] : undefined,
    ["isAdmin"]
  );

  const canComment = checkRights(
    currentUser,
    "canComment",
    item ? [item?.site?.id] : undefined,
    ["isAdmin"]
  );

  const {
    title,
    content,
    contentRich,
    mainImage,
    type,
    site: { name: siteName = "", location: siteLocation = "" } = {},
    author,
    status,
    publishedDate,
    endPublishedDate,
    action,
    actionRich,
    advice,
    adviceRich,
    location,
    alertLevel,
    alertType,
    startEventDate,
    endEventDate,
    notifyBySMS,
    smsContent,
    contactGroups = [],
    documents = [],
    upVotes = [],
    isPublic,
    privateThread,
    numberOfEmailsSent,
    numberOfPushSent,
    numberOfSMSSent,
    notificationSent,
    plannedNotifications,
  } = (item as Post) || {};

  const contents = content?.split("\n");
  const contentsAdvice = advice?.split("\n");
  const contentsAction = action?.split("\n");

  const comments = privateThread?.messages;
  const authorName = get(author, "name");
  const isPendingModeration = status === "pending_moderation";
  const canUnpublish = ["pending_publication", "online"].includes(status);
  const hasLiked =
    isPendingModeration &&
    currentUser &&
    !!upVotes.find((userItem) => userItem.id === currentUser.id);

  const contactGroupsPublic = isPublic
    ? [
        {
          name: t("common.public"),
          description: t("common.publicDesc", { wording: getWording(true) }),
          type: "public",
          isSpecial: true,
        } as ContactGroup,
      ]
    : [];

  const isSmsEnabled = currentOrganization?.activeModuleSms;

  const onUpdateStatus = useCallback(
    async (newStatus: string) => {
      try {
        await updateItem(id, { status: newStatus }, true, "updateStatus");
      } catch (e) {
        setAlert(e, "danger");
      }
    },
    [updateItem, id, setAlert]
  );

  const onUpdateUpVoted = useCallback(async () => {
    try {
      const userId = currentUser?.id || 0;
      const newUpVotes = hasLiked
        ? upVotes.filter((user: User) => user.id !== userId)
        : [...upVotes, currentUser];

      await updateItem(id, { upVotes: newUpVotes }, true, "onUpdateUpVoted");
    } catch (e) {
      setAlert(e, "danger");
    }
  }, [updateItem, id, hasLiked, currentUser, upVotes, setAlert]);

  const onValidate = useCallback(() => {
    if (publishedDate && dayjs().isBefore(publishedDate)) {
      onUpdateStatus("pending_publication");
      return null;
    }

    if (endPublishedDate && dayjs().isAfter(endPublishedDate)) {
      onUpdateStatus("offline");
      return null;
    }
    onUpdateStatus("online");
    return null;
  }, [onUpdateStatus, publishedDate, endPublishedDate]);

  const onUnpublish = useCallback(() => {
    onUpdateStatus("pending_moderation");
  }, [onUpdateStatus]);

  const onLike = useCallback(() => {
    onUpdateUpVoted();
  }, [onUpdateUpVoted]);

  const refresh = useCallback(() => {
    fetchItem();
  }, [fetchItem]);

  if (isFetching && !item) {
    return <PageLoading />;
  }

  let extraFieldsList = [{ title: t("forms.location"), value: location }];

  if (item?.type.value === "alert") {
    if (alertLevel) {
      extraFieldsList = [
        ...extraFieldsList,
        {
          title: t("forms.alertLevel"),
          value: t(`forms.${alertLevel}`),
        },
      ];
    }

    if (alertType) {
      extraFieldsList = [
        ...extraFieldsList,
        { title: t("forms.alertType"), value: alertType.name },
      ];
    }
  }

  const commentsNumber = privateThread
    ? getCommentsNumber([privateThread])
    : undefined;

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <DetailHeader
        backLink="/posts"
        title={title}
        Status={
          <div className="flex gap-2 flex-wrap">
            <Status status={status} />
          </div>
        }
        subtitleIcon="WorkSiteIcon"
        TitleIcon={
          <PublicationIcon type={type} iconStyle="w-7 h-7 text-white" />
        }
        HeaderInfos={
          <PublicationHeaderInfos
            publishedDate={publishedDate}
            endPublishedDate={endPublishedDate}
            authorName={authorName}
          />
        }
        SubTitle={
          <PublicationHeaderSubTitle
            siteName={siteName}
            location={siteLocation}
          />
        }
        HeaderButtons={
          <HeaderButtons
            editUrl={`/posts/edit/${id}`}
            editDisabled={!canManagePost}
            likeCount={upVotes.length}
            hasLiked={hasLiked}
            // validate
            onValidate={onValidate}
            validateDisabled={!canManagePost || !isPendingModeration}
            // unpublish
            unpublishDisabled={!canManagePost || !canUnpublish}
            onUnpublish={onUnpublish}
            isPublishing={isUpdateFetching("updateStatus")}
            // like
            onLike={onLike}
            likeDisabled={!isPendingModeration}
            isLiking={isUpdateFetching("onUpdateUpVoted")}
          />
        }
        commentsNumber={commentsNumber}
      />

      <div className="responsive-flex gap-2 mt-2">
        <div className="flex-1">
          <div className="flex flex-col gap-2 w-full">
            {/* Image & desc */}
            <div className="responsive-flex white-box">
              <div className="responsive-flex gap-4">
                <div className="w-full rounded-lg overflow-hidden md:w-[180px] h-[250px] md:h-[180px]">
                  <ImageComponent
                    image={
                      mainImage
                        ? ImageFromStrapiMedia(mainImage).uri
                        : undefined
                    }
                  />
                </div>
                <div className="flex-1 py-4">
                  {contentRich ? (
                    <div className="richtext-rendered">
                      <ReactQuill theme="snow" readOnly value={contentRich} />
                    </div>
                  ) : content ? (
                    contents?.map((p: any, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <p key={`p-${index}`} className="mb-4">
                        {p}
                      </p>
                    ))
                  ) : (
                    <p>{t("forms.noContent")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 white-box mt-2">
            <SectionTitle title={t("forms.diffusion")} icon="BsPhoneVibrate" />
            <div>
              <SectionTitle title={t("forms.recipients")} className="mb-1" />
              <ContactGroups
                contactGroups={[...contactGroupsPublic, ...contactGroups]}
              />
            </div>

            {isSmsEnabled && notifyBySMS && (
              <div>
                <SectionTitle title={t("forms.informBySms")} className="mb-1" />
                <div className="responsive-flex items-center gap-1 mt-1 italic ">
                  <Icon
                    name="RiDoubleQuotesL"
                    className="self-start text-slate-300"
                  />
                  {smsContent}
                  <Icon
                    name="RiDoubleQuotesR"
                    className="self-end text-slate-300"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Extra fields & Map : alert & events */}
          {item?.type?.value !== "info" && (
            <div className="white-box responsive-flex justify-between items-start mt-2">
              {/* Extra fields */}
              <div className="flex w-full flex-col justify-start pr-6 gap-4">
                <div className="flex flex-col gap-4">
                  <SectionTitle
                    title={t("forms.infos")}
                    icon="InformationCircleIcon"
                  />
                  <div className="flex w-full flex-col gap-2">
                    {extraFieldsList.map((field: any) => (
                      <PublicationField {...field} />
                    ))}
                  </div>
                </div>

                {startEventDate && endEventDate && (
                  <div className="my-2">
                    <DatesRange
                      startDate={startEventDate}
                      endDate={endEventDate}
                      isCurrentDate={
                        dayjs().isBetween(
                          dayjs(startEventDate),
                          dayjs(endEventDate)
                        ) ||
                        (dayjs().isAfter(dayjs(startEventDate)) &&
                          !endEventDate)
                      }
                    />
                  </div>
                )}
              </div>

              {/* position principale du marker + todo later: polygone autour (donc multipositions)  */}
              {enableMapField(
                currentOrganization,
                item?.site?.siteType?.isGeo
              ) && (
                <div className="my-2 w-full">
                  <MapField
                    noMargin
                    mapPrefix="postDetails"
                    position={{
                      lat: item?.geojson?.lat,
                      lng: item?.geojson?.lng,
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {/* Prevention : alert  */}
          {item?.type?.value === "alert" &&
            Boolean(advice || action || adviceRich || actionRich) && (
              <div className="white-box mt-2">
                <div>
                  <SectionTitle
                    title={t("forms.actionsAndAdvices")}
                    icon="BsListCheck"
                  />
                  <div className="responsive-flex mt-6 w-full gap-6">
                    {Boolean(advice || adviceRich) && (
                      <div className="flex flex-col gap-1 w-full md:border-r border-slate-200">
                        <SectionTitle title={t("forms.advices")} />

                        {adviceRich ? (
                          <div className="richtext-rendered">
                            <ReactQuill
                              theme="snow"
                              readOnly
                              value={adviceRich}
                            />
                          </div>
                        ) : (
                          <div>
                            {contentsAdvice?.map((p: any, index: number) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <p key={`p-advice-${index}`} className="mb-4">
                                {p}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                    {Boolean(action || actionRich) && (
                      <div className="flex flex-col gap-1 w-full ">
                        <SectionTitle title={t("forms.actions")} />

                        {actionRich ? (
                          <div className="richtext-rendered">
                            <ReactQuill
                              theme="snow"
                              readOnly
                              value={actionRich}
                            />
                          </div>
                        ) : (
                          <div>
                            {contentsAction?.map((p: any, index: number) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <p key={`p-action-${index}`} className="mb-4">
                                {p}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

          <div className="white-box mt-2">
            <div>
              <div className="responsive-flex items-center text-xs mb-4 gap-1 field-title">
                <QuestionMarkCircleIcon className="w-4 h-4" />
                {t("forms.qAndA")}
              </div>

              {item?.qAndAs?.length > 0 ? (
                item.qAndAs?.map((q: QAndAType) => <QAndA item={q} />)
              ) : (
                <EmptyResult text={t("common.noQAndA")} />
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 md:w-1/3 items-start">
          <div className="flex flex-col w-full gap-4 tertiary-box">
            <SectionTitle
              title={t("forms.notifications")}
              icon="BellAlertIcon"
            />
            {notificationSent ? (
              <div className="flex flex-col">
                <span>
                  {`Nombre de Notifications PUSH envoyées : ${
                    numberOfPushSent ?? "Aucune"
                  }`}
                </span>
                <span>{`Nombre d'emails envoyés : ${
                  numberOfEmailsSent ?? "Aucun"
                }`}</span>
                <span>{`Nombre de SMS envoyés : ${
                  numberOfSMSSent ?? "Aucun"
                }`}</span>
              </div>
            ) : (
              <span className="text-slate-500 italic text-sm">
                {plannedNotifications && plannedNotifications.length > 0
                  ? "La notification principale n'a été envoyée pour cette publication"
                  : "Aucune notification n'a été envoyée pour cette publication"}
              </span>
            )}
            {plannedNotifications && plannedNotifications.length > 0 && (
              <div>
                {plannedNotifications?.map((n) => {
                  return (
                    <div className="flex flex-row gap-1 text-sm">
                      <div>
                        {n.notificationSent
                          ? "Une notification a été envoyée le "
                          : "Une notification est prévue pour le"}
                      </div>
                      <div className="font-bold">
                        {`${dayjs(n?.notificationDate).format("LLL")}.`}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="flex flex-col w-full gap-4 document-box">
            <SectionTitle title={t("forms.documents")} icon="BsFiles" />
            <DefaultFilesList items={documents} disabled />
          </div>
          <div className="flex w-full flex-col gap-4 secondary-box">
            <SectionTitle
              title={t("forms.comments")}
              icon="ChatBubbleLeftRightIcon"
            />
            <CommentsList
              comments={comments}
              canComment={canComment}
              postId={item?.id}
              privateThreadId={privateThread?.id}
              refresh={refresh}
              isPrivate
              secondary
              siteId={item?.site?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostDetails;
