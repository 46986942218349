import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import EditHeader from "common/components/EditHeader/EditHeader";
import useRouter from "common/hooks/use-router";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import imgSizes from "conf/imgSizes.json";

function ThreadTypeForm({ onSubmit, threadType }: any) {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [errorMessage, setErrorMessage] = useState("");

  const colorOptions = useMemo(
    () => [
      { label: "Rouge", value: "#DD1E1E" },
      { label: "Orange", value: "#FF9253" },
      { label: "Bleu", value: "#34A6FF" },
      { label: "Violet", value: "#631F9A" },
      { label: "Vert", value: "#006013" },
      { label: "Turquoise", value: "#0099A4" },
    ],
    []
  );

  const initialValues = useMemo(
    () =>
      threadType?.id
        ? {
            mainColor: "#34A6FF",
            ...threadType,
          }
        : {
            name: "",
            imageLightMode: null,
            imageDarkMode: null,
            mainColor: "#34A6FF",
          },
    [threadType]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(
                threadType?.id ? "editItem.threadType" : "newItem.threadType"
              )}
              subtitle={values.name}
              icon="BiCommentError"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/thread-types")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex gap-2 mt-2">
                <div className="md:w-full mb-2 md:mb-0">
                  <div className="flex flex-col gap-6 w-full">
                    <div className="white-box">
                      <div className="responsive-flex gap-6">
                        <div className="flex flex-col w-full md:w-auto md-flex-[1]">
                          <div className="flex flex-row gap-6">
                            <FormControl
                              title={t("forms.imageLightMode")}
                              labelIcon="PhotoIcon"
                              name="imageLightMode"
                              type="image"
                              maxWidth={imgSizes.icon.width}
                              maxHeight={imgSizes.icon.height}
                              style={{
                                padding: "0.5rem",
                              }}
                            />
                            <div>
                              <FormControl
                                title={t("forms.imageDarkMode")}
                                labelIcon="PhotoIcon"
                                name="imageDarkMode"
                                type="image"
                                maxWidth={imgSizes.icon.width}
                                maxHeight={imgSizes.icon.height}
                                style={{
                                  backgroundColor: values.mainColor,
                                  borderRadius: "0.5rem",
                                  padding: "0.5rem",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col w-full md:w-auto md:flex-[2]">
                          <FormControl
                            name="name"
                            titleKey={t("forms.name")}
                            required
                            containerStyle="flex w-full mb-4"
                            className="text-input"
                          />
                          <FormControl
                            type="select"
                            name="mainColor"
                            titleKey={t("forms.color")}
                            options={colorOptions}
                          />
                          <FormControl
                            name="rank"
                            titleKey={t("forms.rank")}
                            containerStyle="flex w-full mt-2"
                            className="text-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/thread-types")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}
export default ThreadTypeForm;
