import { IRadioBlockItem } from "common/components/RadioBlockItem/RadioBlockItem";
import { IconNames } from "components/Icon/Icon";

const pubTypesData: IRadioBlockItem[] = [
  {
    id: 1,
    icon: "BellAlertIcon",
    title: "Alerte",
    subtitle: "Alertez les riverains sur une nuisance",
    color: "#f97316", // orange-500
  },
  {
    id: 2,
    icon: "CalendarDaysIcon",
    title: "Evènement",
    subtitle: "Créer un évènement",
    color: "#742b7f", // secondary
  },
  {
    id: 3,
    icon: "InformationCircleIcon",
    title: "Info",
    subtitle: "Créer une note d'information",
    color: "#3b82f6", // blue-500
  },
];

export default pubTypesData;

export const getDefaultPostTypeProps = (value: string) => {
  if (value === "alert") {
    return {
      icon: "BellAlertIcon" as IconNames,
      color: "#f97316", // orange-500
    };
  }

  if (value === "event") {
    return {
      icon: "CalendarDaysIcon" as IconNames,
      color: "#742b7f", // secondary
    };
  }

  return {
    icon: "InformationCircleIcon" as IconNames,
    color: "#3b82f6", // blue-500
  };
};
