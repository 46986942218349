import dayjs from "dayjs";
import { Link } from "react-router-dom";
import "dayjs/locale/fr";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/20/solid";

import DatesRange from "common/components/DatesRange/DatesRange";
import Button from "common/components/Button/Button";
import truncateString from "common/utils/truncateString";
import { Activity } from "types/Activity";
import { useTranslation } from "react-i18next";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import useAuth from "common/hooks/useAuth";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import checkRights from "common/utils/checkRights";
import Icon from "components/Icon/Icon";

const containerStyle =
  "responsive-flex px-4 py-4 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all";

function ActivityItem({ item, onNavigate, onDelete }: any) {
  const { user: currentUser } = useAuth();

  const canEditItem = checkRights(
    currentUser,
    "canManageActivity",
    [item.site?.id],
    ["isAdmin"]
  );
  const {
    id,
    name,
    description,
    // documents,
    attendees,
    contactGroups,
    date,
    // site,
  } = item as Activity;

  const { t } = useTranslation();

  const detailLink = `/activities/${id}`;

  return (
    <div className={`${containerStyle} hover:border-blue-500`}>
      <Link to={detailLink} className="w-full responsive-flex">
        <div className="flex mb-4 lg:mb-0 w-full">
          {/* very left */}
          <div className="lg:border-r mr-2 pr-3">
            <div className="responsive-flex relative w-16 h-16 min-h-16 top-1">
              <div className="rounded-lg overflow-hidden">
                <ImageComponent
                  image={
                    item.site?.logo || item.site?.mainImage
                      ? ImageFromStrapiMedia(
                          item.site.logo ?? item.site.mainImage
                        )?.uri
                      : undefined
                  }
                />
              </div>
            </div>
          </div>

          {/* main */}
          <div className="flex flex-col flex-1 justify-start lg:pl-2 lg:pr-6">
            <div className="responsive-flex-lg w-full">
              <div className="flex flex-col flex-1">
                <h4 className="text-xl font-bold text-primary leading-6">
                  {name}
                </h4>
                <div className="text-sm flex">
                  <div className="flex items-center mr-4">
                    <Icon name="WorkSiteIcon" className="w-4 h-4 mr-1" />
                    <span className="font-bold">{item.site.name}</span>
                  </div>
                </div>
              </div>

              {Boolean(date) && (
                <div className="responsive-flex gap-4 items-start mt-2 lg:mt-0">
                  <div className="flex justify-end items-end">
                    <DatesRange startDate={dayjs(date).toDate()} hideHours />
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col gap-2 pt-2 mt-2 border-t border-slate-200">
              <div className="text-sm text-gray-500">
                {truncateString(description, 150)}
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* very right */}
      <div className="flex flex-col justify-between items-end text-xs lg:border-l lg:pl-4 lg:min-w-[30em] border-slate-150 gap-2">
        {(contactGroups?.length > 0 || attendees?.length > 0) && (
          <div className="flex flex-col w-full">
            {contactGroups?.length > 0 && (
              <div className="flex items-center gap-1 text-sm">
                <UserGroupIcon className="w-4 h-4 text-slate-400" />
                {t("forms.contactGroupsLength", {
                  count: contactGroups?.length,
                })}
              </div>
            )}

            {attendees?.length > 0 && (
              <div className="flex items-center gap-1 text-sm">
                <ChatBubbleOvalLeftIcon className="w-4 h-4 text-slate-400" />
                {t("forms.attendeesLength", { count: attendees?.length })}
              </div>
            )}
          </div>
        )}

        <div className="flex w-full gap-1 justify-end">
          <Button
            type="error-line"
            icon="TrashIcon"
            compact
            onClick={() => onDelete(item.id)}
            disabled={!canEditItem}
            confirmMessage={t("actions.confirmDelete")}
          />

          <Button
            title={t("actions.edit")}
            type="warning-line"
            icon="PencilSquareIcon"
            onClick={() => onNavigate(item.id)}
            disabled={!canEditItem}
          />
        </div>
      </div>
    </div>
  );
}

export default ActivityItem;
