import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  EnvelopeIcon,
  PhoneIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";

import Button from "common/components/Button/Button";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import truncateString from "common/utils/truncateString";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import useAuth from "common/hooks/useAuth";
import { SiteUser } from "types";
import getWording from "common/utils/wording";

// import checkRights from "common/utils/checkRights";

function ContactItem({ item, onDelete, onNavigate }: any) {
  const { avatar, name, email, quality, phoneNumber } = item as any;

  const { user: currentUser } = useAuth();

  /* les contacts ne sont pas associés à des chantiers pour le moment */
  const canEditItem = currentUser?.isAdmin || currentUser?.isSuperadmin;
  /* const canEditItem = checkRights(
    currentUser,
    "canManageContact",
    item ? [item?.site?.id] : undefined,
    ["isAdmin"]
  ); */

  const editLink = `/contacts/edit/${item.id}`;

  const { t } = useTranslation();

  return (
    <div className="responsive-flex-lg px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem
            ? "pointer-events-none responsive-flex-lg flex-1 lg:items-center"
            : "responsive-flex-lg flex-1 lg:items-center"
        }
      >
        <div className="flex w-full gap-4">
          {/* very left */}
          <div className="flex relative shrink-0 mb-2 lg:mb-0 w-16 max-w-[4rem] h-16">
            <div className="absolute -top-2 -left-2">
              <WorkSiteIcon iconStyle="w-4 h-4 text-white" />
            </div>
            <div className="rounded-lg w-full h-full overflow-hidden  lg:w-auto">
              <ImageComponent
                image={avatar ? ImageFromStrapiMedia(avatar).uri : undefined}
              />
            </div>
          </div>

          {/* middle */}
          <div className="flex flex-col xl:flex-row w-full">
            <div className="flex flex-col flex-1">
              <h4 className="text-xl font-bold text-primary leading-4">
                {name}
              </h4>
              {quality && (
                <div className="text-sm text-slate-500 mt-2">
                  {truncateString(quality, 150)}
                </div>
              )}
            </div>

            {item?.siteUsers?.length ? (
              <div className="flex flex-col flex-1 gap-2 py-3 xl:py-0 xl:px-6">
                <h4 className="text-xl font-bold text-primary leading-4">
                  {t("titlePages.sites", {
                    wording: getWording(true, true),
                  })}
                </h4>
                {item?.siteUsers?.map((su: SiteUser) => (
                  <div className="flex">
                    <div className="rounded-lg overflow-hidden w-8 h-8 mr-3 mt-1">
                      <ImageComponent
                        image={
                          su.site?.logo || su.site?.mainImage
                            ? ImageFromStrapiMedia(
                                su.site.logo ?? su.site.mainImage
                              )?.uri
                            : undefined
                        }
                      />
                    </div>
                    <div className="flex flex-col flex-1">
                      <span className="font-bold text-sm">
                        {su?.site?.name}
                      </span>
                      <div className="flex items-center gap-1 text-sm">
                        <WalletIcon className="w-4 h-4" />
                        <span>{su.jobPosition?.name}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex-1 py-1 xl:py-0 xl:px-6" />
            )}

            <div className="flex flex-col flex-1 justify-start">
              {email && (
                <div className="flex items-center gap-1 text-sm">
                  <EnvelopeIcon className="w-3 h-3" />
                  <span className="font-bold">{email}</span>
                </div>
              )}

              {phoneNumber && (
                <div className="flex items-center gap-1 text-sm">
                  <PhoneIcon className="w-3 h-3" />
                  <span className="font-bold">{phoneNumber}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
      <div className="flex flex-col justify-end items-end text-xs lg:border-l pl-4 lg:min-w-[22em] border-slate-150 gap-2">
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />

              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactItem;
