import Icon from "components/Icon/Icon";

interface ISiteTypeIcon {
  labelType?: string;
  iconStyle?: string;
}

const containerStyle =
  "flex w-full h-full items-center justify-center p-2 rounded-lg shadow-lg";

function SiteTypeIcon({ labelType, iconStyle }: ISiteTypeIcon) {
  if (labelType === "worksite") {
    // chantier
    return (
      <div className={`${containerStyle} bg-orange-500 shadow-orange-300`}>
        <Icon name="WorkSiteIcon" className={iconStyle} />
      </div>
    );
  }

  if (labelType === "usine") {
    // usine
    return (
      <div className={`${containerStyle} bg-orange-500 shadow-orange-300`}>
        <Icon name="WorkSiteIcon" className={iconStyle} />
      </div>
    );
  }

  if (labelType === "project") {
    // projet
    return (
      <div className={`${containerStyle} bg-secondary shadow-secondary`}>
        <Icon name="UserGroupIcon" className={iconStyle} />
      </div>
    );
  }

  if (labelType === "theme") {
    // theme
    return (
      <div className={`${containerStyle} bg-secondary shadow-secondary`}>
        <Icon name="InformationCircleIcon" className={iconStyle} />
      </div>
    );
  }

  if (labelType === "site") {
    // site
    return (
      <div className={`${containerStyle} bg-blue-500 shadow-blue-300`}>
        <Icon name="InformationCircleIcon" className={iconStyle} />
      </div>
    );
  }

  if (labelType === "zone" || labelType === "worksiteWithZones") {
    // site
    return (
      <div className={`${containerStyle} bg-orange-500 shadow-orange-300`}>
        <Icon name="WorkSiteIcon" className={iconStyle} />
      </div>
    );
  }

  if (labelType === "worksiteWithZones") {
    // site
    return (
      <div className={`${containerStyle} bg-orange-500 shadow-orange-300`}>
        <Icon name="WorkSiteIcon" className={iconStyle} />
      </div>
    );
  }

  return null;
}

SiteTypeIcon.propTypes = {};

export default SiteTypeIcon;
