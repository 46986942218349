import { useCallback, useContext, useEffect } from "react";
import { remove } from "lodash";

import PageLoading from "common/components/PageLoading/PageLoading";
import useRouter from "common/hooks/use-router";
import useAuth from "common/hooks/useAuth";
import useItem from "common/hooks/useItem";

import AlertsContext from "common/providers/alerts";
import PostForm from "./PostForm";

function Post() {
  const router = useRouter();
  const id = router.query.id as string;
  const isDuplicated = router.pathname.includes("duplicate");

  const {
    user: { id: userId },
  }: any = useAuth();
  const { setAlert } = useContext(AlertsContext);

  const {
    item,
    updateItem,
    saveItem,
    error,
    isFetching,
    fetchItem,
    isUpdateFetching,
  }: any = useItem("posts", id, {
    populate: [
      "type",
      "site",
      "author",
      "mainImage",
      "documents",
      "contactGroups",
      "privateThread",
      "privateThread.messages",
      "privateThread.messages.author",
      "privateThread.messages.documents",
      "qAndAs",
      "documents",
      "alertType",
      "plannedNotifications",
    ],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (id) {
      router.navigate(`/posts/${id}`);
      return;
    }
    if (!error) {
      router.navigate("/posts");
    }
  }, [router, error, id]);

  const publishPost = useCallback(
    async (dataPost: any) => {
      const post = {
        ...dataPost,
        contactGroups: remove(dataPost.contactGroups, (e) => e !== "public"),
        author: userId,
      };

      if (isDuplicated) {
        delete post.id;
        if (post.threads) {
          delete post.threads;
        }
        if (post.privateThread) {
          delete post.privateThread;
        }
        if (post.qAndAs) {
          post.qAndAs = post.qAndAs.map((q: any) => {
            const tmpQ = { ...q };
            if (tmpQ.id) {
              delete tmpQ.id;
            }
            return tmpQ;
          });
        }
        if (post.plannedNotifications) {
          post.plannedNotifications = post.plannedNotifications.map(
            (p: any) => {
              const tmpP = { ...p };
              if (tmpP.id) {
                delete tmpP.id;
              }
              tmpP.notificationSent = false;
              return tmpP;
            }
          );
        }
        if (post.upVotes) {
          delete post.upVotes;
        }
        post.numberOfPushSent = 0;
        post.numberOfEmailsSent = 0;
        post.numberOfSMSSent = 0;
        post.thanks = 0;
      }

      try {
        if (post.id) {
          await updateItem(post.id, post);
        } else {
          await saveItem(
            {
              data: {
                ...post,
                status: "pending_moderation",
              },
            },
            false
          );
        }
      } catch (e: any) {
        return e;
      }
      return true;
    },
    [isDuplicated, saveItem, updateItem, userId]
  );

  const onSubmitKeepPage = useCallback(
    async (dataPost: any) => {
      const res = await publishPost(dataPost);

      return res;
    },
    [publishPost]
  );

  const onSubmit = useCallback(
    async (dataPost: any) => {
      const res = await publishPost(dataPost);

      back();
      return res;
    },
    [publishPost, back]
  );

  if (isFetching) {
    return <PageLoading />;
  }

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-700 text-base">
      <PostForm
        post={item}
        fetchPost={fetchItem}
        updatePost={updateItem}
        isUpdateFetching={isUpdateFetching}
        onSubmit={onSubmit}
        onSubmitKeepPage={onSubmitKeepPage}
      />
    </div>
  );
}

export default Post;
