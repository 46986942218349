import { HomeIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import {
  IMenuChild,
  IMenuGroup,
  superadminRootMenu,
  superadminFilteredMenu,
  proMenu,
} from "conf/menu";
import Header from "common/components/Header/Header";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import getWording from "common/utils/wording";
import SectionTitle from "../../common/components/SectionTitle/SectionTitle";
import Button from "../../common/components/Button/Button";
import useRouter from "../../common/hooks/use-router";
import checkRights from "../../common/utils/checkRights";

interface IDashboardItem {
  item: IMenuChild;
}

function DashboardItem({ item }: IDashboardItem) {
  const {
    label,
    id,
    Icon,
    disableBoard,
    add,
    addRightType,
    addRightKey,
    addRightKeyJoker,
    useWordingPlural,
  } = item;
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { user: currentUser } = useAuth();

  const onListClick = () => navigate(id);
  const onAddClick = () => navigate(`${id}/add`);

  const user = currentUser as any;

  const canAdd = () => {
    if (addRightKey) {
      if (addRightType === "role") {
        return !!user[addRightKey];
      }
      if (addRightType === "jobPosition") {
        return checkRights(
          user,
          addRightKey,
          user?.mySitesId,
          addRightKeyJoker
        );
      }
      return true;
    }

    return true;
  };

  if (disableBoard) {
    return null;
  }

  return (
    <div className="flex items-center justify-between border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
      <div className="flex gap-2 items-center">
        <Icon className="w-4 h-4 text-secondary" />
        {useWordingPlural
          ? t(`titlePages.${label}`, {
              wording: getWording(true, true),
            })
          : t(`titlePages.${label}`)}
      </div>
      <div className="flex gap-1">
        <Button
          type="primary-line"
          compact
          icon="MdList"
          onClick={onListClick}
        />
        {add && (
          <Button
            type="valid-line"
            compact
            icon="PlusCircleIcon"
            onClick={onAddClick}
            disabled={!canAdd()}
            className={!canAdd() ? "cursor-not-allowed" : ""}
          />
        )}
      </div>
    </div>
  );
}

interface IDashboardGroup {
  group: IMenuGroup;
}

function DashboardGroup({ group }: IDashboardGroup) {
  const { label, children, icon } = group;
  const { t } = useTranslation();

  const childrenList = children.map((child) => (
    <DashboardItem item={child} key={child.label} />
  ));

  return (
    <div className="flex flex-col gap-1 white-box">
      <SectionTitle
        title={t(`titleDividers.${label}`)}
        icon={icon}
        className="text-lg"
      />
      {childrenList}
    </div>
  );
}

function Home() {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const activeMenu =
    currentUser?.isAdmin || currentUser?.isSuperadmin
      ? organization?.id
        ? superadminFilteredMenu
        : superadminRootMenu
      : proMenu;

  const list = Object.values(activeMenu).map((group) => (
    <DashboardGroup group={group} key={group.label} />
  ));

  return (
    <div className="py-6">
      <div className="mx-auto text-sm max-w-full max-main-box px-4 mb-4">
        <Header
          title={t("titlePages.board")}
          subtitle={t("titleLegends.board")}
          Icon={HomeIcon}
        />
        <div className="grid-1or2 gap-2 mt-4">{list}</div>
      </div>
    </div>
  );
}

export default Home;
