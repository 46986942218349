import {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import { Activity } from "types/Activity";
import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";

import useList from "common/hooks/useList";
import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import getDefaultFilters from "common/utils/getDefaultFilters";
import checkRights from "common/utils/checkRights";
import getWording from "common/utils/wording";
import AlertsContext from "common/providers/alerts";
import EmptyBlockWithCTA from "common/components/EmptyBlockWithCTA/EmptyBlockWithCTA";
import PostItem from "./ActivityItem";

function EmptyActivityDataWithCTA() {
  const { t } = useTranslation();
  return (
    <EmptyBlockWithCTA
      title={t("emptyDatasCta.noSitesYet", { wording: getWording() })}
      content={t("emptyDatasCta.forActivities", {
        wording: getWording(true),
      })}
      actionTitle={t("emptyDatasCta.addSite", { wording: getWording() })}
      actionUrl="/sites/add"
    />
  );
}

function Activities() {
  const { t } = useTranslation();

  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const canManageActivity = checkRights(
    currentUser,
    "canManageActivity",
    currentUser?.mySitesId,
    ["isAdmin"]
  );

  const defaultFilters = useMemo(
    () =>
      getDefaultFilters({
        currentUser,
        sitesIdsPath: "site][id",
      }),
    [currentUser]
  );

  const {
    fetchItems,
    items,
    isFetching,
    isItemsValid,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<Activity>("activities", {
    defaultFilters: {
      ...defaultFilters,
      site: {
        active: true,
        disabled: false,
      },
    },
    defaultSort: "name:ASC",
    populate: [
      "documents",
      "site",
      "site.mainImage",
      "site.logo",
      "contactGroups",
      "attendees",
    ],
    cachePrefix: `Activities_${organization?.id || "all"}`,
  });

  const { items: siteItems } = useList("sites", {
    defaultFilters: organization?.id
      ? {
          organization: organization.id,
        }
      : {},
    cachePrefix: `Sites_inActivities_${organization?.id || "all"}`,
  });

  const { setAlert } = useContext(AlertsContext);
  const { navigate } = useRouter();
  const { removeItem } = useItem<Activity>("activities", "");

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  useEffect(() => {
    setFilter("site][organization", organization?.id || false);
  }, [organization, setFilter]);

  const handleNavigate = (id: any) => {
    navigate(`/activities/edit/${id}`);
  };

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("forms.name"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "w-1/2",
      },
      {
        Header: t("forms.site", {
          wording: getWording(false, true),
        }),
        filterName: "site][id",
        columnClasses: "w-1/4",
        filterComponent: (props: any) => (
          <SiteSelect
            isFilter
            allowReadOnlyOptions
            Icon={WorkSiteIcon}
            {...props}
          />
        ),
      },
    ],
    [t]
  );

  const isBlank = !isItemsValid && !isFetching;

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.activities")}
        subtitle={t("titleLegends.activities")}
        btnTitle={t("newItem.activity")}
        btnSubtitle={t("newItem.activityLegend")}
        Icon={CalendarDaysIcon}
        addLink="/activities/add"
        addLinkDisabled={!canManageActivity || Boolean(!siteItems?.length)}
        isBeforeFilters
      />
      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={PostItem}
        RenderEmptyBlock={
          !siteItems?.length && !isBlank ? EmptyActivityDataWithCTA : undefined
        }
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Activities;
