import { Suspense } from "react";
import { AuthProvider } from "common/providers/AuthProvider";
import { LoadingProvider } from "common/providers/LoadingProvider";
import { AlertsProvider } from "common/providers/alerts";
import { OrganizationProvider } from "common/providers/OrganizationProvider";
// import { NotificationProvider } from 'common/providers/NotificationProvider';

type Props = {
  children: JSX.Element;
};

function AppProvider({ children }: Props) {
  return (
    <Suspense>
      <LoadingProvider>
        <AlertsProvider>
          <AuthProvider>
            <OrganizationProvider>{children} </OrganizationProvider>
          </AuthProvider>
        </AlertsProvider>
      </LoadingProvider>
    </Suspense>
  );
}

export default AppProvider;
