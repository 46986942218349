import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import { useMemo, useState, useEffect, useContext } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import Button from "common/components/Button/Button";
import EditHeader from "common/components/EditHeader/EditHeader";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import useRouter from "common/hooks/use-router";
import { Contact } from "types";
import useList from "common/hooks/useList";
import FormAddButton from "common/components/FormAddButton.tsx/FormAddButton";
import Modal from "common/components/Modal/Modal";
import ContactForm from "pages/Contacts/ContactForm";
import useItem from "common/hooks/useItem";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import truncateString from "common/utils/truncateString";
import getWording from "common/utils/wording";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import AlertsContext from "common/providers/alerts";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import { isTrue } from "common/utils/getEnv";
import SiteSelect from "../../components/select/SiteSelect/SiteSelect";
import getDefaultFilters from "../../common/utils/getDefaultFilters";

function ContactGroupForm({ onSubmit, contactGroup }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization, refreshOrganization } = useOrganization();
  const { navigate } = useRouter();
  const [errorMessage, setErrorMessage] = useState("");
  const { setAlert } = useContext(AlertsContext);

  const {
    items: contacts,
    fetchItems: fetchContacts,
    setFilter: setFilterContact,
    error: contactsError,
  } = useList<Contact>("contacts", {
    populate: ["avatar"],
    defaultFilters: getDefaultFilters({
      currentOrganizationId: organization?.id,
      currentUser,
      // sitesIdsPath  // contacts are not linked to sites
    }),
    defaultItemsPerPage: 5000,
    cachePrefix: `ContactGroupForm_${organization?.id || "all"}`,
  });

  useEffect(() => {
    setFilterContact("organization", organization?.id || false);
  }, [organization, setFilterContact]);

  const contactsList = useMemo(
    () =>
      contacts?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
    [contacts]
  );

  const initialValues = useMemo(
    () =>
      contactGroup?.id
        ? {
            ...contactGroup,
            site: contactGroup.site?.id,
            contacts: contactGroup?.contacts?.map((c: Contact) => c.id),
          }
        : {
            site: null,
            name: "",
            type: "",
            contacts: [],
            notifyWithoutUser: !isTrue("VITE_IS_LOGIN_MANDATORY_APP"),
            targetWithoutSubscription: false,
            appliedToTheWholeOrganization: false,
            organization: organization?.id || currentUser?.organizations[0]?.id,
          },
    [contactGroup, currentUser?.organizations, organization?.id]
  );

  /* create contact */

  const [createContact, setCreateContact] = useState(false);

  const { saveItem, error: contactError }: any = useItem("contacts", null, {
    populate: ["avatar"],
  });

  useEffect(() => {
    if (contactError) {
      setAlert(contactError, "danger");
    }
  }, [contactError, setAlert]);

  useEffect(() => {
    if (contactsError) {
      setAlert(contactsError, "danger");
    }
  }, [contactsError, setAlert]);

  const removeContact = (form: any, values: any, deleteId: number) => {
    const newContacts = values.contacts.filter(
      (contactId: number) => contactId !== deleteId
    );
    form.mutators.setFieldValue({
      field: "contacts",
      value: newContacts,
    });
  };

  const onSubmitWrapper = async (values: any) => {
    if (values.appliedToTheWholeOrganization) {
      // eslint-disable-next-line no-param-reassign
      values.site = null;
    }
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    } else if (organization) {
      refreshOrganization(organization?.id);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit, values, form }) => {
        // inside form to get mutators
        const onSubmitContact = async (dataContact: any) => {
          const contact = { ...dataContact };

          try {
            const newContact = await saveItem(contact);

            form.mutators.setFieldValue({
              field: "contacts",
              value: [...values.contacts, newContact?.id],
            });
          } catch (e: any) {
            return e;
          }

          fetchContacts();

          setCreateContact(false);
          return true;
        };

        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(
                contactGroup?.id
                  ? "editItem.contactGroup"
                  : "newItem.contactGroup"
              )}
              subtitle={values.name}
              icon="UserGroupIcon"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/contact-groups")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex gap-2 mt-2">
                <div className="md:w-1/3 mb-2 md:mb-0">
                  <div className="flex flex-col gap-2 w-full">
                    <div className="white-box">
                      <div className="flex flex-col gap-2">
                        <FormControl
                          name="name"
                          titleKey={t("forms.name")}
                          required
                          containerStyle=""
                        />
                        <FormControl name="type" titleKey={t("forms.type")} />
                        {currentUser &&
                          ["isSuperadmin", "isAdmin"].find(
                            (jr: string) => (currentUser as any)[jr] === true
                          ) && (
                            <div className="flex flex-row gap-4 my-4">
                              <FormControl
                                type="custom"
                                name="appliedToTheWholeOrganization"
                              >
                                <ToggleSwitch />
                              </FormControl>
                              {t("forms.appliedToTheWholeOrganization", {
                                wording: getWording(true, false),
                              })}
                            </div>
                          )}
                        {!values.appliedToTheWholeOrganization && (
                          <FormControl
                            type="custom"
                            title={t("forms.site", {
                              wording: getWording(false, true),
                            })}
                            required
                            name="site"
                            containerStyle=""
                            isSelect
                          >
                            <SiteSelect />
                          </FormControl>
                        )}
                      </div>
                    </div>

                    {isTrue("VITE_IS_LOGIN_MANDATORY_APP") && (
                      <div className="white-box">
                        <div className="flex flex-col gap-2">
                          <SectionTitle
                            title={t("forms.contactGroupPosts")}
                            className=" text-lg normal-case mb-2"
                          />

                          <div className="flex flex-row gap-4">
                            <FormControl
                              type="custom"
                              name="targetWithoutSubscription"
                            >
                              <ToggleSwitch />
                            </FormControl>
                            {t("forms.targetWithoutSubscription")}
                          </div>
                          <p className="italic text-sm text-slate-400">
                            {t("forms.targetWithoutSubscriptionSubTitle", {
                              wording: getWording(true),
                            })}
                          </p>
                        </div>

                        <div className="flex flex-col gap-1 mt-4">
                          <div className="flex flex-row gap-4">
                            <FormControl type="custom" name="notifyWithoutUser">
                              <ToggleSwitch />
                            </FormControl>
                            {t("forms.notifyWithoutUser")}
                          </div>
                          <p className="italic text-sm text-slate-400">
                            {t("forms.notifyWithoutUserSubTitle", {
                              wording: getWording(true),
                            })}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="md:w-2/3">
                  <div className="flex flex-col gap-2 w-full">
                    <div className="white-box flex flex-col gap-2 justify-between items-center ">
                      <div className="flex-1 w-full">
                        <FormControl
                          title={t("forms.contacts")}
                          type="select"
                          labelIcon="WorkSiteIcon"
                          options={contactsList}
                          name="contacts"
                          required
                          multiple
                        />
                      </div>

                      <div className="flex-1 mt-2 xl:mt-4">
                        <FormAddButton
                          label={t("newItem.contact")}
                          onClick={() => setCreateContact(true)}
                        />
                      </div>
                    </div>

                    {values?.contacts.map((contactId: number) => {
                      const contact = contacts?.find(
                        (c: Contact) => c.id === contactId
                      );

                      return (
                        <div className="responsive-flex gap-4 items-center justify-start border bg-slate-50 border-slate-200 p-3 rounded-lg text-slate-700">
                          <div className="flex w-full gap-4">
                            {/* very left */}
                            <div className="flex relative shrink-0 mb-2 lg:mb-0 w-16 max-w-[4rem] h-16">
                              <div className="absolute -top-2 -left-2">
                                <WorkSiteIcon iconStyle="w-4 h-4 text-white" />
                              </div>
                              <div className="rounded-lg w-full h-full overflow-hidden  lg:w-auto">
                                <ImageComponent
                                  image={
                                    contact?.avatar
                                      ? ImageFromStrapiMedia(contact?.avatar)
                                          .uri
                                      : undefined
                                  }
                                />
                              </div>
                            </div>

                            {/* middle */}
                            <div className="flex flex-col xl:flex-row w-full">
                              <div className="flex flex-col flex-1">
                                <h4 className="text-xl font-bold text-primary leading-4">
                                  {contact?.name}
                                </h4>
                                {contact?.quality && (
                                  <div className="text-sm text-slate-500 mt-2">
                                    {truncateString(contact?.quality, 150)}
                                  </div>
                                )}
                              </div>

                              <div className="flex flex-col flex-1 justify-start">
                                {contact?.email && (
                                  <div className="flex items-center gap-1 text-sm">
                                    <EnvelopeIcon className="w-3 h-3" />
                                    <span className="font-bold">
                                      {contact?.email}
                                    </span>
                                  </div>
                                )}

                                {contact?.phoneNumber && (
                                  <div className="flex items-center gap-1 text-sm">
                                    <PhoneIcon className="w-3 h-3" />
                                    <span className="font-bold">
                                      {contact?.phoneNumber}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="w-full md:w-auto flex justify-end">
                            <Button
                              type="error-line"
                              onClick={() =>
                                removeContact(form, values, contactId)
                              }
                              icon="TrashIcon"
                              className="px-3 py-3"
                              compact
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {
                // JSON.stringify(values)
              }

              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/contact-groups")}
              />
            </form>

            {createContact && (
              <Modal
                onCloseModal={() => setCreateContact(false)}
                showCloseModalFooter={false}
                visible={createContact}
              >
                <div>
                  <ContactForm
                    onSubmit={onSubmitContact}
                    onCancel={() => setCreateContact(false)}
                    inModal
                  />
                </div>
              </Modal>
            )}
          </div>
        );
      }}
    />
  );
}
export default ContactGroupForm;
