import { orderBy, sortBy } from "lodash";
import { Thread } from "types/Thread";

const getLastComment = (threads: Thread[]) => {
  const lastThread = sortBy(threads, "createdAt")[0];
  if (!lastThread) {
    return null;
  }
  return orderBy(lastThread.messages, "createdAt", "desc")[0];
};

export default getLastComment;
