import Icon, { IconNames } from "components/Icon/Icon";
import StickyWrapper from "common/components/StickyWrapper/StickyWrapper";
import QRCode from "common/components/QRCode/QRCode";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface IDetailHeader {
  title: string;
  subtitleIcon?: IconNames;
  Status?: React.ReactElement;
  TitleIcon?: React.ReactElement;
  SubTitle?: React.ReactElement;
  HeaderInfos?: React.ReactElement;
  HeaderButtons?: React.ReactElement;
  commentsNumber?: number;
  qrCode?: string;
  backLink?: string;
  isDraft?: boolean;
}

function DetailHeader({
  title,
  subtitleIcon,
  Status,
  TitleIcon,
  SubTitle,
  HeaderInfos,
  HeaderButtons,
  commentsNumber,
  qrCode,
  backLink,
  isDraft = false,
}: IDetailHeader) {
  const { t } = useTranslation();
  const containerPadding = qrCode ? "pl-6 pr-4 py-3" : "p-6";

  return (
    <StickyWrapper isHeader disabledOnMobile>
      <div
        className={`border rounded-t-lg border-slate-200 bg-white ${containerPadding}`}
      >
        <div className="responsive-flex-lg lg:items-center lg:justify-between">
          <div className="flex gap-4 flex-1 justify-between">
            <div className="flex gap-4 items-center">
              {backLink && (
                <Link
                  to={backLink}
                  className="flex justify-center items-center w-8 h-16 duration-200 ease-in-out relative right-0 hover:right-2"
                >
                  <ArrowLeftIcon />
                </Link>
              )}
              {TitleIcon && (
                <div className="hidden lg:flex min-w-[4rem] w-16 h-16">
                  {TitleIcon}
                </div>
              )}
              <div>
                <h1 className="text-3xl font-bold text-primary">{title}</h1>
                {SubTitle && (
                  <div className="flex items-center gap-1 text-secondary">
                    {subtitleIcon && (
                      <Icon
                        name={subtitleIcon}
                        className="w-4 min-w-[1rem] h-4"
                      />
                    )}
                    {SubTitle}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="responsive-flex-lg lg:items-center gap-4">
            {commentsNumber ? (
              <div className="flex items-center gap-3">
                <div className="flex items-center gap-1">
                  <Icon
                    name="ChatBubbleLeftRightIcon"
                    className="w-4 min-w-[1rem] h-4 text-slate-400"
                  />
                  {commentsNumber}
                </div>
              </div>
            ) : null}

            <div className="self-end">{Status}</div>
            {qrCode && <QRCode text={qrCode} />}
          </div>
        </div>
      </div>

      <div
        className={`responsive-flex justify-between border rounded-b-lg border-t-0 ${
          isDraft
            ? "bg-orange-100 border-orange-200"
            : "bg-white border-slate-200"
        }  px-6 py-3 w-full`}
      >
        {isDraft && (
          <div
            className="self-center mr-4 px-2 py-1 rounded-xl bg-orange-600 text-white  flex items-center text-sm w-fit"
            title={t("forms.adminOption")}
          >
            {t("forms.draft")}
          </div>
        )}
        {HeaderInfos}
        {HeaderButtons}
      </div>
    </StickyWrapper>
  );
}

export default DetailHeader;
