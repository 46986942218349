import { ChartBarIcon } from "@heroicons/react/24/outline";
import Header from "common/components/Header/Header";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import Select from "common/components/Select/Select";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SiteStatistics from "./SiteStatistics";
import ThreadStatistics from "./ThreadStatistics";
import PostStatistics from "./PostStatistics";

export default function Stats() {
  const { t } = useTranslation();
  const [statsToDisplay, setStatsToDisplay] = useState<
    "sites" | "posts" | "threads"
  >();

  const renderStatsContent = () => {
    switch (statsToDisplay) {
      case "sites":
        return <SiteStatistics />;
      case "posts":
        return <PostStatistics />;
      case "threads":
        return <ThreadStatistics />;
      default:
        return undefined;
    }
  };

  return (
    <div className="py-6">
      <div className="mx-auto text-sm max-w-full max-main-box px-4 mb-4">
        <Header
          title={t("titlePages.stats")}
          subtitle={t("titleLegends.stats")}
          Icon={ChartBarIcon}
        />
        <div className="flex flex-row gap-8 mt-4 white-box">
          <SectionTitle
            title={t(`stats.selectDataToDisplay`)}
            className="text-lg"
          />
          <Select
            onChange={setStatsToDisplay}
            options={[
              { value: "sites", label: "Sites" },
              { value: "posts", label: "Publications" },
              { value: "threads", label: "Échanges" },
            ]}
          />
        </div>

        {renderStatsContent()}
      </div>
    </div>
  );
}
