import React, { useState } from "react";
// import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import { GoogleMap, MarkerF as Marker } from "@react-google-maps/api";
import { BsPinMap } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Modal from "common/components/Modal/Modal";
import FormInput from "common/components/FormInput/FormInput";
import mapStyle from "./mapStyle";
import Button from "../Button/Button";

export const defaultCenter = {
  lat: 43.6043,
  lng: 1.4437,
};

function MapField({
  // initialCenter = defaultCenter,
  mapHeight = "500px",
  onChange,
  position = defaultCenter,
  positionTemp = defaultCenter,
  editable = false,
  mapPrefix,
  noMargin = false,
  form,
}: any) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const validPosition = Boolean(position?.lat && position?.lng);
  const validPositionTemp = Boolean(positionTemp?.lat && positionTemp?.lng);

  const markerIcon = {
    url: `/images/map-marker.png`,
    scaledSize: { equals: () => false, width: 35, height: 45.5 },
  };

  return (
    <>
      {/* static panel */}
      <div
        className={`relative flex flex-col w-full ${
          noMargin ? "my-0" : "my-4"
        }`}
      >
        <div className="flex text-xs mb-3 gap-1 field-title">
          <BsPinMap className="w-3 h-3" />
          {t("forms.map")}
        </div>
        <div className="flex-1 max-w-full rounded-lg max-h-[200px] overflow-hidden">
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: 200 }}
            center={validPosition ? position : defaultCenter}
            zoom={14}
            options={
              {
                styles: mapStyle as unknown as google.maps.MapTypeStyle,
                streetViewControl: false,
                zoomControl: false,
                mapTypeControl: false,
              } as unknown as google.maps.MapOptions
            }
          >
            <Marker
              key={`${mapPrefix}_readonly`}
              position={validPosition ? position : defaultCenter}
              icon={markerIcon}
            />
          </GoogleMap>
        </div>

        {editable && (
          <div className="absolute bottom-3 right-3">
            <Button
              title={t("forms.modifyPosition")}
              type="primary"
              icon="BsPinMap"
              onClick={() => setIsEditing(!isEditing)}
            />
          </div>
        )}
      </div>

      {/* edit panel */}
      {editable && (validPosition || validPositionTemp) && (
        <Modal
          onConfirmModal={() => {
            // set new position
            onChange(positionTemp);
            setIsEditing(false);
          }}
          onCloseModal={() => {
            // reset older position
            form.mutators.setFieldValue({
              field: "geojsonTemp",
              value: position,
            });
            setIsEditing(false);
          }}
          visible={isEditing}
        >
          <div>
            {form && (
              <div className="mb-4">
                <FormInput
                  name="map-helper"
                  type="geolocation"
                  onChange={form.mutators.setHelpPosition}
                  // label={t("common.address")}
                />
              </div>
            )}
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: mapHeight }}
              center={validPositionTemp ? positionTemp : defaultCenter}
              zoom={14}
              options={
                {
                  styles: mapStyle as unknown as google.maps.MapTypeStyle,
                } as unknown as google.maps.MapOptions
              }
            >
              <Marker
                key={`${mapPrefix}_edition`}
                position={validPositionTemp ? positionTemp : defaultCenter}
                draggable
                onDragEnd={(event: any) => {
                  form.mutators.setFieldValue({
                    field: "geojsonTemp",
                    value: {
                      lat: event.latLng.lat(),
                      lng: event.latLng.lng(),
                    },
                  });
                }}
                icon={markerIcon}
              />
            </GoogleMap>
          </div>
        </Modal>
      )}
    </>
  );
}

export default MapField;

/** ******************************* TODO PERIMETER ************************ */

/* drawPolyline && perimeterPositions?.length > 1 && (
          <Polyline
            path={googlePerimeterPositions}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={4}
          />
        ) */
