import React from "react";

function PollutionIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="397"
      version="1"
      x="0px"
      y="0px"
      viewBox="0 0 400 397"
      fill="currentColor"
      {...props}
    >
      <g>
        <path
          d="M311.4,17.7c1.7,1,3.3,2,5.1,3c11.6,7.4,17.1,20.8,13.9,34.8c-7.3,32.2-14.7,64.3-22.1,96.5c-8.5,37-17,74.1-25.4,111.1
		c-6.4,28.4-21.5,50.7-45.8,66.8c-1.6,1-2.9,3.1-3.3,5c-2.6,10.3-4.8,20.6-7.2,31.1c-2.9,12.9-10.7,19.3-23.8,20.2
		c-0.3,0-0.7,0.3-1,0.4c-33.8-7.7-67.7-15.5-101.4-23.2c-2.9-2.2-6.1-4-8.5-6.5c-5.9-6.3-7.4-14-5.5-22.3
		c2.4-10.6,4.9-21.1,7.2-31.8c0.4-1.9,0.1-4.3-0.8-5.8c-14.4-24.6-18.8-50.8-12.4-78.6c5.8-25.2,11.5-50.4,17.3-75.7
		c1.8-7.7,3.8-15.2,5.2-23c4.6-25.1,34.8-35.4,53.7-17.7c0.2,0.3,0.6,0.4,1.2,0.8c6.9-9.5,16-14.9,27.9-14.5
		c11.9,0.4,20.7,6.2,27.1,16.4c13.8-8.3,27.1-7.8,40.5,2.6c0.4-1.7,0.7-2.9,1-4.2c4.5-19.7,9-39.5,13.5-59.2
		c4.2-18.4,14.8-27.2,33.6-27.8c0.2-0.1,0.5-0.2,0.7-0.3C305.2,16.3,308.3,17,311.4,17.7z M109.1,164.3
		c-4.6,20.1-9.2,39.4-13.5,58.9c-5.9,27.2-0.3,51.9,16.7,73.8c39.2,50.5,118.5,42.5,147.3-14.7c7-14,9.2-29.3,12.8-44.2
		c0.2-0.9-0.3-2.2-0.8-3.1c-9.4-15.1-18.6-30.2-28.4-45c-4.6-6.9-11.5-10.5-20.1-10.2c-18.3,0.6-29.2,20.5-19.8,36.2
		c7.1,11.9,14.6,23.5,21.9,35.4c2.9,4.8,2,9.7-2.2,12.1c-3.9,2.4-8.5,0.9-11.5-3.6c-7.3-11.6-14.5-23.3-21.8-34.9
		c-4.7-7.4-6.6-15.6-5.9-24.3c0.1-0.9,0.1-1.8,0.2-3c-9.9,2.8-19,1.8-27.3-3.5c-8.5-5.4-13-13.4-14.7-23.2
		C129.8,174.6,119.1,172.4,109.1,164.3z M277.4,214c0.5-1.7,0.8-2.7,1-3.6c4.2-18,8.4-36,12.7-54c7.8-34,15.5-67.9,23.3-102
		c0.5-2.1,1-4.4,0.9-6.7c-0.1-6.4-3-11.2-8.8-14.2c-9.7-4.9-20.3,0.6-23,11.7c-9.4,40.9-18.6,81.9-28.1,122.7
		c-1.4,5.9-1.3,10.6,2.6,15.8c6.2,8.4,11.3,17.7,16.9,26.5C275.6,211.3,276.3,212.3,277.4,214z M107.3,315.8
		c-1.6,7.1-3.1,13.7-4.7,20.4c-2,8.6-0.2,11.5,8.3,13.4c29.1,6.7,58.3,13.3,87.4,20c1.5,0.3,2.9,0.7,4.5,0.7c3.8,0,6.8-1.7,7.7-5.3
		c2.1-7.9,3.8-16,5.6-24.2C175.1,353.1,139.1,345,107.3,315.8z M194.6,147.2c1.8-7.8,3.7-15.6,5.2-23.4c0.5-2.2,0.7-4.8,0.3-7
		c-1.3-7.3-7.7-12.4-15.2-12.6c-7.5-0.2-14.1,4.7-15.9,12.2c-3.6,15.3-7.1,30.7-10.5,46c-2.2,9.5,2.8,17.9,11.6,20
		c8.9,2.1,16.9-3.1,19.2-12.6C191.3,162.3,192.9,154.7,194.6,147.2z M239.1,166.8c2.8-12.2,5.4-23.7,8-35.2c0.3-1.3,0.1-2.7-0.2-4
		c-1.4-7.2-7.7-12.4-15.1-12.7c-7.4-0.3-14.1,4.5-15.8,11.8c-2.1,8.3-3.9,16.7-5.8,25.1c-1.2,5.1-2.3,10.1-3.6,15.8
		C217.6,162.8,228.2,162.9,239.1,166.8z M147.9,136.3c0.7-2.9,1.4-5.8,2-8.8c1.5-8.5-3.5-16.1-11.8-18c-8.2-2-16.6,2.7-18.9,10.7
		c-1.7,6-3.1,12.1-4.2,18.2c-1.4,8.1,4.4,16.2,12.3,17.9c8.2,1.8,16.2-3.1,18.5-11.3C146.8,142.2,147.2,139.2,147.9,136.3z"
        />
      </g>
    </svg>
  );
}

export default PollutionIcon;
