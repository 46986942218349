import { useCallback, useMemo, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  BellAlertIcon,
  ChatBubbleLeftRightIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  ClipboardDocumentListIcon,
  ListBulletIcon,
  SparklesIcon,
} from "@heroicons/react/20/solid";
import { BsClipboardX } from "react-icons/bs";

import { Thread } from "types/Thread";
import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import useList from "common/hooks/useList";
import useAuth from "common/hooks/useAuth";
import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import TabBlockItem from "common/components/TabBlockItem/TabBlockItem";
import getDefaultFilters from "common/utils/getDefaultFilters";
import ThreadTypeSelect from "components/select/ThreadTypeSelect/ThreadTypeSelect";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import useOrganization from "common/hooks/useOrganization";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import getWording from "common/utils/wording";
import AlertsContext from "common/providers/alerts";
import PostSelect from "components/select/PostSelect/PostSelect";
import EmptyBlockWithCTA from "common/components/EmptyBlockWithCTA/EmptyBlockWithCTA";
import UserSelect from "components/select/UserSelect/UserSelect";
import TagSelect from "components/select/TagSelect/TagSelect";
import { TagIcon } from "@heroicons/react/24/solid";
import ThreadItem from "./ThreadItem";

function EmptyThreadDataWithCTA() {
  const { t } = useTranslation();
  return (
    <EmptyBlockWithCTA
      title={t("emptyDatasCta.noThreadsYet")}
      content={t("emptyDatasCta.forThreads", { wording: getWording() })}
      actionTitle={t("emptyDatasCta.addSiteSoft", {
        wording: getWording(),
      })}
      actionUrl="/sites/add"
    />
  );
}

function Threads() {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();
  const { setAlert } = useContext(AlertsContext);

  const defaultFilters = getDefaultFilters({
    currentUser,
    sitesIdsPath: "site][id",
  });

  const {
    fetchItems,
    items,
    isFetching,
    isItemsValid,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error: errorList1,
  } = useList<Thread>("threads/find-including-site-children", {
    defaultFilters: {
      ...defaultFilters,
      unkonwnSite: true,
    },
    populate: [
      "site",
      "site.mainImage",
      "site.logo",
      "type",
      "type.imageLightMode",
      "type.imageDarkMode",
      "post",
      "messages",
      "messages.author",
      "messages.author.role",
      "messages.author.avatar",
      "messages.privateThreadIntern.messages",
      "messages.privateThreadIntern.messages.author",
      "messages.privateThreadIntern.messages.author.role",
      "messages.privateThreadIntern.messages.author.avatar",
      "messages.documents",
      "messages.privateThreadIntern.messages.documents",
      "assignedUsers",
      "tags",
      "userType",
    ],
    defaultSorts: [
      "updatedAt:DESC",
      // "messages.privateThreadIntern?.messages?.updatedAt:DESC", PB: les messages internes existent pas forcément
    ],
    cachePrefix: `Threads_threads_${organization?.id || "all"}`,
  });

  const { items: siteItems } = useList("sites", {
    defaultFilters: organization?.id
      ? {
          organization: organization.id,
        }
      : {},
    cachePrefix: `Sites_inThreads_${organization?.id || "all"}`,
  });

  useEffect(() => {
    if (errorList1) {
      setAlert(errorList1, "danger");
    }
  }, [errorList1, setAlert]);

  const { navigate, location } = useRouter();

  const filterOnPost = location?.state?.filterOnPost;
  const filterOnSite = location?.state?.filterOnSite;

  const {
    items: dataThreads,
    setFilter: setFilterDataThreads,
    error: errorList2,
  } = useList<Thread>("threads/find-including-site-children", {
    defaultFilters: {
      ...defaultFilters,
      myThreads: !currentUser?.isAdmin,
      /* site: {
        active: true,
        disabled: false,
      }, */
    },
    populate: ["site", "post"],
    defaultItemsPerPage: 5000,
    cachePrefix: `Threads_threads2_${organization?.id || "all"}`,
  });

  useEffect(() => {
    if (errorList2) {
      setAlert(errorList2, "danger");
    }
  }, [errorList2, setAlert]);

  useEffect(() => {
    setFilter("site][organization", organization?.id || false);
    setFilterDataThreads("site][organization", organization?.id || false);

    if (filterOnSite) {
      setFilter("site][id", filterOnSite);
      setFilterDataThreads("site][id", filterOnSite);
    }

    if (filterOnPost) {
      setFilter("post][id", filterOnPost);
      setFilterDataThreads("post][id", filterOnPost);
    }
  }, [
    organization,
    setFilter,
    setFilterDataThreads,
    currentUser,
    filterOnPost,
    filterOnSite,
  ]);

  const statusList = useMemo(() => {
    return [
      {
        id: "",
        name: t("status.all"),
        count: dataThreads?.length || 0,
        icon: ListBulletIcon,
        color: "secondary",
      },
      {
        id: "waiting_answer",
        name: t("status.waiting_answer"),
        count:
          dataThreads?.filter((item: any) => item.status === "waiting_answer")
            ?.length || 0,
        icon: ClipboardDocumentListIcon,
        color: "yellow",
      },
      {
        id: "answered",
        name: t("status.answered"),
        count:
          dataThreads?.filter((item: any) => item.status === "answered")
            ?.length || 0,
        icon: SparklesIcon,
        color: "green",
      },
      {
        id: "closed",
        name: t("status.closed"),
        count:
          dataThreads?.filter((item: any) => item.status === "closed")
            ?.length || 0,
        icon: BsClipboardX,
        color: "slate",
      },
    ];
  }, [dataThreads, t]);

  const { removeItem } = useItem<Thread>("threads", "");

  const handleNavigate = (id: any) => {
    navigate(`/threads/${id}`);
  };

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, removeItem, fetchItems, setAlert]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("forms.site", {
          wording: getWording(false, true),
        }),
        filterName: "site][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <SiteSelect
            isFilter
            allowReadOnlyOptions
            Icon={WorkSiteIcon}
            alsoOnChange={() => setFilter("post][id", "")}
            {...props}
          />
        ),
      },
      {
        Header: t("titlePages.posts"),
        filterName: "post][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <PostSelect
            isFilter
            filterOnSiteId={filters["site][id"]}
            Icon={WorkSiteIcon}
            {...props}
          />
        ),
      },
      {
        Header: t("forms.type"),
        filterName: "type][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <ThreadTypeSelect isFilter Icon={BellAlertIcon} {...props} />
        ),
      },
      {
        Header: t("forms.tag"),
        filterName: "tags][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <TagSelect isFilter icon={TagIcon} {...props} />
        ),
      },
      {
        Header: t("forms.assignedUsers"),
        filterName: "assignedUsers][id",
        filterLabel: t("forms.assignedUsers"),
        filterComponent: (props: any) => (
          <UserSelect isFilter Icon={UserIcon} {...props} />
        ),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("filters.publicContent"),
        filterName: "messages][content$contains",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("forms.myThreads"),
        filterName: "myThreads",
        filterComponent: (props: any) => (
          <div className="pt-2">
            <ToggleSwitch
              rightLabel={t("forms.myThreads")}
              {...props}
              /* onChange={(value: boolean) => {
                props.onChange(value ? currentUser?.id : false);
              }}
              value={Boolean(props.value)} */
            />
          </div>
        ),
        columnClasses: "md:flex-1",
      },
    ],
    [t, filters, setFilter]
  );

  const setOverallFilter = useCallback(
    (key: string, value: string | number | boolean) => {
      setFilter(key, value);
      setFilterDataThreads(key, value);
    },
    [setFilter, setFilterDataThreads]
  );

  const isBlank = !isItemsValid && !isFetching;

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.threads")}
        // subtitle={t("titleLegends.threads")}
        Icon={ChatBubbleLeftRightIcon}
        isBeforeFilters
        filters={filters}
        setFilter={setOverallFilter}
        filterName="status$contains"
        FilterComponent={(props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={statusList}
            horizontal
            noGap
            {...props}
          />
        )}
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        backLink={filterOnPost ? "/posts" : filterOnSite ? "/sites" : undefined}
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setOverallFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={ThreadItem}
        RenderEmptyBlock={
          !dataThreads?.length && !siteItems?.length && !isBlank
            ? EmptyThreadDataWithCTA
            : undefined
        }
        /* subtilité : on peut avoir des échanges alors qu'on a pas de sites (cf futur dev), donc on affiche le panel
      "pas encore d'échange, btw pourquoi pas créer un 1er site ?" seulement si on a aucuns des deux */
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Threads;
