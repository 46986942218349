import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import "photoswipe/dist/photoswipe.css";
import { Message } from "types/Message";
import truncateString from "common/utils/truncateString";
import Avatar from "common/components/Avatar/Avatar";
import { CgAttachment } from "react-icons/cg";
import { useCallback } from "react";

interface ICommentItemSmall {
  comment: Message;
}

function CommentItemSmall({ comment }: ICommentItemSmall) {
  const { content, createdAt, author, documents } = comment;
  const { t } = useTranslation();

  const getAvatar = useCallback(
    (username: string, avatar: any) => (
      <Avatar name={username} image={avatar} />
    ),
    []
  );

  return (
    <div className="flex items-center mt-1 text-sm">
      {getAvatar(author?.name, author?.avatar)}
      <div className="flex flex-col ml-2">
        <div className="flex-1 flex">
          <span className="font-bold">
            {author?.name || t("threads.unknownUser")}
          </span>
          <div className="italic text-black/60 ml-2">
            {dayjs(createdAt).fromNow()}
          </div>
        </div>

        {(content as string)?.length && (
          <div className="my-0">{truncateString(content as string, 50)}</div>
        )}
        {documents?.length && (
          <p className="flex gap-2 items-center font-bold text-primary">
            <CgAttachment />
            {t("threads.joinedFiles", { count: documents.length })}
          </p>
        )}
      </div>
    </div>
  );
}

export default CommentItemSmall;
