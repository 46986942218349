import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Marker } from "types/Marker";

function Label({ name, logo }: any) {
  return (
    <div className="flex gap-2 items-center my-2">
      <div className="rounded-lg overflow-hidden w-8 h-8">
        <ImageComponent
          image={logo ? ImageFromStrapiMedia(logo)?.uri : undefined}
        />
      </div>

      <span>{name}</span>
    </div>
  );
}

const processOptions = (options: Marker[]) =>
  options.map(({ id, label, image }) => ({
    value: id,
    label: <Label name={label} logo={image} />,
    customOption: <Label name={label} logo={image} />,
  }));

function MarkerSelect(props: any) {
  return (
    <RemoteSelect
      url="markers"
      processOptions={processOptions}
      populate={["image"]}
      {...props}
    />
  );
}

export default MarkerSelect;
