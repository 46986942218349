import { Thread } from "types/Thread";

const getCommentsNumber = (threads: Thread[]) => {
  let count = 0;

  threads.forEach(({ messages }) => {
    count += messages?.length || 0;
  });

  return count;
};

export default getCommentsNumber;
