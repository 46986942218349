import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { ThreadType } from "types/ThreadType";

const processOptions = (options: ThreadType[]) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function ThreadTypeSelect(props: any) {
  return (
    <RemoteSelect
      url="thread-types"
      processOptions={(options) => processOptions(options)}
      {...props}
    />
  );
}

export default ThreadTypeSelect;
