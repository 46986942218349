import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";

import { SiteUser } from "types";
import { ContactGroup } from "types/ContactGroup";
import EditHeader from "common/components/EditHeader/EditHeader";
import useRouter from "common/hooks/use-router";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import imgSizes from "conf/imgSizes.json";
import { checkEmail, checkPhoneFormat } from "common/utils/fieldsValidators";

function ContactForm({ contact, onSubmit, onCancel, inModal = false }: any) {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [errorMessage, setErrorMessage] = useState("");

  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const initialValues = useMemo(
    () =>
      contact?.id
        ? {
            ...contact,
            role: contact.role?.id ?? 1, // Set 'authenticated', by default
            organization: contact.organization?.id,
            siteUsers: contact.siteUsers?.map((su: SiteUser) => su.id),
            contactGroups: contact.contactGroups?.map(
              (cg: ContactGroup) => cg.id
            ),
          }
        : {
            organization: organization?.id || currentUser?.organizations[0]?.id,
            name: "",
            email: "",
            quality: "",
            phoneNumber: "",
            address: "",
            notes: "",
            user: null,
            siteUsers: [],
            avatar: null,
            contactGroups: [],
            notifyMe: true,
          },
    [contact, currentUser, organization]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <div
          className={`mx-auto max-w-full px-4 text-slate-500 text-base ${
            !inModal && "py-4 px-4"
          }`}
        >
          {/* Header */}
          <EditHeader
            title={t(contact?.id ? "editItem.contact" : "newItem.contact")}
            subtitle={values.name}
            icon="PhoneIcon"
            inModal={inModal}
            errorMessage={errorMessage}
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={inModal ? onCancel : () => navigate("/contacts")}
              />
            }
          />

          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />

            <div className="white-box">
              <div className="responsive-flex gap-4">
                <div className="md:w-1/3">
                  <FormControl
                    name="name"
                    titleKey={t("forms.name")}
                    required
                    containerStyle="mb-4"
                  />
                </div>

                <div className="md:w-1/3 z-0">
                  {/*
              
                  <FormControl
                    type="custom"
                    name="user"
                    titleKey={t("forms.user")}
                    containerStyle="mb-4"
                  >
                    <UserSelect />
                  </FormControl>
 */}
                  <FormControl
                    name="quality"
                    titleKey={t("forms.quality")}
                    containerStyle="mb-4"
                  />
                </div>
              </div>
            </div>

            {/* image et desc */}
            <div className="responsive-flex items-stretch gap-2 mt-2">
              <div className="md:w-2/3 gap-2">
                <div className="white-box h-full">
                  <div className="responsive-flex gap-4">
                    <div className="flex flex-col w-full md:w-[180px]">
                      <FormControl
                        title={t("forms.avatar")}
                        labelIcon="PhotoIcon"
                        name="avatar"
                        type="image"
                        maxWidth={imgSizes.avatar.width}
                        maxHeight={imgSizes.avatar.height}
                      />
                    </div>

                    <div className="flex flex-col w-full">
                      <div>
                        <FormControl
                          name="notes"
                          titleKey={t("forms.notes")}
                          type="textarea"
                          inputContainerStyle="flex-1"
                          containerStyle="flex w-full mb-4"
                          className="text-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/3">
                <div className="white-box">
                  <div className="flex flex-col gap-4">
                    <SectionTitle
                      title={t("forms.coordinates")}
                      className=" text-lg normal-case"
                    />
                    <FormControl name="address" titleKey={t("forms.address")} />

                    <FormControl
                      name="email"
                      titleKey={t("forms.email")}
                      validate={(val: string) => checkEmail(val, t)}
                    />

                    <FormControl
                      name="phoneNumber"
                      titleKey={t("forms.phoneNumber")}
                      validate={(val: string) =>
                        !val ? undefined : checkPhoneFormat(val, t)
                      }
                    />

                    <div className="flex items-center gap-4 ">
                      <FormControl type="custom" name="notifyMe">
                        <ToggleSwitch />
                      </FormControl>
                      {t("forms.notifyMe")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              // JSON.stringify(values)
            }

            <HeaderButtonsEdit
              onSubmit={handleSubmit}
              onCancel={inModal ? onCancel : () => navigate("/contacts")}
            />
          </form>
        </div>
      )}
    />
  );
}
export default ContactForm;
