import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import Button from "../Button/Button";

type Props = {
  title?: string;
  content?: string;
  actionTitle?: string;
  actionUrl?: string;
  classNames?: any;
};

function EmptyBlockWithCTA({
  title,
  content,
  actionTitle,
  actionUrl,
  classNames,
}: Props) {
  return (
    <div
      className={`white-box mt-4 p-4 lg:p-8 flex flex-col justify-center items-center gap-3 ${classNames}`}
    >
      {Boolean(title) && (
        <SectionTitle
          title={title ?? ""}
          className="text-lg text-center normal-case"
        />
      )}
      {Boolean(content) && <p className="text-lg text-center">{content}</p>}

      {Boolean(actionTitle && actionUrl) && (
        <Link to={actionUrl ?? ""} className="">
          <Button title={actionTitle} type="valid-line" icon="PlusCircleIcon" />
        </Link>
      )}
    </div>
  );
}

export default EmptyBlockWithCTA;
