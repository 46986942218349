import {
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

import Button from "common/components/Button/Button";

import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import { JobPosition } from "types/JobPosition";
import { AiOutlineMonitor } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAuth from "common/hooks/useAuth";
import checkRights from "common/utils/checkRights";
import getWording from "common/utils/wording";

function JobPositionItem({ item, onDelete, onNavigate }: any) {
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();

  const canEditItem = checkRights(
    currentUser,
    "canEditItem",
    currentUser?.mySitesId,
    ["isAdmin"]
  );

  const {
    name,
    canSeeUserData,
    canAnswerMessage,
    canComment,
    canEditSite,
    canManageActivity,
    canManageContact,
    canManagePost,
    canSubmitPost,
  } = item as JobPosition;

  const editLink = `/job-positions/edit/${item.id}`;

  return (
    <div className="responsive-flex-lg px-4 py-4 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem ? "pointer-events-none flex flex-1" : "flex flex-1"
        }
      >
        <div className="flex flex-col flex-1 justify-between lg:pl-4 lg:pr-6 mb-2">
          <div className="flex mt-4 mb-2 gap-3">
            <div className="flex-1">
              <h4 className="text-xl font-bold text-primary leading-4">
                {name}
              </h4>

              <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 mt-4">
                {canEditSite && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <WorkSiteIcon className="w-8 h-8 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      <span className="text-sm my-1">
                        {t("rights.toEditSite", {
                          wording: getWording(true),
                        })}
                      </span>
                    </div>
                  </div>
                )}

                {(canSubmitPost || canManagePost) && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <CalendarDaysIcon className="w-8 h-8 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      {canSubmitPost && (
                        <span className="text-sm my-1">
                          {t("rights.toSubmitPost")}
                        </span>
                      )}
                      {canManagePost && (
                        <span className="text-sm my-1 mt-2">
                          {t("rights.toManagePost")}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {(canComment || canAnswerMessage) && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <ChatBubbleLeftRightIcon className="w-8 h-8 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      {canComment && (
                        <span className="text-sm my-1">
                          {t("rights.toComment")}
                        </span>
                      )}
                      {canAnswerMessage && (
                        <span className="text-sm my-1">
                          {t("rights.toAnswerMessage")}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {canManageActivity && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <AiOutlineMonitor className="w-8 h-8 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      <span className="text-sm my-1">
                        {t("rights.toManageActivity")}
                      </span>
                    </div>
                  </div>
                )}

                {(canManageContact || canSeeUserData) && (
                  <div className="flex items-center justify-start border bg-slate-50 border-slate-200 p-1 pl-3 rounded-lg text-slate-700">
                    <UserGroupIcon className="w-8 h-8 text-emerald-600" />
                    <div className="flex flex-col pl-4">
                      {canManageContact && (
                        <span className="text-sm my-1">
                          {t("rights.toManageContact")}
                        </span>
                      )}
                      {canSeeUserData && (
                        <span className="text-sm my-1">
                          {t("rights.canSeeUserData")}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-end items-end text-xs lg:border-l pl-4  border-slate-150 gap-2">
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />

              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobPositionItem;
