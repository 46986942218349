import {
  EnvelopeIcon,
  PhoneIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";

import Button from "common/components/Button/Button";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import { Link } from "react-router-dom";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import { SiteUser, User, Site } from "types";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import Status from "common/components/Status/Status";
import getWording from "common/utils/wording";
import TooltipWrapper from "common/components/TooltipWrapper/TooltipWrapper";

const iconStyle = "w-4 h-4 text-white";

function UserItemSite({ siteUser }: { siteUser: SiteUser }) {
  return (
    <div className="flex">
      <div className="rounded-lg overflow-hidden w-8 h-8 mr-3 mt-1">
        <ImageComponent
          image={
            siteUser.site?.logo || siteUser.site?.mainImage
              ? ImageFromStrapiMedia(
                  siteUser.site.logo ?? siteUser.site.mainImage
                )?.uri
              : undefined
          }
        />
      </div>
      <div className="flex flex-col flex-1">
        <span className="font-bold text-sm">{siteUser?.site?.name}</span>
        <div className="flex items-center gap-1 text-sm">
          <WalletIcon className="w-4 h-4" />
          <span>{siteUser?.jobPosition?.name}</span>
        </div>
      </div>
    </div>
  );
}

function UserItem({ item, onDelete, onNavigate }: any) {
  const { user: currentUser } = useAuth();

  const canEditItem = currentUser?.isAdmin || currentUser?.isSuperadmin;

  const { avatar, name: fullname, email, phoneNumber, role } = item as User;

  const editLink = `/users/edit/${item.id}`;

  const { t } = useTranslation();

  const limit = 2;

  return (
    <div className="responsive-flex-lg px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem
            ? "pointer-events-none responsive-flex-lg flex-1"
            : "responsive-flex-lg flex-1"
        }
      >
        {role?.name !== "Authenticated" && (
          <div className="flex relative w-40 h-40 lg:w-16 lg:h-16 shrink-0">
            <div className="absolute -top-2 -left-2">
              <WorkSiteIcon iconStyle={iconStyle} />
            </div>
            <div className="rounded-lg overflow-hidden w-full">
              <ImageComponent
                image={avatar ? ImageFromStrapiMedia(avatar)?.uri : undefined}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col flex-1 justify-between lg:pl-4 lg:pr-6">
          <div className="flex gap-3">
            <div className="flex-1 flex flex-col gap-2 py-3 lg:py-0">
              <h4 className="text-xl font-bold text-primary leading-4 mb-1">
                {fullname}
              </h4>

              {email && (
                <div className="flex items-center gap-1 text-sm">
                  <EnvelopeIcon className="w-3 h-3" />
                  <span className="font-bold">{email}</span>
                </div>
              )}

              {phoneNumber && (
                <div className="flex items-center gap-1 text-sm">
                  <PhoneIcon className="w-3 h-3" />
                  <span className="font-bold">{phoneNumber}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {Boolean(item?.siteUsers?.length) && (
          <div className="flex flex-col flex-1 gap-2">
            <h4 className="text-xl font-bold text-primary leading-4">
              {t("titlePages.sites", {
                wording: getWording(true, true),
              })}
            </h4>

            <div>
              <TooltipWrapper
                className="mr-1 my-1"
                content={
                  item?.siteUsers?.length > limit ? (
                    <div className="flex flex-col gap-2">
                      {item?.siteUsers?.map((su: SiteUser) => (
                        <UserItemSite siteUser={su} />
                      ))}
                    </div>
                  ) : null
                }
              >
                <div>
                  {item?.siteUsers?.map((su: SiteUser, index: number) =>
                    index < limit ? <UserItemSite siteUser={su} /> : null
                  )}

                  {item?.siteUsers?.length > limit && (
                    <div className="mt-2">
                      {t("siteUsers.others", {
                        count: (item?.siteUsers?.length || 0) - limit,
                      })}
                    </div>
                  )}
                </div>
              </TooltipWrapper>
            </div>
          </div>
        )}

        {Boolean(
          item?.followedSites?.length && role.name === "Authenticated"
        ) && (
          <div className="flex flex-col flex-1 gap-2 lg:pl-4 lg:pr-6">
            <h4 className="text-xl font-bold text-primary leading-4 mb-1">
              {t("common.followedSites")}
            </h4>
            {item?.followedSites?.map((site: Site) => (
              <div className="flex items-center">
                <div className="rounded-full overflow-hidden w-8 h-8 mr-3 mt-1">
                  <ImageComponent
                    image={
                      site?.mainImage
                        ? ImageFromStrapiMedia(site.mainImage)?.uri
                        : undefined
                    }
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <span className="font-bold text-sm">{site?.name}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </Link>

      <div className="flex flex-col justify-between items-end text-xs lg:border-l pl-4 min-w-[22em] border-slate-150 gap-2">
        <div className={role ? "flex justify-end gap-2" : ""}>
          {role && <Status status={role?.name} />}
          <Status status={item.confirmed ? "confirmed" : "unconfirmed"} />
        </div>

        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />

              <Button
                title={t("actions.edit")}
                type="warning-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserItem;
