import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";

import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";

function QAndAForm({ onSubmit, onCancel, qAndA }: any) {
  const { t } = useTranslation();

  const initialValues = useMemo(
    () =>
      qAndA?.id
        ? {
            ...qAndA,
            site: qAndA.site ? qAndA.site?.id : undefined,
            post: qAndA.post ? qAndA.post?.id : undefined,
          }
        : {
            // first qAndA values since ThreadForm
            site: qAndA?.site || undefined,
            post: qAndA?.post || undefined,
            question: qAndA?.question,
            answerRich: qAndA?.answerRich || qAndA?.answer,
          },
    [qAndA]
  );

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <div className="px-4  text-slate-500 text-base">
          {/* Header */}
          <EditHeader
            title={t(qAndA?.id ? "editItem.qAndA" : "newItem.qAndA")}
            subtitle={values.question}
            icon="QuestionMarkCircleIcon"
            inModal
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={onCancel}
              />
            }
            shouldBeCol
          />

          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />

            <div className="white-box">
              <div className="flex flex-col gap-4 mt-2">
                <FormControl
                  title={t("forms.question")}
                  name="question"
                  type="textarea"
                  className="text-input"
                  inputContainerStyle="flex flex-1"
                  containerStyle="w-full"
                  labelIcon="BsTextIndentLeft"
                  required
                />
                <FormControl
                  title={t("forms.answer")}
                  name="answerRich"
                  type="richtext"
                  className="text-input"
                  containerStyle="w-full"
                  labelIcon="BsTextIndentLeft"
                  required
                />
              </div>
            </div>

            {
              // JSON.stringify(values)
            }

            <HeaderButtonsEdit onSubmit={handleSubmit} onCancel={onCancel} />
          </form>
        </div>
      )}
    />
  );
}
export default QAndAForm;
