import { useCallback, useMemo, useState } from "react";
import Modal from "common/components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { ContactGroup } from "types/ContactGroup";
import useOrganization from "common/hooks/useOrganization";
import fetchJSON from "common/utils/fetchJSON";
import { usePapaParse } from "react-papaparse";
import getWording from "common/utils/wording";
import SectionTitle from "../SectionTitle/SectionTitle";
import { FormInput } from "../FormInput/FormInput";
import FileUploader from "../FileUploader/FileUploader";
import ImageFromStrapiMedia from "../ImageFromStrapiMedia/ImageFromStrapiMedia";
import { RemoteSelect } from "../RemoteSelect/RemoteSelect";
import PageLoading from "../PageLoading/PageLoading";

export type ImportContactsModalProps = {
  confirmModal: (values: any) => void;
  closeModal: () => void;
  visible?: boolean;
};

type ContactInfo = {
  name: string;
  email: string;
};

function ImportContactsModal({
  confirmModal,
  closeModal,
  visible = true,
}: ImportContactsModalProps) {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const { readRemoteFile } = usePapaParse();

  const [fileToImport, setFileToImport] = useState<any[]>();
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const [contactGroupId, setContactGroupId] = useState(0);
  const [siteId, setSiteId] = useState(0);
  const [columns, setColumns] = useState<ContactInfo>({
    name: "",
    email: "",
  });
  const [recordExample, setRecordExample] = useState<any>({});
  const [isImporting, setIsImporting] = useState(false);
  const [nbImported, setNbImported] = useState(-1);
  const [nbNotImported, setNbNotImported] = useState(0);

  const isInputDataValid = useMemo(() => {
    return (
      fileToImport &&
      columns.name &&
      columns.email &&
      contactGroupId > 0 &&
      !isImporting
    );
  }, [fileToImport, columns, contactGroupId, isImporting]);

  const handleCsvContent = useCallback(async (result: any) => {
    try {
      // parse results
      const records = result.data;

      if (records.length > 0) {
        // count the columns
        const colNames = Object.keys(records[0]);

        setRecordExample(records[0]);

        setColumnNames(colNames);
        const emailColumn = colNames.includes("email") ? "email" : "";
        const nameColumn = colNames.includes("name") ? "name" : "";
        setColumns({ email: emailColumn, name: nameColumn });
      } else {
        console.error("No data to import or bad CSV format");
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleFileUploaded = useCallback(
    async (file: any) => {
      // count the number of columns in first row (line) of the imported CSV file
      const fileUrl = ImageFromStrapiMedia(file).uri;
      readRemoteFile(fileUrl, {
        header: true,
        preview: 1,
        skipEmptyLines: true,
        download: true,
        complete: async (result: any) => {
          await handleCsvContent(result);
          setFileToImport(file);
        },
      });
    },
    [handleCsvContent, readRemoteFile]
  );

  const processOptions = (options: ContactGroup[]) => {
    const list = options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    return list;
  };

  const handleUpload = async () => {
    try {
      setIsImporting(true);
      const res: any = await fetchJSON({
        url: `contacts/import`,
        method: "POST",
        payload: {
          data: {
            file: fileToImport,
            contactGroupId,
            columns,
            organizationId: organization?.id,
          },
        },
      });
      setNbImported(res?.successCount || 0);
      setNbNotImported(res?.alreadyExistCount || 0);
    } catch (e) {
      console.error(e);
    } finally {
      setIsImporting(false);
    }
  };

  return (
    <Modal
      confirmBtnLabel={
        !fileToImport || nbImported > -1 ? undefined : t("import.createBtn")
      }
      disableConfirmBtn={!isInputDataValid}
      onConfirmModal={nbImported > -1 ? confirmModal : handleUpload}
      onCloseModal={closeModal}
      visible={visible}
    >
      <div style={{ height: "100%", overflowY: "scroll", width: "100%" }}>
        {isImporting && <PageLoading />}
        {nbImported > -1 ? (
          <div className="flex flex-col w-full gap-4 mb-6">
            <SectionTitle /* icon="UploadIcon" */ title={t("import.success")} />
            <div className="flex flex-col w-full gap-4 items-center">
              <p className="flex-1 text-center w-full">
                {t("import.successMessage", {
                  count: nbImported,
                })}
                {nbNotImported ? (
                  <p className="flex-1 text-center w-full">
                    {t("import.alreadyExistMessage", {
                      count: nbNotImported,
                    })}
                  </p>
                ) : null}
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col w-full gap-4 mb-6">
              <SectionTitle
                /* icon="UploadIcon" */ title={t("import.contacts")}
              />
              <FileUploader
                max={1}
                onUpload={handleFileUploaded}
                canRenameFiles={false}
              />
            </div>
            {columnNames.length > 0 && (
              <div className="flex flex-col w-full gap-1 mb-6">
                <div className="flex flex-row gap-4 items-center justify-end">
                  <p className="basis-1/2 font-bold text-sm text-left pl-4">
                    {t("import.row2example")}
                  </p>
                </div>
                <div className="flex flex-row gap-4 items-center">
                  <p className="flex-1 font-bold text-right">
                    {t("forms.name")}
                  </p>
                  <div className="flex-1">
                    <FormInput
                      type="select"
                      name="nameColumn"
                      label={t("forms.name")}
                      value={columns?.name}
                      options={columnNames.map((colName) => ({
                        value: colName,
                        label: colName,
                      }))}
                      onChange={(value: string) =>
                        setColumns({ ...columns, name: value })
                      }
                    />
                  </div>
                  <p className="flex-[2] text-left text-xs">
                    {recordExample?.[columns?.name]}
                  </p>
                </div>
                <div className="flex flex-row gap-4 items-center">
                  <p className="flex-1 font-bold text-right">
                    {t("forms.email")}
                  </p>

                  <div className="flex-1">
                    <FormInput
                      type="select"
                      name="emailColumn"
                      label={t("forms.email")}
                      value={columns?.email}
                      options={columnNames.map((colName) => ({
                        value: colName,
                        label: colName,
                      }))}
                      onChange={(value: string) =>
                        setColumns({ ...columns, email: value })
                      }
                    />
                  </div>
                  <p className="flex-[2] text-left text-xs">
                    {recordExample?.[columns?.email]}
                  </p>
                </div>
                {/* Note : le selecteur de site n'est pas nécessaire,
                mais comme il peut y avoir beaucoup de groupes de contacts
                sur beaucoup de sites différents, ça permet de filtrer */}
                <div className="flex flex-col gap-2 items-center pt-8">
                  <p className="flex-1 text-left w-full font-bold">
                    {t("import.selectSite", {
                      wording: getWording(false, false),
                    })}{" "}
                    :
                  </p>
                  <RemoteSelect
                    url="sites"
                    filters={[
                      { name: "organization", value: organization?.id },
                    ]}
                    processOptions={processOptions}
                    onChange={(val: any) => setSiteId(val)}
                  />
                </div>
                <div className="flex flex-col gap-2 items-center pt-4">
                  <p className="flex-1 text-left w-full font-bold">
                    {t("import.selectContactGroup")} :
                  </p>
                  <RemoteSelect
                    url="contact-groups"
                    filters={[
                      { name: "site][organization", value: organization?.id },
                      { name: "site][id", value: siteId || undefined },
                    ]}
                    processOptions={processOptions}
                    onChange={setContactGroupId}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default ImportContactsModal;
