import classNames from "classnames";
import Icon, { IconNames } from "components/Icon/Icon";
import { I18nKey } from "i18n";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import ImageComponent from "../ImageComponent/ImageComponent";

export interface IRadioBlockItem {
  id: number;
  icon?: IconNames;
  imageLightMode?: string;
  imageDarkMode?: string;
  title?: string;
  titleKey?: I18nKey;
  subtitle?: string;
  subtitleKey?: I18nKey;
  color?: string;
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

function RadioBlockItem({
  id,
  icon = "BellAlertIcon",
  imageLightMode,
  imageDarkMode,
  title,
  titleKey,
  subtitle,
  subtitleKey,
  color = "primary",
  selected,
  onClick,
  disabled = false,
}: IRadioBlockItem) {
  const { t } = useTranslation();

  const colorClasses = useMemo(() => {
    if (imageLightMode || color?.startsWith("#")) {
      return undefined;
    }

    let newColor = color;
    // if color is not primary or secondary
    if (newColor !== "primary" && newColor !== "secondary") {
      // if color in hexa format
      if (newColor?.startsWith("#")) {
        newColor = `[${newColor}]`;
      } else {
        newColor = `${color}-500`;
      }
    }

    return {
      bg: `bg-${newColor}`,
      text: `text-${newColor}`,
      border: `border-${newColor}`,
    };
  }, [color, imageLightMode]);

  return (
    <div
      key={id}
      className={`border rounded-lg ${
        selected
          ? `shadow-lg shadow-slate-100 ${colorClasses?.bg} ${colorClasses?.border}`
          : `bg-white text-slate-600 ${
              disabled ? "" : "hover:border-secondary"
            }`
      } p-3 mt-2 cursor-pointer flex-1 min-w-[160px] max-w-[25%]`}
      style={
        selected
          ? {
              backgroundColor: !colorClasses ? color : undefined,
              borderColor: !colorClasses ? color : undefined,
            }
          : {}
      }
      onClick={onClick}
    >
      <div className="flex gap-4 items-center">
        {(icon || imageLightMode) && (
          <div
            className={`flex items-center justify-center rounded-md w-12 h-12 ${colorClasses?.bg}`}
            style={{
              backgroundColor: selected
                ? "white"
                : !colorClasses
                ? color
                : undefined,
            }}
          >
            {imageLightMode ? (
              <div className="w-full h-full rounded-md overflow-hidden p-2">
                <ImageComponent
                  image={
                    selected
                      ? imageLightMode || undefined
                      : imageDarkMode || undefined
                  }
                />
              </div>
            ) : (
              <Icon
                name={icon}
                className={`w-6 h-6 ${colorClasses?.text}`}
                style={{
                  color: selected
                    ? !colorClasses
                      ? color
                      : undefined
                    : "white",
                }}
              />
            )}
          </div>
        )}
        <div className="flex flex-col justify-center">
          <span
            className={`text-xl font-bold ${colorClasses?.text}`}
            style={{
              color: selected ? "white" : !colorClasses ? color : undefined,
            }}
          >
            {title || (titleKey ? t(titleKey) : "")}
          </span>
          <span
            className={classNames(
              "text-xxs uppercase",
              selected && "text-white/80",
              !selected && "text-slate-500"
            )}
          >
            {subtitle || (subtitleKey ? t(subtitleKey) : "")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default RadioBlockItem;
