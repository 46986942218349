import { IRadioBlockItem } from "common/components/RadioBlockItem/RadioBlockItem";

const rolesData: IRadioBlockItem[] = [
  {
    id: 3,
    icon: "BsStarHalf",
    titleKey: "roles.Pro",
    subtitleKey: "roles.ProSubtitle",
    color: "#60A5FA",
  },
  {
    id: 6,
    icon: "BsStarFill",
    titleKey: "roles.Administrateur",
    subtitleKey: "roles.AdministrateurSubtitle",
    color: "#1D4ED8",
  },
];

export default rolesData;
