// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { UserGroupIcon } from "@heroicons/react/24/outline";

import { ContactGroup } from "types/ContactGroup";

import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import UserSelect from "components/select/UserSelect/UserSelect";
import checkRights from "common/utils/checkRights";
import getWording from "common/utils/wording";
import AlertsContext from "common/providers/alerts";
import EmptyBlockWithCTA from "common/components/EmptyBlockWithCTA/EmptyBlockWithCTA";
import ContactGroupItem from "./ContactGroupItem";

function EmptyContactGroupsDataWithCTA() {
  const { t } = useTranslation();
  return (
    <EmptyBlockWithCTA
      title={t("emptyDatasCta.noContactsYet")}
      content={t("emptyDatasCta.forContactGroups")}
      actionTitle={t("emptyDatasCta.addContact")}
      actionUrl="/contacts/add"
    />
  );
}

function ContactGroups() {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const canManageContact = checkRights(
    currentUser,
    "canManageContact",
    currentUser?.mySitesId,
    ["isAdmin"]
  );

  const {
    fetchItems,
    items,
    isFetching,
    isItemsValid,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<ContactGroup>("contact-groups", {
    defaultFilters: {
      $or: {
        0: {
          site: {
            active: true,
            disabled: false,
          },
        },
      },
    },
    defaultSort: "name:ASC",
    populate: [
      "avatar",
      "site",
      "site.mainImage",
      "site.logo",
      "contacts",
      "contacts.avatar",
      "organizations",
    ],
    cachePrefix: `ContactGroups_${organization?.id || "all"}`,
  });

  const { items: contactItems } = useList("contacts", {
    defaultFilters: organization?.id
      ? {
          organization: organization.id,
        }
      : {},
    cachePrefix: `Contacts_inContactGroups_${organization?.id || "all"}`,
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  useEffect(() => {
    setFilter("$or][0][site][organization", organization?.id || false);
    setFilter("$or][1][organization", organization?.id || false);
    setFilter("$or][1][appliedToTheWholeOrganization", true);
  }, [organization, setFilter]);

  const { removeItem } = useItem<ContactGroup>("contact-groups", "");

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, removeItem, fetchItems, setAlert]
  );

  const handleNavigate = (id: any) => {
    navigate(`/contact-groups/edit/${id}`);
  };

  /* const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]); */

  const columns = useMemo(
    () => [
      {
        Header: t("forms.name"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-[2]",
      },
      {
        Header: t("forms.site", {
          wording: getWording(false, true),
        }),
        filterName: "site][id",
        filterComponent: (props: any) => (
          <SiteSelect
            isFilter
            Icon={WorkSiteIcon}
            allowReadOnlyOptions
            {...props}
          />
        ),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("forms.user"),
        filterName: "users][id",
        filterComponent: (props: any) => (
          <UserSelect isFilter Icon={WorkSiteIcon} {...props} />
        ),
        columnClasses: "md:flex-1",
      },
    ],
    [t]
  );

  const isBlank = !isItemsValid && !isFetching;

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.contactGroups")}
        subtitle={t("titleLegends.contactGroups")}
        btnTitle={t("newItem.contactGroup")}
        btnSubtitle={t("newItem.contactGroupLegend")}
        Icon={UserGroupIcon}
        addLink="/contact-groups/add"
        addLinkDisabled={!canManageContact}
        isBeforeFilters
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={ContactGroupItem}
        RenderEmptyBlock={
          !contactItems?.length && !isBlank
            ? EmptyContactGroupsDataWithCTA
            : undefined
        }
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default ContactGroups;
