/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { SortableContainer } from "react-sortable-hoc";

export type SortableItemProps = {
  name: string;
  percentage: number;
  url: string;
  isToRemove: boolean;
  onRemoveFile: (value: any) => any;
  paddingTop?: number;
  width?: number;
};

// const SortableItem = SortableElement(({
function SortableItem({
  name,
  percentage,
  url,
  isToRemove,
  onRemoveFile,
  paddingTop,
  width,
}: SortableItemProps) {
  const value: any = { name, percentage, url, isToRemove };

  const itemStyle = {
    paddingTop,
    backgroundImage: `url(${import.meta.env.VITE_API_URL}${url})`,
  };

  if (isToRemove) return <div style={{ width: 0 }} />;

  return (
    <div className="image-item" title={name} style={{ width }}>
      <div className="image-item-inside" style={itemStyle}>
        <div className="ProgressBar">
          <div className="Progress" style={{ width: `${percentage}%` }} />
        </div>
        <div className="image-delete">
          <button
            type="button"
            className="button is-danger"
            onClick={onRemoveFile(value)}
          >
            <span className="icon">
              <i className="fas fa-trash-alt" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
// });

SortableItem.defaultProps = {
  paddingTop: 0,
  width: 0,
};

export type SortableListProps = {
  items: any;
  onRemoveFile: (value: any) => any;
};

const SortableList = SortableContainer(
  ({ items, onRemoveFile }: SortableListProps) => (
    <div className={`image-list ${items.length ? "has-items" : ""}`}>
      {items.map((value: any, index: number) => (
        <SortableItem
          key={`item-${index}`}
          name={value.name}
          percentage={value.percentage}
          url={value.url}
          isToRemove={value.isToRemove}
          onRemoveFile={onRemoveFile}
        />
      ))}
    </div>
  )
);

function FilesList(props: any) {
  return <SortableList {...props} />;
}

FilesList.propTypes = {
  axis: PropTypes.any,
  nbCols: PropTypes.number,
  ratio: PropTypes.number,
  onSortEnd: PropTypes.func,
  items: PropTypes.array,
  onRemoveFile: PropTypes.func,
};

FilesList.defaultProps = {
  axis: "",
  nbCols: 1,
  ratio: 1,
  items: [],
  onRemoveFile() {},
  onSortEnd() {},
};

export default FilesList;
