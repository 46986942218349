import { uniqueId } from "lodash";
import { PlacesType, Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";

import "./TooltipWrapper.css";

interface ITooltip {
  content: React.ReactElement | null;
  children: React.ReactElement | string | null;
  place?: PlacesType;
  className?: string;
}

function TooltipWrapper({
  children,
  content,
  place = "top",
  className = "",
}: ITooltip) {
  const id = uniqueId("tooltip");
  if (!content) {
    return <div>{children}</div>;
  }
  return (
    <>
      <a
        data-tooltip-id={id}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(content)}
        data-tooltip-place={place}
        className={`leading-none ${className}`}
      >
        {children}
      </a>
      <Tooltip id={id} className="custom-tooltip z-[100]" />
    </>
  );
}

export default TooltipWrapper;
