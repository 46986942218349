import useOrganization from "common/hooks/useOrganization";
import { capitalize } from "lodash";

const getWording = (plural?: boolean, capital?: boolean) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { organization } = useOrganization();

  if (plural) {
    return capital
      ? capitalize(organization?.vocabularySite?.Pl_main || "sites")
      : organization?.vocabularySite?.Pl_main || "sites";
  }
  return capital
    ? capitalize(organization?.vocabularySite?.Sg_main || "site")
    : organization?.vocabularySite?.Sg_main || "site";
};

export default getWording;
