import useItem from "common/hooks/useItem";

import useRouter from "common/hooks/use-router";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import HeaderButtons from "common/components/DetailHeader/HeaderButtons";
import PublicationIcon from "common/components/PostsAndActivities/PublicationIcon";
import PageLoading from "common/components/PageLoading/PageLoading";
import PublicationHeaderSubTitle from "common/components/PostsAndActivities/PublicationHeaderSubTitle";
import useAuth from "common/hooks/useAuth";
import { ContactGroup } from "types/ContactGroup";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import truncateString from "common/utils/truncateString";
import {
  BellIcon,
  EnvelopeIcon,
  EyeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import checkRights from "common/utils/checkRights";
import { Contact } from "types";
import { useContext, useEffect } from "react";
import AlertsContext from "common/providers/alerts";
import { useTranslation } from "react-i18next";
import getWording from "common/utils/wording";

function ContactGroupDetails() {
  const router = useRouter();
  const { t } = useTranslation();
  const id = router.query.id as string;
  const { setAlert } = useContext(AlertsContext);

  const { item, isFetching, error } = useItem<ContactGroup>(
    "contact-groups",
    id,
    {
      populate: ["site", "contacts", "contacts.avatar"],
    }
  );

  const { user: currentUser } = useAuth();

  const canManageContact = checkRights(
    currentUser,
    "canManageContact",
    item && item?.site ? [item?.site?.id] : undefined,
    ["isAdmin"]
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const { name } = (item as ContactGroup) || {};

  if (isFetching || !item) {
    return <PageLoading />;
  }

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <DetailHeader
        backLink="/contact-groups"
        title={name}
        subtitleIcon="WorkSiteIcon"
        TitleIcon={
          <PublicationIcon
            labelType="contact-group"
            iconStyle="w-7 h-7 text-white"
          />
        }
        SubTitle={
          <PublicationHeaderSubTitle
            siteName={
              item?.site?.name ||
              t("titleLegends.forAllSites", {
                wording: getWording(true, false),
              })
            }
            location={item?.type}
          />
        }
        HeaderInfos={
          <div className="flex flex-col w-full">
            <div className="flex items-center gap-2">
              <EyeIcon
                height={20}
                width={20}
                className={
                  item.targetWithoutSubscription
                    ? "text-emerald-600"
                    : "text-slate-400"
                }
              />
              <span
                className={`${
                  item.targetWithoutSubscription
                    ? "font-bold text-slate-900"
                    : ""
                } text-sm`}
              >
                {item.targetWithoutSubscription
                  ? t("forms.targetWithoutSubscriptionEnabled")
                  : t("forms.targetWithoutSubscriptionDisabled")}
              </span>
            </div>

            <div className="flex items-center gap-2">
              <BellIcon
                height={20}
                width={20}
                className={
                  item.notifyWithoutUser ? "text-emerald-600" : "text-slate-400"
                }
              />
              <span
                className={`${
                  item.notifyWithoutUser ? "font-bold text-slate-900" : ""
                } text-sm`}
              >
                {item.notifyWithoutUser
                  ? t("forms.notifyWithoutUserEnabled")
                  : t("forms.notifyWithoutUserDisabled")}
              </span>
            </div>
          </div>
        } // empty space at left
        HeaderButtons={
          <HeaderButtons
            editUrl={`/contact-groups/edit/${id}`}
            editDisabled={!canManageContact}
          />
        }
      />

      <div className="grid md:grid-cols-2 gap-2 mt-2">
        {item?.contacts?.map((contact: Contact) => {
          return (
            <div className="flex gap-4 items-start justify-start border bg-white border-slate-200 p-3 rounded-lg text-slate-700">
              <div className="flex relative w-20 h-20">
                <div className="absolute -top-2 -left-2">
                  <WorkSiteIcon iconStyle="w-4 h-4 text-white" />
                </div>
                <div className="rounded-lg overflow-hidden">
                  <ImageComponent
                    image={
                      contact?.avatar
                        ? ImageFromStrapiMedia(contact.avatar).uri
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col flex-1 gap-2">
                <div className="flex-1 mt-1">
                  <h4 className="text-xl font-bold text-primary leading-4">
                    {contact?.name}
                  </h4>
                  {contact?.quality && (
                    <div className="text-sm text-slate-500 mt-1 ">
                      {truncateString(contact?.quality, 150)}
                    </div>
                  )}
                </div>
                {contact?.email && (
                  <div className="flex items-center gap-1 text-sm">
                    <EnvelopeIcon className="w-3 h-3" />
                    <span className="font-bold">{contact?.email}</span>
                  </div>
                )}
                {contact?.phoneNumber && (
                  <div className="flex items-center gap-1 text-sm">
                    <PhoneIcon className="w-3 h-3" />
                    <span className="font-bold">{contact?.phoneNumber}</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ContactGroupDetails;
