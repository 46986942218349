import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import EditHeader from "common/components/EditHeader/EditHeader";
import useRouter from "common/hooks/use-router";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import imgSizes from "conf/imgSizes.json";
import useOrganization from "common/hooks/useOrganization";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";

const setMarkerCenter = (value: any, form: any, formValues: any) => {
  // anticipe la valeur qui n'est pas encore à jour dans la fonction
  const previousValue = Boolean(!formValues?.[value]);

  if (value === "centeredOnCenter") {
    form.mutators.setFieldValue({
      field: "centeredOnCenter",
      value: previousValue,
    });
    form.mutators.setFieldValue({
      field: "centeredOnBottomCenter",
      value: !previousValue,
    });
  } else if (value === "centeredOnBottomCenter") {
    form.mutators.setFieldValue({
      field: "centeredOnCenter",
      value: !previousValue,
    });
    form.mutators.setFieldValue({
      field: "centeredOnBottomCenter",
      value: previousValue,
    });
  }
};

function MarkerForm({ onSubmit, marker }: any) {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [errorMessage, setErrorMessage] = useState("");
  const { organization: currentOrganization } = useOrganization();

  const initialValues = useMemo(
    () =>
      marker?.id
        ? {
            ...marker,
          }
        : {
            label: "",
            image: null,
            organization: currentOrganization?.id,
            centeredOnCenter: false,
            centeredOnBottomCenter: true,
            size: 30,
          },
    [currentOrganization?.id, marker]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ form, handleSubmit, values }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(marker?.id ? "editItem.marker" : "newItem.marker")}
              subtitle={values.name}
              icon="MapPinIcon"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/markers")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex gap-2 mt-2">
                <div className="md:w-full mb-2 md:mb-0">
                  <div className="flex flex-col gap-2 w-full">
                    <div className="white-box">
                      <div className="responsive-flex gap-4">
                        <div className="flex flex-col w-full md:w-[180px]">
                          <FormControl
                            title={t("forms.image")}
                            labelIcon="PhotoIcon"
                            name="image"
                            type="image"
                            maxWidth={imgSizes.icon.width}
                            maxHeight={imgSizes.icon.height}
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <FormControl
                            name="label"
                            titleKey={t("forms.name")}
                            required
                            containerStyle="flex w-full mb-4"
                            className="text-input"
                          />
                          <div className="flex items-center mb-2">
                            <FormControl
                              type="custom"
                              name="centeredOnCenter"
                              onChange={() =>
                                setMarkerCenter(
                                  "centeredOnCenter",
                                  form,
                                  values
                                )
                              }
                            >
                              <ToggleSwitch
                                rightLabel={t("forms.centeredOnCenter")}
                              />
                            </FormControl>
                          </div>
                          <div className="flex items-center mb-4">
                            <FormControl
                              type="custom"
                              name="centeredOnBottomCenter"
                              onChange={() =>
                                setMarkerCenter(
                                  "centeredOnBottomCenter",
                                  form,
                                  values
                                )
                              }
                            >
                              <ToggleSwitch
                                rightLabel={t("forms.centeredOnBottomCenter")}
                              />
                            </FormControl>
                          </div>
                          <FormControl
                            name="size"
                            titleKey={t("forms.iconSize")}
                            required
                            containerStyle="self-start"
                            validate={(value) => {
                              return Number.isNaN(parseInt(value, 10))
                                ? t("forms.shouldBeNumber")
                                : undefined;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/markers")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}
export default MarkerForm;
