import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import SiteForm from "./SiteForm";

function Site() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    "sites",
    id,
    {
      populate: [
        "mainImage",
        "logo",
        "steps",
        "keyIndicators",
        "documents",
        "organization",
        "parent",
        "children",
        "siteUsers",
        "siteUsers.user",
        "siteUsers.contact",
        "siteUsers.jobPosition",
        "siteType",
        "threadTypes",
        "qAndAs",
        "qAndAs.post",
        "links",
        "customMarkerSelected",
        "customMarkerSelected.image",
        "adminContact",
      ],
    }
  );

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/sites");
    }
  }, [setAlert, router, error, t]);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const onSubmit = useCallback(
    async (dataSite: any) => {
      const site = { ...dataSite };

      try {
        if (site.id) {
          await updateItem(site.id, site);
        } else {
          await saveItem(site);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <SiteForm onSubmit={onSubmit} site={item} />
  );
}

export default Site;
