import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect, { components } from "react-select";
import Async from "react-select/async";
import Icon, { IconNames } from "../../../components/Icon/Icon";

import "./Select.css";

/**
 * Override of React Select :
 * - return option.value instead of option (obj) (works also with multiple values instead of array of option)
 *   To be able to initialize more quickly a select in a form
 * - onChange will returns also the full option (row) as second arguments, onChange(opt.value,opt)
 * - Default label
 * - Clearable by default, except if required
 * - Default NoResult Message (intl)
 * - Default label Message (intl)
 * - Close Menu on Select except if Multiple
 * - disabled and multiple props instea of multi and isDisabled for more common name
 */
type AsyncType = React.ComponentProps<Async>;

export type Props = {
  loadOptions?: AsyncType["loadOptions"];
  value?: any;
  children?: JSX.Element;
  placeholder?: string;
  onChange?: (val: any, full: any) => void;
  alsoOnChange?: () => void;
  multiple?: boolean;
  clearable?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  options?: any[];
  defaultOptions?: any[];
  isFilter?: boolean;
  "data-cy"?: string;
  icon?: IconNames;
  isLoading?: boolean;
};

// https://react-select.com/styles#the-classnames-prop
// This ensures that Emotion's styles are inserted before Tailwind's styles so that Tailwind classes have precedence over Emotion
function EmotionCacheProvider({ children }: { children: React.ReactNode }) {
  const cache = React.useMemo(
    () =>
      createCache({
        key: "with-tailwind",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        insertionPoint: document.querySelector("title")!,
      }),
    []
  );

  return <CacheProvider value={cache}>{children}</CacheProvider>;
}

function SingleValue({ children, data, ...props }: any) {
  const icon = data.icon || props.icon;
  return (
    <components.SingleValue {...props} className="flex gap-1 items-center">
      {icon && <Icon name={icon} className="w-4 h-4" />}
      {children}
    </components.SingleValue>
  );
}
function Option({ children, data, ...props }: any) {
  const { icon } = data;
  return (
    <components.Option {...props} className="flex gap-1 items-center">
      {icon && <Icon name={icon} className="w-4 h-4" />}
      {children}
    </components.Option>
  );
}
function Placeholder({ children, ...props }: any) {
  const { icon } = props;
  return (
    <components.Placeholder {...props} className="flex gap-1 items-center">
      {icon && <Icon name={icon} className="w-4 h-4" />}
      {children}
    </components.Placeholder>
  );
}

function Select({
  value,
  onChange = () => {},
  alsoOnChange,
  multiple = false,
  loadOptions,
  clearable = true,
  disabled = false,
  required = false,
  options = [],
  placeholder,
  defaultOptions,
  "data-cy": dataCy,
  icon,
  isLoading = false,
  isFilter = false,
  ...rest
}: Props) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (val: any) => {
      let v;

      if (!val) {
        v = val;
      } else if (Array.isArray(val)) {
        v = val.map((item) => item.value);
      } else {
        v = val.value;
      }

      if (multiple && !Array.isArray(v)) {
        v = [v];
      }

      onChange(v, val);

      if (alsoOnChange) {
        alsoOnChange();
      }
    },
    [onChange, alsoOnChange, multiple]
  );

  const selectedOption = useMemo(() => {
    if (!value) return value;

    const opts = options || defaultOptions || [];

    if (multiple) {
      return opts.filter((opt) => value.includes(opt.value));
    }

    return opts.find((opt) => opt.value === value);
  }, [value, multiple, options, defaultOptions]);

  let isClearable = clearable;

  if (required) {
    isClearable = false;
  }

  const Component = loadOptions ? Async : ReactSelect;

  return (
    <EmotionCacheProvider>
      <Component
        menuPortalTarget={document.body}
        loadOptions={loadOptions}
        value={selectedOption}
        onChange={handleChange}
        noOptionsMessage={() => t("common.noResult")}
        loadingMessage={() => t("common.loading")}
        closeMenuOnSelect={!multiple}
        placeholder={placeholder || t("common.search")}
        isMulti={multiple}
        isDisabled={disabled}
        isClearable={isClearable}
        options={options}
        defaultOptions={defaultOptions}
        classNamePrefix="react-select"
        aria-label={dataCy}
        menuPlacement="bottom"
        isLoading={isLoading}
        classNames={{
          control: ({ isDisabled, isFocused }) =>
            classNames(
              "cursor-pointer rounded-md py-0.5 shadow-none w-full border-slate-200 hover:border-secondary",
              !isDisabled &&
                isFocused &&
                "hover:border-secondary-500 border-secondary-500",
              isFocused && "box-shadow-0",
              isFilter ? "bg-slate-300/60" : "bg-slate-200/60"
            ),
          dropdownIndicator: () => "text-secondary hover:text-primary",
          option: ({ isDisabled, isFocused, isSelected }) =>
            classNames(
              "cursor-pointer first:rounded-t last:rounded-b active:text-white focus:text-white",
              isSelected && "bg-primary text-white",
              !isSelected &&
                isFocused &&
                "bg-secondary text-white active:text-white focus:text-white",
              !isDisabled && isSelected && "text-white active:bg-secondary-600",
              !isDisabled &&
                !isSelected &&
                "hover:text-white active:text-white focus:text-white active:bg-secondary-600"
            ),
          menuList: () => "m-0 p-0",
        }}
        components={{
          SingleValue: (props) => <SingleValue icon={icon} {...props} />,
          Option: (props) => <Option icon={icon} {...props} />,
          Placeholder: (props) => <Placeholder icon={icon} {...props} />,
        }}
        {...rest}
      />
    </EmotionCacheProvider>
  );
}

export default Select;
