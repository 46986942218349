import getInitials from "common/utils/getInitials";
import { useMemo } from "react";
import ImageComponent from "../ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "../ImageFromStrapiMedia/ImageFromStrapiMedia";

interface IAvatar {
  name?: string;
  image?: any;
  size?: string;
}

function Avatar({ name, image, size = "6" }: IAvatar) {
  const initials = useMemo(() => getInitials(name), [name]);

  const content = image ? (
    <ImageComponent image={ImageFromStrapiMedia(image).uri} />
  ) : (
    <span className="text-xs font-medium leading-none text-white">
      {initials}
    </span>
  );
  return (
    <span
      className={`inline-flex overflow-hidden items-center justify-center rounded-full bg-secondary-400 border border-white 
    w-${size} h-${size}
    `}
    >
      {content}
    </span>
  );
}

export default Avatar;
