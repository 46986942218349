import { useTranslation } from "react-i18next";

import Header from "common/components/Header/Header";
import useAuth from "common/hooks/useAuth";
import { BiCommentError } from "react-icons/bi";
import ImportModal from "common/components/Import/ImportModal";
import { useCallback, useContext, useEffect, useMemo } from "react";
import useList from "common/hooks/useList";
import FilteredList from "common/components/Table/FilteredList";
import useItem from "common/hooks/useItem";
import AlertsContext from "common/providers/alerts";
import ImportItem from "./ImportItem";

function Imports() {
  const { t } = useTranslation();
  //   const { navigate } = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<any>("imports", {
    defaultFilters: {},
    defaultSort: "createdAt:DESC",
    cachePrefix: "Importss_all",
  });
  const { removeItem } = useItem<any>("imports", "");

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("forms.name"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-[2]",
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.import")}
        subtitle={t("titleLegends.import")}
        btnTitle={t("newItem.import")}
        btnSubtitle={t("newItem.importLegend")}
        Icon={BiCommentError}
        addLinkDisabled={!currentUser?.isSuperadmin}
        isBeforeFilters
        canImport={currentUser?.isSuperadmin}
        ImportModal={ImportModal}
      />
      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={ImportItem}
        onDelete={handleDelete}
        onNavigate={() => {}}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Imports;
