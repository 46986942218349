import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";

import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import useRouter from "common/hooks/use-router";
import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import imgSizes from "conf/imgSizes.json";

function PostTypeForm({ onSubmit, postType }: any) {
  const { t } = useTranslation();
  const { navigate } = useRouter();

  const colorOptions = useMemo(
    () => [
      { label: "Rouge", value: "#DD1E1E" },
      { label: "Orange", value: "#f97316" },
      { label: "Bleu", value: "#3b82f6" },
      { label: "Violet", value: "#742b7f" },
      { label: "Vert", value: "#006013" },
      { label: "Turquoise", value: "#0099A4" },
    ],
    []
  );

  const initialValues = useMemo(
    () =>
      postType?.id
        ? {
            color: "#34A6FF",
            ...postType,
          }
        : {
            name: "",
            shouldHavePlanning: true,
            shouldHaveLocation: true,
            shouldHavePerturbation: true,
            shouldHaveAdvice: true,
            shouldHaveAction: true,
            subtitle: "",
            imageLightMode: null,
            imageDarkMode: null,
            color: "#34A6FF",
          },
    [postType]
  );

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <div className="mx-auto max-main-box py-4 px-4 text-slate-500 text-base">
          {/* Header */}
          <EditHeader
            title={t(postType?.id ? "editItem.postType" : "newItem.postType")}
            subtitle={values.name}
            icon="WalletIcon"
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={() => navigate("/post-types")}
              />
            }
          />

          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />

            <div className="responsive-flex gap-2 mt-2">
              <div className="md:w-full mb-2 md:mb-0">
                <div className="flex flex-col gap-6 w-full">
                  <div className="white-box">
                    <div className="responsive-flex gap-6">
                      <div className="flex flex-col w-full md:w-auto md-flex-[1]">
                        <div className="flex flex-row gap-6">
                          <FormControl
                            title={t("forms.imageLightMode")}
                            labelIcon="PhotoIcon"
                            name="imageLightMode"
                            type="image"
                            maxWidth={imgSizes.icon.width}
                            maxHeight={imgSizes.icon.height}
                            style={{
                              padding: "0.5rem",
                            }}
                          />
                          <div>
                            <FormControl
                              title={t("forms.imageDarkMode")}
                              labelIcon="PhotoIcon"
                              name="imageDarkMode"
                              type="image"
                              maxWidth={imgSizes.icon.width}
                              maxHeight={imgSizes.icon.height}
                              style={{
                                backgroundColor: values.color,
                                borderRadius: "0.5rem",
                                padding: "0.5rem",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col w-full md:w-auto md:flex-[2]">
                        <FormControl
                          name="name"
                          titleKey={t("forms.name")}
                          required
                          containerStyle="flex w-full mb-4"
                          className="text-input"
                        />
                        <FormControl
                          name="subtitle"
                          titleKey={t("forms.subtitle")}
                          required
                          containerStyle="mb-4"
                        />
                        <FormControl
                          type="select"
                          name="color"
                          titleKey={t("forms.color")}
                          options={colorOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex-[1]">
                    <div className="white-box gap-3 flex flex-col">
                      <SectionTitle title={t("forms.config")} />

                      <div className="flex items-center ">
                        <FormControl type="custom" name="shouldHavePlanning">
                          <ToggleSwitch
                            rightLabel={t("forms.shouldHavePlanning")}
                            legende={t("forms.shouldHavePlanningLegend")}
                          />
                        </FormControl>
                      </div>
                      <div className="flex items-center ">
                        <FormControl type="custom" name="shouldHaveLocation">
                          <ToggleSwitch
                            rightLabel={t("forms.shouldHaveLocation")}
                            legende={t("forms.shouldHaveLocationLegend")}
                          />
                        </FormControl>
                      </div>
                      <div className="flex items-center ">
                        <FormControl
                          type="custom"
                          name="shouldHavePerturbation"
                        >
                          <ToggleSwitch
                            rightLabel={t("forms.shouldHavePerturbation")}
                            legende={t("forms.shouldHavePerturbationLegend")}
                          />
                        </FormControl>
                      </div>
                      <div className="flex items-center ">
                        <FormControl type="custom" name="shouldHaveAdvice">
                          <ToggleSwitch
                            rightLabel={t("forms.shouldHaveAdvice")}
                          />
                        </FormControl>
                      </div>
                      <div className="flex items-center ">
                        <FormControl type="custom" name="shouldHaveAction">
                          <ToggleSwitch
                            rightLabel={t("forms.shouldHaveAction")}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <HeaderButtonsEdit
              onSubmit={handleSubmit}
              onCancel={() => navigate("/post-types")}
            />
          </form>
        </div>
      )}
    />
  );
}
export default PostTypeForm;
