import Icon from "components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { ContactGroup } from "types/ContactGroup";
import { getSpecialStyle } from "../CheckBoxBlockItem/CheckBoxBlockItem";
import EmptyResult from "../EmptyResult/EmptyResult";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

interface IContactGroups {
  contactGroups: ContactGroup[];
  short?: boolean;
}

function ContactGroups({ contactGroups, short = false }: IContactGroups) {
  const { t } = useTranslation();
  if (!contactGroups?.length) {
    return <EmptyResult text={t("forms.noContactGroup")} />;
  }

  /*
            short
              ? "flex-1 text-ellipsis whitespace-nowrap overflow-hidden"
              : ""
        */

  const list = contactGroups?.map(({ name, isSpecial, description }) => {
    const node = () => (
      <span
        key={name}
        className={`flex gap-1 h-6 items-center border ${getSpecialStyle(
          false,
          isSpecial
        )} rounded-lg py-1 px-2 text-xs`}
        /* short ? "max-w-[150px]" : "" */
      >
        <Icon name="UsersIcon" className="w-4 h-4" />
        <span>{name}</span>

        {!short && description && (
          <span className="text-xs text-slate-400 italic">
            {" "}
            - {description}
          </span>
        )}
      </span>
    );
    return short && description ? (
      <TooltipWrapper
        className="mr-1 my-1"
        content={<span>{description}</span>}
      >
        {node()}
      </TooltipWrapper>
    ) : (
      <div className="mr-1 my-1">{node()}</div>
    );
  });
  return (
    <div
      className={`flex gap-1 items-center flex-wrap ${
        short ? "flex-wrap" : ""
      }`}
    >
      {list}
    </div>
  );
}

export default ContactGroups;
