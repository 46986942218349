import React from "react";

function PollutionIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="186"
      height="77"
      version="1"
      viewBox="0 0 186 177"
      fill="currentColor"
      {...props}
    >
      <path
        d="M17.1,123.9c0.6-1,1.1-2.3,1.9-3.1c2.6-2.6,6.8-2.1,9,1c2,3,4.2,5.6,7.3,7.6c9.2,6,22.3,3.5,28.6-5.6
	c0.5-0.8,1.1-1.6,1.6-2.3c2.5-3.1,7.1-3.1,9.4,0.1c2.1,3,4.3,5.8,7.5,7.8c9.2,6,22.3,3.5,28.6-5.6c0.5-0.7,1-1.5,1.5-2.2
	c2.5-3.2,7-3.2,9.5-0.1c1.6,2.1,3.1,4.3,5.1,6c9.5,8.3,24.2,6.3,31.3-4.2c1-1.5,2-3.1,3.9-3.8c2.4-0.9,5-0.2,6.6,1.7
	s1.9,4.6,0.6,6.8c-3.9,6.6-9.3,11.4-16.4,14.2c-10.5,4-20.6,3.1-30.2-2.9c-2-1.3-3.8-2.9-5.7-4.3c-12,12-33.2,13.6-46.7,0.3
	c-3.4,2.2-6.6,4.7-10.2,6.3c-10.8,4.8-21.3,4-31.4-2.2c-4.5-2.7-7.9-6.5-10.7-10.9c-0.4-0.7-0.7-1.5-1.1-2.2
	C17.1,125.5,17.1,124.7,17.1,123.9z"
      />
      <path
        d="M17.1,35.9c0.2-0.3,0.3-0.6,0.5-0.9c0.9-2.1,2.5-3.4,4.7-3.6c2.4-0.3,4.4,0.5,5.7,2.5c2,3,4.2,5.6,7.3,7.6
	c9.3,6.1,22.4,3.5,28.7-5.7c0.5-0.7,1-1.5,1.5-2.2c2.4-3.1,6.9-3.1,9.3-0.1c1.7,2.1,3.3,4.4,5.3,6.2c9.1,8.1,24.1,6.2,30.9-3.9
	c0.4-0.7,0.9-1.3,1.4-2c2.6-3.5,7.2-3.5,9.8,0c2.1,2.9,4.3,5.6,7.3,7.6c9.3,6.1,22.4,3.5,28.7-5.7c0.4-0.7,0.9-1.3,1.4-2
	c2-2.7,5.5-3.4,8.2-1.6c2.7,1.8,3.5,5.3,1.7,8.2c-5.3,8.5-12.7,13.9-22.6,15.7c-10.5,1.9-19.8-0.8-27.9-7.7c-0.5-0.4-1-0.9-1.5-1.4
	c-13.4,13.1-34.2,12.7-47.1,0c-8.3,7.8-17.9,11.3-28.9,9.5s-19.2-7.8-24.4-17.7C17.1,37.7,17.1,36.8,17.1,35.9z"
      />
      <path
        d="M17.1,79.9c0.8-1.9,1.8-3.5,3.9-4.3c2.7-1,5.5-0.2,7.2,2.4c1.9,2.9,4.2,5.5,7.2,7.5c9.2,6,22.3,3.4,28.6-5.7
	c0.4-0.5,0.7-1.1,1.1-1.6c2.9-4,7.6-4.1,10.4,0.1c1.9,2.8,4.1,5.3,6.9,7.1c9.3,6.1,22.4,3.6,28.7-5.6c0.5-0.7,1-1.5,1.5-2.2
	c2.5-3.2,7.1-3.2,9.5,0c2,2.8,4.1,5.6,7.1,7.6c9.6,6.4,22.8,3.8,29.2-5.8c1.1-1.6,2.1-3.3,4.1-3.9c2.4-0.8,5,0,6.6,2s1.7,4.7,0.3,7
	c-5.3,8.6-12.9,14-22.9,15.7c-10.3,1.8-19.5-0.9-27.5-7.8c-0.5-0.5-1-0.9-1.6-1.5c-6.6,6.4-14.4,9.8-23.5,9.8S77,97.3,70.6,91.1
	c-2.2,1.6-4.2,3.3-6.4,4.7c-11.8,7.2-26.3,6.5-37.3-1.8c-4.1-3.1-7.5-6.9-9.5-11.7C17.1,81.5,17.1,80.7,17.1,79.9z"
      />
    </svg>
  );
}

export default PollutionIcon;
