import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AvatarsStack from "common/components/AvatarsStack/AvatarsStack";
import Icon from "components/Icon/Icon";
import { Message } from "types/Message";
import { User } from "types";

interface ICommentFooter {
  privateMessages: Message[];
}

function CommentFooter({ privateMessages }: ICommentFooter) {
  const { t } = useTranslation();
  const privateAuthors: User[] = useMemo(() => {
    if (!privateMessages) {
      return [];
    }
    const messagesObj = privateMessages.reduce(
      (acc: any, privateMessage: Message) => {
        if (acc[privateMessage.author?.id]) {
          return acc;
        }
        acc[privateMessage.author?.id] = privateMessage.author;
        return acc;
      },
      {}
    );

    return Object.values(messagesObj);
  }, [privateMessages]);

  const content = useMemo(() => {
    if (privateAuthors.length) {
      return (
        <div className="flex gap-2 group-hover:text-secondary transition-all font-bold">
          {t("threads.privateMessageAnswer", { count: privateMessages.length })}
          <AvatarsStack users={privateAuthors} stack />
        </div>
      );
    }

    return (
      <>
        <Icon
          name="PlusCircleIcon"
          className="w-4 h-4 group-hover:text-secondary"
        />
        <div className="cursor-pointer group-hover:text-secondary transition-all">
          {t("threads.newPrivateMessage")}
        </div>
      </>
    );
  }, [t, privateAuthors, privateMessages]);

  return (
    <div className="flex items-center gap-2">
      {content}
      <Icon
        name="ChevronRightIcon"
        className="w-4 h-4 group-hover:text-secondary"
      />
    </div>
  );
}

export default CommentFooter;
