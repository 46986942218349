// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { ThreadType } from "types/ThreadType";

import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import useAuth from "common/hooks/useAuth";
import AlertsContext from "common/providers/alerts";
import { BiCommentError } from "react-icons/bi";
import ThreadTypeItem from "./ThreadTypeItem";

function ThreadTypes() {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<ThreadType>("thread-types", {
    defaultSorts: ["rank:ASC", "name:ASC"],
    populate: ["imageLightMode", "imageDarkMode"],
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const { removeItem } = useItem<ThreadType>("thread-types", "");

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, removeItem, fetchItems, setAlert]
  );

  const handleNavigate = (id: any) => {
    navigate(`/thread-types/edit/${id}`);
  };

  /* const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]); */

  const columns = useMemo(
    () => [
      {
        Header: t("forms.name"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-[2]",
      } /* ,
      {
        Header: t("forms.icon"),
        columnClasses: "md:flex-1",
      } */,
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.threadTypes")}
        subtitle={t("titleLegends.threadTypes")}
        btnTitle={t("newItem.threadType")}
        btnSubtitle={t("newItem.threadTypeLegend")}
        Icon={BiCommentError}
        addLink="/thread-types/add"
        addLinkDisabled={!currentUser?.isAdmin && !currentUser?.isSuperadmin}
        isBeforeFilters
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={ThreadTypeItem}
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default ThreadTypes;
