import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "common/components/Modal/Modal";
import Icon, { IconNames } from "components/Icon/Icon";

const iconNamesArray: IconNames[] = [
  "CalendarDaysIcon",
  "MapPinIcon",
  "PhotoIcon",
  "TrashIcon",
  "UserGroupIcon",
  "UserIcon",
  "IoIosAttach",
  "IoIosSend",
  "AiOutlineDownload",
  "CheckIcon",
  "PencilIcon",
  "XMarkIcon",
  "PencilSquareIcon",
  "IoMdMegaphone",
  "HandThumbUpIconSolid",
  "CheckBadgeIcon",
  "BellAlertIcon",
  "MdOutlineVisibility",
  "InformationCircleIcon",
  "AiFillStar",
  "UsersIcon",
  "ChatBubbleOvalLeftIcon",
  "ClockIcon",
  "ExclamationCircleIcon",
  "UserCircleIcon",
  "ChatBubbleLeftRightIcon",
  "AiOutlineFile",
  "BsPhoneVibrate",
  "BsListCheck",
  "BsFiles",
  "BsPinMap",
  "BsTextIndentLeft",
  "WalletIcon",
  "BuildingOfficeIcon",
  "PhoneIcon",
  "BsCardChecklist",
  "GiBrokenWall",
  "GiSpill",
  "HandRaisedIcon",
  "BiDotsHorizontal",
  "RiChatPrivateLine",
  "MdOutlineUnpublished",
  "MdOutlineChecklist",
  "GiMineTruck",
  "VcsSettings",
  "MdList",
  "BsClipboardX",
  "BsFileEarmark",
  "BiDownload",
  "IoRefreshCircle",
  "EnvelopeIcon",
  "BsStar",
  "BsStarFill",
  "BsStarHalf",
  "EyeSlashIcon",
  "EyeIcon",
  "TbBellSearch",
  "TbBellPlus",
  "TbCalendarEvent",
  "IoLocationOutline",
  "TbGauge",
  "Type",
  "BiCommentError",
];

export type IconSelectProps = {
  value?: any;
  onChange?: (val: any) => void;
  className?: string;
};

function IconSelect({
  value,
  onChange = () => {},
  className,
}: IconSelectProps) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleConfirmModal = useCallback(() => {
    onChange(localValue);
    setShowModal(false);
  }, [localValue, onChange]);

  return (
    <div
      className={`w-10 h-10 border items-center justify-center rounded-md ${className}`}
      onClick={handleOpenModal}
    >
      <Modal
        visible={showModal}
        onCloseModal={handleCloseModal}
        onConfirmModal={handleConfirmModal}
      >
        <div
          className="grid grid-cols-8 w-full gap-4"
          style={{ height: "100%", overflowY: "scroll" }}
        >
          {iconNamesArray.map((icon) => {
            const isSelected = localValue === icon;
            return (
              <div
                className={`flex w-7 h-7 items-center p-1 hover:bg-slate-300 rounded-full transition-transform duration-150 ${
                  !isSelected && "hover:scale-[1.04]"
                } ${isSelected && "bg-primary-100 hover:bg-primary-100"}`}
                onClick={() => {
                  setLocalValue(icon);
                }}
              >
                <div className="flex flex-1">
                  <Icon name={icon} className="flex flex-1" />
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      {value ? (
        <div className="p-2">
          <Icon name={value} />
        </div>
      ) : null}
    </div>
  );
}

export default IconSelect;
