import React from "react";

function PollutionIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="408"
      height="400"
      version="1"
      viewBox="0 0 408 400"
      fill="currentColor"
      {...props}
    >
      <path
        d="M211.2,25.5c6.7,1.3,13.5,2.2,20,4c40.8,11.4,70.9,47.1,75.4,89.4c4.8,44.4-19.3,87-60,105.8c-2.6,1.2-3.2,2.6-3.3,5.3
	c-0.2,8.6,0.4,17.4-1.3,25.8c-4,19.7-22.7,32.9-42.4,31.1c-20-1.8-36.4-18.8-36.8-39c-0.4-18.6-0.4-37.3,0-55.9
	c0.4-20.9,18.2-37.9,39.6-38.5c11.2-0.3,20-6.7,23-16.8c2.7-9.3,0.4-17.4-6.6-23.9c-7.1-6.7-15.6-8.4-24.6-4.7
	c-8.8,3.6-13.9,10.3-14.7,20c-1.5,20-17.7,36.3-37.2,37.5c-20.9,1.2-38.9-12.6-42.5-32.8c-2.2-12.1,0-24.1,4.1-35.4
	c15.1-41.4,44.6-65.3,88.3-71.3c0.7-0.1,1.4-0.4,2.2-0.6C200,25.5,205.6,25.5,211.2,25.5z M182.7,220.1c0,8.2,0,16.4,0,24.6
	c0,2,0.1,4,0.4,6c2.3,10.2,11.4,16.9,21.5,16.1c10.2-0.9,18.1-9.3,18.3-20.2c0.2-8.1,0.1-16.2,0.1-24.3c0-8.4,1.5-10.6,9.3-13.5
	c37.9-14.1,61-54.3,53.1-93.8c-6.4-32.3-25.5-54.6-56.6-64.9c-30.9-10.2-59.2-3.5-83.3,18.4c-16.3,14.8-25.3,33.5-26.4,55.7
	c-0.5,11.2,7.9,20.7,18.6,21.2c11.4,0.6,20.8-7.6,21.5-19.1c1.7-26,27.2-45,52.6-39.3c21.4,4.9,36.6,25,34.5,45.8
	c-2.3,23.6-20,40.2-43.1,40.8c-12.2,0.3-20.5,8.7-20.6,21C182.7,203.3,182.7,211.7,182.7,220.1z"
      />
      <path
        d="M198.4,370.7c-5.3-2.4-11.2-4-15.9-7.4c-12-8.6-16.2-25-10.6-38.9c5.6-13.8,20-22.7,34.5-21c15.2,1.7,27.2,13,29.7,27.8
	c3.1,18.3-8.7,35.3-26.9,38.8c-0.5,0.1-1,0.4-1.5,0.6C204.7,370.7,201.5,370.7,198.4,370.7z M203.2,323.5
	c-7.4-0.1-13.5,5.9-13.6,13.4c-0.1,7.4,5.9,13.6,13.3,13.6c7.4,0.1,13.5-5.9,13.6-13.4C216.6,329.7,210.6,323.6,203.2,323.5z"
      />
    </svg>
  );
}

export default PollutionIcon;
