import Icon from "components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { Attendee } from "types/Attendee";
import EmptyResult from "../EmptyResult/EmptyResult";

interface IAttendees {
  attendees: Attendee[];
}

function Attendees({ attendees }: IAttendees) {
  const { t } = useTranslation();

  if (!attendees?.length) {
    return <EmptyResult text={t("forms.noAttendee")} />;
  }

  const list = attendees?.map(({ name }) => (
    <span
      key={name}
      className="flex gap-1 items-center border rounded-lg py-1 px-3"
    >
      <Icon name="UsersIcon" className="w-4 h-4" />
      {name}
    </span>
  ));
  return (
    <div className="flex flex-row flex-wrap gap-2 items-start">{list}</div>
  );
}

export default Attendees;
