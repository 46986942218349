import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Site } from "types";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import useAuth from "common/hooks/useAuth";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import getDefaultFilters from "common/utils/getDefaultFilters";
import useOrganization from "common/hooks/useOrganization";
import getWording from "common/utils/wording";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import AlertsContext from "common/providers/alerts";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import Select from "common/components/Select/Select";
import SiteItem from "./SiteItem";

function Sites() {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();
  const { organization: currentOrganization } = useOrganization();
  const canManageSites = currentUser?.isAdmin || currentUser?.isSuperadmin;

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<Site>("sites", {
    defaultFilters: {
      ...getDefaultFilters({
        currentOrganizationId: currentOrganization?.id,
        currentUser,
        sitesIdsPath: "id",
      }),
      active: true,
      mySites: currentUser?.isPro,
    },
    defaultSorts: ["siteType.id:ASC", "name:ASC"],
    populate: [
      "mainImage",
      "logo",
      "steps",
      "siteType",
      "siteUsers",
      "siteUsers.user",
      "threads",
      "parent",
      "adminContact",
    ],
    cachePrefix: `Sites_${currentOrganization?.id || "all"}`,
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const { removeItem } = useItem<Site>("sites", "");
  const handleDelete = useCallback(
    async (id: any) => {
      try {
        if (currentUser?.isSuperadmin) {
          removeListItem(id);
          await removeItem(id);
          fetchItems();
        }
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [
      currentUser?.isSuperadmin,
      removeListItem,
      removeItem,
      fetchItems,
      setAlert,
    ]
  );

  const handleNavigate = (id: any) => {
    navigate(`/sites/edit/${id}`);
  };

  const handleOpenThreads = (id: any) => {
    navigate(`/threads`, { state: { filterOnSite: id } });
  };

  /* const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]); */

  const siteTypesOptions = useMemo(() => {
    return (
      currentOrganization?.siteTypes?.map((type: any) => ({
        value: parseInt(type.id, 10),
        label: t(`sites.type_${type.name}`),
      })) || []
    );
  }, [currentOrganization?.siteTypes, t]);

  const columns = useMemo(
    () => [
      {
        Header: t("filters.siteName"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "w-1/4",
      },
      {
        Header: t("forms.type"),
        hidden: currentOrganization?.isMonoActivity,
        filterName: "siteType][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <Select isFilter options={siteTypesOptions} {...props} />
        ),
      },
      {
        Header: t("forms.parent", {
          wording: getWording(false, true),
        }),
        filterName: "parent][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <SiteSelect
            isFilter
            allowReadOnlyOptions
            filterOnParents
            filterOnActive={!!filters.active}
            Icon={WorkSiteIcon}
            {...props}
          />
        ),
      },
      {
        Header: t("forms.active"),
        filterName: "active",
        filterComponent: (props: any) => (
          <div className="pt-2">
            <ToggleSwitch rightLabel={t("forms.active")} {...props} />
          </div>
        ),
        columnClasses: "",
      },

      {
        Header: t("forms.mySites", { wording: getWording(true) }),
        filterName: "mySites",
        hidden: !currentUser?.isAdmin,
        filterComponent: (props: any) => (
          <div className="pt-2">
            <ToggleSwitch
              rightLabel={t("forms.mySites", { wording: getWording(true) })}
              {...props}
              /* onChange={(value: boolean) => {
                props.onChange(value ? currentUser?.id : false);
              }}
              value={Boolean(props.value)} */
            />
          </div>
        ),
        columnClasses: "",
      },
      /* {
        Header: t("filters.isSiteFinishedTitle"),
        headerClasses: "mb-3",
        filterName: "isFinished$contains",
        filterComponent: (props: any) => (
          <ToggleSwitch
            rightLabel={t("filters.isSiteFinishedLegend")}
            {...props}
          />
        ), 
      }, */
    ],
    [
      t,
      currentOrganization?.isMonoActivity,
      currentUser?.isAdmin,
      siteTypesOptions,
      filters.active,
    ]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.sites", {
          wording: getWording(true, true),
        })}
        subtitle={t("titleLegends.sites", {
          wording: getWording(true),
        })}
        btnTitle={t("newItem.site", {
          wording: getWording(false, false),
        })}
        // btnSubtitle={t("newItem.siteLegend")}
        Icon={WorkSiteIcon}
        addLink="/sites/add"
        addLinkDisabled={!canManageSites}
        isBeforeFilters
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={SiteItem}
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        onOpenThreads={handleOpenThreads}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Sites;
