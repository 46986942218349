import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useAuth from "common/hooks/useAuth";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import { isPasswordCorrect } from "common/components/Password/Password";
import useRouter from "common/hooks/use-router";
import EditHeader from "common/components/EditHeader/EditHeader";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import { Organization } from "types/Organization";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import RadioBlockItem from "common/components/RadioBlockItem/RadioBlockItem";
import rolesData from "conf/roleTypesData";
import pubRolesData from "conf/pubRoleTypesData";
import useOrganization from "common/hooks/useOrganization";
import getWording from "common/utils/wording";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import imgSizes from "conf/imgSizes.json";
import { checkEmail, checkPhoneFormat } from "common/utils/fieldsValidators";

function UserForm({ onSubmit, editedUser, myAccount }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();
  const { navigate } = useRouter();
  const [errorMessage, setErrorMessage] = useState("");
  const [changePassword, setChangePassword] = useState(false);

  const initialValues = useMemo(
    () =>
      editedUser?.id
        ? {
            ...editedUser,
            role: editedUser?.role?.id ?? 3,
            organizations: editedUser?.organizations?.map(
              (o: Organization) => o.id
            ),
            role_name: editedUser?.role?.name,
          }
        : {
            organizations: [organization?.id],
            name: undefined,
            password: undefined,
            role: 3,
            email: "",
            phoneNumber: undefined,
            blocked: false,
            confirmed: true,
            siteUsers: [],
            followedSites: [],
            avatar: null,
            notificationSettings: {
              postNew: true,
              threadNew: true,
              onlinePost: true,
              siteUpdate: true,
              validatePost: true,
              contactUpdate: true,
              voteInPostNew: true,
              activityUpdate: true,
              commentInPostNew: true,
              contactGroupUpdate: true,
              publicMessageInThreadNew: true,
              privateMessageInThreadNew: true,
              assignedOnOnly: false,
            },
          },
    [editedUser, organization]
  );

  const checkPassword = (value: string) =>
    isPasswordCorrect(value) ? undefined : t("common.passwordTooWeak");

  const validatePasswords = (values: any) => {
    if (values.password !== values.confirmPassword) {
      return { confirmPassword: t("common.resetPasswordError") };
    }
    return {};
  };

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    } else {
      setChangePassword(false);
    }
  };

  const displayRoleChoice =
    !myAccount &&
    (currentUser?.isSuperadmin ||
      (currentUser?.isAdmin &&
        !["SuperAdmin", "Public", "Authenticated"].includes(
          initialValues.role_name
        )));

  const displayNotificationSettings = !["Public", "Authenticated"].includes(
    initialValues.role_name
  );

  return (
    <Form
      onSubmit={onSubmitWrapper}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      validate={validatePasswords}
      render={({ handleSubmit, values }) => (
        <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
          {/* Header */}
          <EditHeader
            title={
              myAccount
                ? `${t("titlePages.myAccount")}`
                : t(`common.${editedUser?.id ? "edit" : "add"}`)
            }
            subtitle={values.name}
            icon="UsersIcon"
            errorMessage={errorMessage}
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={myAccount ? null : () => navigate("/users")}
              />
            }
          />
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FormScrollError />

            <div className="responsive-flex gap-2 mt-2">
              <div className="md:w-2/3 flex flex-col gap-2">
                <div className="white-box">
                  {displayRoleChoice && (
                    <>
                      <SectionTitle
                        icon="CalendarDaysIcon"
                        title={t("forms.role")}
                      />
                      <FormControl
                        type="custom"
                        name="role"
                        inputContainerStyle="flex gap-3 mb-4"
                        containerStyle="w-full"
                        editable={initialValues.role_name !== "Authenticated"}
                      >
                        <RadioBlock
                          Component={RadioBlockItem}
                          items={
                            initialValues.role_name === "Authenticated"
                              ? pubRolesData
                              : rolesData
                          }
                          horizontal
                        />
                      </FormControl>
                    </>
                  )}
                  <div className="responsive-flex gap-4">
                    {editedUser?.role?.name !== "Authenticated" && (
                      <div className="flex flex-col w-full md:w-[180px]">
                        <FormControl
                          title={t("forms.avatar")}
                          labelIcon="PhotoIcon"
                          name="avatar"
                          type="image"
                          maxWidth={imgSizes.avatar.width}
                          maxHeight={imgSizes.avatar.width}
                          keepRatio
                        />
                      </div>
                    )}

                    <div className="flex flex-col w-full gap-4">
                      {/* currentUser?.isAdmin && (
                        <FormControl
                          type="custom"
                          name="organizations"
                          titleKey={t("forms.organization")}
                          containerStyle="mb-4"
                          multiple
                        >
                          <OrganizationSelect />
                        </FormControl>
                      ) */}

                      <FormControl
                        name="name"
                        titleKey={t("forms.name")}
                        required
                      />

                      {(currentUser?.isAdmin || currentUser?.isSuperadmin) && (
                        <FormControl type="custom" name="confirmed">
                          <ToggleSwitch
                            rightLabel={
                              values.confirmed
                                ? t("forms.confirmed")
                                : t("forms.unconfirmed")
                            }
                          />
                        </FormControl>
                      )}

                      {!!editedUser?.id && (
                        <ToggleSwitch
                          rightLabel={t("forms.newPassword")}
                          value={changePassword}
                          onChange={setChangePassword}
                        />
                      )}

                      {(changePassword || !editedUser?.id) && (
                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                          <FormControl
                            type="jpassword"
                            name="password"
                            titleKey={t("forms.password")}
                            helpText={t("common.passwordInstruction")}
                            required
                            validate={checkPassword}
                          />
                          <FormControl
                            name="confirmPassword"
                            title={t("forms.confirmPassword")}
                            type="password"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {displayNotificationSettings && (
                  <div className="white-box">
                    <SectionTitle
                      title={t("forms.notificationsSettings")}
                      className="mb-4 text-lg normal-case"
                    />
                    <div className="grid-1or2 xl- gap-2">
                      <div className="flex w-full flex-col gap-4">
                        <SectionTitle
                          title={t("titlePages.posts")}
                          className="mb-1"
                        />
                        <FormControl
                          type="custom"
                          name="notificationSettings.postNew"
                        >
                          <ToggleSwitch
                            rightLabel={t("notificationSettings.postNew")}
                          />
                        </FormControl>

                        <FormControl
                          type="custom"
                          name="notificationSettings.commentInPostNew"
                        >
                          <ToggleSwitch
                            rightLabel={t(
                              "notificationSettings.commentInPostNew"
                            )}
                          />
                        </FormControl>

                        <FormControl
                          type="custom"
                          name="notificationSettings.voteInPostNew"
                        >
                          <ToggleSwitch
                            rightLabel={t("notificationSettings.voteInPostNew")}
                          />
                        </FormControl>

                        <FormControl
                          type="custom"
                          name="notificationSettings.validatePost"
                        >
                          <ToggleSwitch
                            rightLabel={t("notificationSettings.validatePost")}
                          />
                        </FormControl>

                        <FormControl
                          type="custom"
                          name="notificationSettings.onlinePost"
                        >
                          <ToggleSwitch
                            rightLabel={t("notificationSettings.onlinePost")}
                          />
                        </FormControl>
                      </div>
                      <div className="flex w-full flex-col gap-4">
                        <SectionTitle
                          title={t("titlePages.threads")}
                          className="mb-1 mt-4 xl:mt-0"
                        />

                        <FormControl
                          type="custom"
                          name="notificationSettings.threadNew"
                        >
                          <ToggleSwitch
                            rightLabel={t("notificationSettings.threadNew")}
                          />
                        </FormControl>

                        <FormControl
                          type="custom"
                          name="notificationSettings.privateMessageInThreadNew"
                        >
                          <ToggleSwitch
                            rightLabel={t(
                              "notificationSettings.privateMessageInThreadNew"
                            )}
                          />
                        </FormControl>

                        <FormControl
                          type="custom"
                          name="notificationSettings.publicMessageInThreadNew"
                        >
                          <ToggleSwitch
                            rightLabel={t(
                              "notificationSettings.publicMessageInThreadNew"
                            )}
                          />
                        </FormControl>

                        <FormControl
                          type="custom"
                          name="notificationSettings.assignedOnOnly"
                        >
                          <ToggleSwitch
                            rightLabel={t(
                              "notificationSettings.assignedOnOnly"
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="flex w-full flex-col gap-4 mt-4">
                        <SectionTitle
                          title={t(
                            "notificationSettings.notificationsForUpdates"
                          )}
                          className="mb-1"
                        />
                        <FormControl
                          type="custom"
                          name="notificationSettings.activityUpdate"
                        >
                          <ToggleSwitch
                            rightLabel={t(
                              "notificationSettings.activityUpdate"
                            )}
                          />
                        </FormControl>
                        <FormControl
                          type="custom"
                          name="notificationSettings.siteUpdate"
                        >
                          <ToggleSwitch
                            rightLabel={t("notificationSettings.siteUpdate", {
                              wording: getWording(),
                            })}
                          />
                        </FormControl>
                        <FormControl
                          type="custom"
                          name="notificationSettings.contactUpdate"
                        >
                          <ToggleSwitch
                            rightLabel={t("notificationSettings.contactUpdate")}
                          />
                        </FormControl>
                        <FormControl
                          type="custom"
                          name="notificationSettings.contactGroupUpdate"
                        >
                          <ToggleSwitch
                            rightLabel={t(
                              "notificationSettings.contactGroupUpdate"
                            )}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full md:w-1/3">
                <div className="white-box">
                  <div className="w-full">
                    <SectionTitle
                      title={t("forms.coordinates")}
                      className="mb-4 text-lg normal-case"
                    />

                    <FormControl
                      name="email"
                      titleKey={t("forms.email")}
                      required
                      validate={(val: string) => checkEmail(val, t)}
                      containerStyle="mb-4"
                    />

                    <FormControl
                      name="phoneNumber"
                      titleKey={t("forms.phoneNumber")}
                      validate={(val: string) =>
                        !val ? undefined : checkPhoneFormat(val, t)
                      }
                      containerStyle="mb-4"
                    />
                  </div>
                </div>
              </div>
            </div>

            <HeaderButtonsEdit
              onSubmit={handleSubmit}
              onCancel={myAccount ? null : () => navigate("/users")}
            />
          </form>
        </div>
      )}
    />
  );
}
export default UserForm;
