const getFlattenText = (richtext: any) => {
  const htmlString = richtext;
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  tempElement.innerHTML = tempElement.innerHTML.replace(/<br>/g, ". ");
  tempElement.innerHTML = tempElement.innerHTML.replace(/<[^>]*>/g, " ");
  const extractedText = tempElement.textContent || tempElement.innerText;
  return extractedText;
};

export default getFlattenText;
