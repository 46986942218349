import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import useRouter from "common/hooks/use-router";
import useAuth from "common/hooks/useAuth";

import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import { Organization } from "types/Organization";
import AlertsContext from "common/providers/alerts";
import OrganizationItem from "./OrganizationItem";

function Organizations() {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();
  const canManageOrganizations = currentUser?.isSuperadmin;

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<Organization>("organizations", {
    defaultFilters: {},
    defaultSort: "name:ASC",
    cachePrefix: "Organizations_all",
  });
  const { removeItem } = useItem<Organization>("organizations", "");

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const handleNavigate = (id: any) => {
    navigate(`/organizations/edit/${id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t("forms.name"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "w-1/2",
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.organizations")}
        subtitle={t("titleLegends.organizations")}
        btnTitle={t("newItem.organization")}
        btnSubtitle={t("newItem.organizationLegend")}
        Icon={BuildingOfficeIcon}
        addLink="/organizations/add"
        addLinkDisabled={!canManageOrganizations}
        isBeforeFilters
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={OrganizationItem}
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Organizations;
