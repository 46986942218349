import React from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { JobPosition } from "types/JobPosition";
import useOrganization from "common/hooks/useOrganization";

const processOptions = (options: JobPosition[]) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function JobPositionSelect(props: any) {
  const { organization } = useOrganization();
  return (
    <RemoteSelect
      url="job-positions"
      processOptions={processOptions}
      filters={[{ name: "organization", value: organization?.id }]}
      {...props}
    />
  );
}

export default JobPositionSelect;
