import { useTranslation } from "react-i18next";
import { BellIcon, EyeIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import { Contact } from "types";
import { ContactGroup } from "types/ContactGroup";
import Button from "common/components/Button/Button";
import truncateString from "common/utils/truncateString";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import useAuth from "common/hooks/useAuth";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import checkRights from "common/utils/checkRights";
import useWindowDimensions from "common/utils/Dimensions";
import getWording from "common/utils/wording";

function ContactGroupItem({ item, onDelete, onNavigate }: any) {
  const { name, type, contacts, id, appliedToTheWholeOrganization } =
    item as ContactGroup;

  const { user: currentUser } = useAuth();
  const { t } = useTranslation();

  const canEditItem =
    checkRights(
      currentUser,
      "canManageContact",
      item && item?.site ? [item?.site?.id] : undefined,
      ["isAdmin"]
    ) ||
    (appliedToTheWholeOrganization &&
      currentUser &&
      ["isSuperadmin", "isAdmin"].find(
        (jr: string) => (currentUser as any)[jr] === true
      ));

  const detailLink = `/contact-groups/${id}`;

  const sortedContacts = contacts.sort((a, b) => {
    return Boolean(a.avatar) === Boolean(b.avatar) ? 0 : a.avatar ? -1 : 1;
  });

  const { width: windowWidth } = useWindowDimensions();

  return (
    <div className="responsive-flex-lg px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link to={detailLink} className="responsive-flex-lg flex-1">
        <div
          className={` mb-2 lg:mb-0 ${
            item?.site?.logo || item?.site?.mainImage
              ? "lg:border-r lg:mr-2 lg:pr-3"
              : ""
          }`}
        >
          <div className="flex relative ">
            {item?.site && (
              <div className="rounded-lg overflow-hidden w-16 min-w-[4rem] mr-2 lg:mr-0 h-16">
                <ImageComponent
                  image={
                    item.site?.logo || item.site?.mainImage
                      ? ImageFromStrapiMedia(
                          item.site.logo ?? item.site.mainImage
                        )?.uri
                      : undefined
                  }
                />
              </div>
            )}

            <div
              className={`flex lg:hidden relative w-full min-w-full ${
                sortedContacts?.length > 1 ? "gap-2" : ""
              }`}
            >
              {sortedContacts?.map((c: Contact, index: number) => {
                const limit = windowWidth < 768 ? 3 : 4;
                return index < limit ? (
                  <div className="rounded-full lg:rounded-lg overflow-hidden w-16 h-16">
                    <ImageComponent
                      image={
                        c.avatar
                          ? ImageFromStrapiMedia(c.avatar).uri
                          : undefined
                      }
                    />
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </div>

        <div
          className={`hidden lg:grid relative w-32 h-32 ${
            sortedContacts?.length > 1 ? "lg:grid-cols-2 gap-2" : ""
          }`}
        >
          {sortedContacts?.map((c: Contact, index: number) => {
            return index < 4 ? (
              <div
                className={`rounded-full lg:rounded-lg overflow-hidden ${
                  sortedContacts.length > 2 ? "h-[3.75rem]" : ""
                }`}
              >
                <ImageComponent
                  image={
                    c.avatar ? ImageFromStrapiMedia(c.avatar).uri : undefined
                  }
                />
              </div>
            ) : null;
          })}
        </div>
        <div className="flex flex-col flex-1 justify-start lg:px-6">
          <div className="flex gap-3">
            <div className="flex-1">
              <div className="flex flex-row justify-between">
                <div>
                  <h4 className="text-xl font-bold text-primary leading-6">
                    {name}
                  </h4>
                  <div className="flex items-center gap-1 text-sm mt-1">
                    <WorkSiteIcon className="w-4 h-4 text-slate-400" />
                    <span className="font-bold">
                      {item?.site?.name ||
                        t("titleLegends.forAllSites", {
                          wording: getWording(true, false),
                        })}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  <div className="flex items-center gap-2">
                    <EyeIcon
                      height={20}
                      width={20}
                      className={
                        item.targetWithoutSubscription
                          ? "text-emerald-600"
                          : "text-slate-400"
                      }
                    />
                    <span
                      className={`${
                        item.targetWithoutSubscription
                          ? "font-bold text-slate-900"
                          : ""
                      } text-sm`}
                    >
                      {item.targetWithoutSubscription
                        ? t("forms.targetWithoutSubscriptionEnabled")
                        : t("forms.targetWithoutSubscriptionDisabled")}
                    </span>
                  </div>

                  <div className="flex items-center gap-2">
                    <BellIcon
                      height={20}
                      width={20}
                      className={
                        item.notifyWithoutUser
                          ? "text-emerald-600"
                          : "text-slate-400"
                      }
                    />
                    <span
                      className={`${
                        item.notifyWithoutUser ? "font-bold text-slate-900" : ""
                      } text-sm`}
                    >
                      {item.notifyWithoutUser
                        ? t("forms.notifyWithoutUserEnabled")
                        : t("forms.notifyWithoutUserDisabled")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col  gap-2 pt-2 mt-2 border-t border-slate-200">
                <div className="text-sm text-gray-500">
                  {truncateString(type, 150)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-between items-end text-xs lg:border-l pl-4 lg:min-w-[22em] border-slate-150 gap-2">
        <div className="flex items-center gap-1 text-sm">
          <UserGroupIcon className="w-3 h-3" />
          <span className="font-bold">
            {t("forms.contactsLength", { count: contacts?.length || 0 })}
          </span>
        </div>
        <div className="flex w-full items-center justify-end text-slate-500 gap-1">
          <Button
            type="error-line"
            icon="TrashIcon"
            compact
            onClick={() => onDelete(item.id)}
            disabled={!canEditItem}
            confirmMessage={t("actions.confirmDelete")}
          />

          <Button
            title={t("actions.edit")}
            type="warning-line"
            icon="PencilSquareIcon"
            onClick={() => onNavigate(item.id)}
            disabled={!canEditItem}
          />
        </div>
      </div>
    </div>
  );
}

export default ContactGroupItem;
