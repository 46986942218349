import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Tag } from "types/Tag";

const processOptions = (options: Tag[]) =>
  options.map(({ id, label }) => ({
    value: id,
    label,
  }));

function TagSelect(props: any) {
  return (
    <RemoteSelect
      url="tags"
      processOptions={(options) => processOptions(options)}
      {...props}
    />
  );
}

export default TagSelect;
