import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AlertsContext from "common/providers/alerts";
import SiteUserList from "components/SiteUserList/SiteUserList";
import { Contact as ContactType } from "types";
import ContactForm from "./ContactForm";

function Contact() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any =
    useItem<ContactType>("contacts", id, {
      populate: ["avatar", "siteUsers"],
    });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/contacts");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataContact: any) => {
      const contact = { ...dataContact };

      try {
        if (contact.id) {
          await updateItem(contact.id, contact);
        } else {
          await saveItem(contact);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <>
      <ContactForm onSubmit={onSubmit} contact={item} />
      {Boolean(item?.siteUsers?.length) && <SiteUserList contact={item} />}
    </>
  );
}

export default Contact;
