import { User } from "types";

interface IgetDefaultFilters {
  currentOrganizationId?: any;
  currentUser?: User;
  sitesIdsPath?: string;
}
const getDefaultFilters = ({
  currentOrganizationId,
  currentUser,
  sitesIdsPath,
}: IgetDefaultFilters) => {
  const organizationFilter = currentOrganizationId
    ? { organization: currentOrganizationId }
    : {};
  if (currentUser?.isSuperadmin) {
    return organizationFilter;
  }
  if (!sitesIdsPath) {
    return organizationFilter;
  }
  return {
    ...organizationFilter,
  };
};

export default getDefaultFilters;
