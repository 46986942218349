import SectionTitle from "common/components/SectionTitle/SectionTitle";
import Select from "common/components/Select/Select";
import getWording from "common/utils/wording";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import fetchJSON from "common/utils/fetchJSON";
import {
  format,
  isFirstDayOfMonth,
  startOfYear,
  startOfMonth,
  subMonths,
} from "date-fns";
import { fr as french } from "date-fns/locale";
import Icon from "components/Icon/Icon";
import DateRangePicker from "common/components/DatePicker/DateRangePicker";
import PostSelect from "components/select/PostSelect/PostSelect";
import useOrganization from "common/hooks/useOrganization";

export default function PostStatistics() {
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});
  const [, setIsFetching] = useState<boolean>(false);

  const maxDate = new Date();
  const defaultStartDate = startOfMonth(subMonths(maxDate, 1));
  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultStartDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(maxDate);

  const [step, setStep] = useState("day");
  const [post, setPost] = useState(undefined);
  const { organization } = useOrganization();

  const buildUrl = useMemo(() => {
    if (startDate && endDate && organization) {
      if (post) {
        return `posts/stats/${organization.id}/${post}/${format(
          startDate as Date,
          "yyyy-MM-dd"
        )}/${format(endDate as Date, "yyyy-MM-dd")}/${step}`;
      }
      return `posts/stats/${organization.id}/${format(
        startDate as Date,
        "yyyy-MM-dd"
      )}/${format(endDate as Date, "yyyy-MM-dd")}/${step}`;
    }
    return null;
  }, [startDate, endDate, post, step, organization]);

  useEffect(() => {
    if (buildUrl) {
      const getData = async () => {
        setIsFetching(true);
        const res = await fetchJSON({
          url: buildUrl,
          method: "GET",
        });
        setIsFetching(false);
        setData(res);
      };

      getData();
    }
  }, [buildUrl]);

  const stats = useMemo(
    () => [
      {
        name: t("stats.nbPosts", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.posts ? data.general.posts : 0,
      },
    ],
    [data, t]
  );

  const postStats = useMemo(
    () => [
      {
        name: t("stats.nbNotifications"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.nbNotifications
            ? data.general.nbNotifications
            : 0,
      },
      {
        name: t("stats.nbEmails", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.nbEmails
            ? data.general.nbEmails
            : 0,
      },
      {
        name: t("stats.nbLike"),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.general && data.general.nbLike ? data.general.nbLike : 0,
      },
    ],
    [data, t]
  );

  return (
    <>
      <div className="mt-4 white-box">
        <SectionTitle title={t(`stats.selectFilters`)} className="text-lg" />
        <div className="grid grid-cols-3 gap-8 mt-2">
          <div>
            <SectionTitle
              title={t(`titlePages.posts`, { wording: getWording() })}
              icon="WorkSiteIcon"
              className="text-base font-normal"
            />
            <PostSelect value={post} onChange={setPost} />
          </div>
          <div>
            <SectionTitle
              title={t(`stats.selectDateFrom`, {
                wording: getWording(),
              })}
              icon="ClockIcon"
              className="text-base font-normal"
            />
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartChange={setStartDate}
              onEndChange={setEndDate}
            />
          </div>
          <div>
            <SectionTitle
              title={t(`stats.selectInterval`, { wording: getWording() })}
              icon="CalendarDaysIcon"
              className="text-base font-normal"
            />
            <Select
              value={step}
              onChange={setStep}
              options={[
                { value: "month", label: t("stats.month") },
                { value: "week", label: t("stats.week") },
                { value: "day", label: t("stats.day") },
              ]}
              clearable={false}
            />
          </div>
        </div>
      </div>
      <dl className="mt-3 grid md:grid-flow-col gap-3 mb-3">
        {!post
          ? stats.map((item) => (
              <div
                key={item?.name}
                className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    {item?.icon}
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    {item?.name}
                  </p>
                </dt>
                <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                  <p className="text-2xl font-semibold text-gray-900">
                    {item?.stat}
                  </p>
                </dd>
              </div>
            ))
          : postStats.map((item) => (
              <div
                key={item?.name}
                className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-indigo-500 rounded-md p-3">
                    {item?.icon}
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    {item?.name}
                  </p>
                </dt>
                <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
                  <p className="text-2xl font-semibold text-gray-900">
                    {item?.stat}
                  </p>
                </dd>
              </div>
            ))}
      </dl>
      <div className="flex flex-row gap-2 h-full">
        {!post && (
          <div className="w-full white-box">
            <Chart
              options={{
                chart: {
                  id: "nbPostByTypes",
                  stacked: true,
                },
                title: {
                  text: t("stats.nbPostByTypes"),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                labels:
                  data && data.general && data.general.nbPostByTypes
                    ? Object.keys(data.general.nbPostByTypes).map(
                        (type) => type
                      )
                    : [],
                plotOptions: {
                  pie: {
                    expandOnClick: false,
                  },
                },
              }}
              series={
                data && data.general && data.general.nbPostByTypes
                  ? (Object.values(data.general.nbPostByTypes) as number[])
                  : []
              }
              type="pie"
            />
          </div>
        )}
      </div>
    </>
  );
}
