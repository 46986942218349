import { Link } from "react-router-dom";

import Button from "common/components/Button/Button";
import { useTranslation } from "react-i18next";

interface IHeaderButtons {
  onDelete?: () => void;
  editUrl?: string;
  likeCount?: number;
  hasLiked?: boolean;
  onLike?: () => void;
  onValidate?: () => void;
  onUnpublish?: () => void;
  deleteDisabled?: boolean;
  validateDisabled?: boolean;
  unpublishDisabled?: boolean;
  editDisabled?: boolean;
  isPublishing?: boolean;
  isLiking?: boolean;
  likeDisabled?: boolean;
}

function HeaderButtons({
  onDelete,
  editUrl,
  likeCount,
  hasLiked,
  onLike,
  onValidate,
  onUnpublish,
  deleteDisabled,
  validateDisabled,
  unpublishDisabled,
  likeDisabled,
  editDisabled,
  isPublishing,
  isLiking,
}: IHeaderButtons) {
  const { t } = useTranslation();
  return (
    <div className="responsive-flex-lg gap-1 lg:justify-end w-full mt-3 lg:mt-0 items-start lg:items-end">
      {onLike && !likeDisabled && (
        <Button
          title={`| ${likeCount}`}
          type={hasLiked ? "primary" : "primary-line"}
          icon="HandThumbUpIconSolid"
          onClick={onLike}
          loading={isLiking}
          // compact
          disabled={likeDisabled}
        />
      )}
      {/* modo actions */}
      {onDelete && !deleteDisabled ? (
        <Button
          onClick={onDelete}
          type="error-line"
          icon="TrashIcon"
          // compact
          confirm
          title={t("actions.delete")}
        />
      ) : null}
      {onValidate && !validateDisabled && (
        <Button
          title={t("actions.validate")}
          type="valid-line"
          icon="CheckIcon"
          onClick={onValidate}
          loading={isPublishing}
          disabled={validateDisabled}
        />
      )}
      {onUnpublish && !unpublishDisabled && (
        <Button
          title={t("actions.unpublish")}
          type="error-line"
          icon="MdOutlineUnpublished"
          onClick={onUnpublish}
          loading={isPublishing}
          disabled={unpublishDisabled}
        />
      )}

      {/* writers actions */}
      {editUrl && !editDisabled && (
        <Link to={editUrl} className="flex w-full lg:w-auto">
          <Button
            title={t("actions.edit")}
            type="warning-line"
            icon="PencilSquareIcon"
          />
        </Link>
      )}
    </div>
  );
}

export default HeaderButtons;
