import { Site } from "types";
import { useTranslation } from "react-i18next";
import Select from "common/components/Select/Select";
import { useMemo } from "react";

export type ProcessOptionsType = (options: Site[]) => {
  value: string;
  label: string;
}[];

function RoleSelect(props: any) {
  const { t } = useTranslation();

  const optionsType = props.optionsType || "adminOptions";

  const superadminOptions = useMemo(
    () => [
      // { value: "Authenticated", label: t("roles.Authenticated") },
      { value: "Pro", label: t("roles.Pro") },
      { value: "Administrateur", label: t("roles.Administrateur") },
      { value: "SuperAdmin", label: t("roles.SuperAdmin") },
    ],
    [t]
  );

  const adminOptions = useMemo(
    () => [
      { value: "Pro", label: t("roles.Pro") },
      { value: "Administrateur", label: t("roles.Administrateur") },
    ],
    [t]
  );

  return (
    <Select
      options={
        optionsType === "adminOptions" ? adminOptions : superadminOptions
      }
      icon="StarIcon"
      {...props}
    />
  );
}

export default RoleSelect;
