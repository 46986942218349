import React, { useState, useCallback, useMemo } from "react";
import debounce from "lodash/debounce";
import classNames from "classnames";

export type Props = {
  value?: string;
  onChange?: (val: any) => void;
  className?: string;
  debounceTimeout?: number;
  placeholder?: string;
  type?: string;
  error?: boolean;
  onPressEnterKey?: () => void;
};

function DebounceInput({
  value,
  onChange,
  debounceTimeout = 300,
  type = "text",
  className,
  error,
  onPressEnterKey,
  ...rest
}: Props) {
  const [localValue, setValue] = useState(value);

  const setDebouncedChange = useMemo(
    () =>
      onChange
        ? debounce((val) => {
            onChange(val);
            setValue(undefined);
          }, debounceTimeout)
        : null,
    [onChange, debounceTimeout]
  );

  const handleInputChange = useCallback(
    (e: any) => {
      setValue(e.target.value);
      if (setDebouncedChange) setDebouncedChange(e.target.value);
    },
    [setDebouncedChange]
  );

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (onPressEnterKey) onPressEnterKey();
    }
  };

  return (
    <input
      className={classNames(error ? "error-input" : "text-input", className)}
      type={type}
      value={localValue ?? (value || "")}
      onChange={handleInputChange}
      autoComplete="new-password"
      onKeyDown={handleKeyDown}
      {...rest}
    />
  );
}

export default DebounceInput;
