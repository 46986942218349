import useFetch from "common/hooks/useFetch";
import { useEffect, useState } from "react";
import { parseStrapiFormat } from "../../utils/strapi";
import Select, { Props as SelectProps } from "../Select/Select";

// filters: ['paramKey']

const postExecute = (response: any) => {
  return parseStrapiFormat(response);
};

export type RemoteSelectProps = {
  onDataLoaded?: (value: any) => void;
  url?: string;
  filters?: any;
  sorts?: string[];
  populate?: any;
  processOptions?: (value: any) => void;
  additionnalOptions?: any;
  multiple?: boolean;
  alsoOnChange?: () => void;
} & SelectProps;

export function RemoteSelect({
  onDataLoaded = () => {},
  url,
  filters = [],
  sorts = [],
  populate = [],
  processOptions = () => {},
  additionnalOptions = () => {},
  multiple = false,
  alsoOnChange,
  ...rest
}: RemoteSelectProps) {
  const [options, setOptions] = useState<any>([]);
  const [params, setParams] = useState<any>({});

  let paramsUpdated = false;
  const newParams: any = {};

  filters.forEach((f: any) => {
    // old : newParams[f] = (rest as any)[f];
    newParams[`filters[${f.name}]`] = f.value;
    if (newParams[`filters[${f.name}]`] !== params[`filters[${f.name}]`]) {
      paramsUpdated = true;
    }
  });

  populate.forEach((p: any, index: number) => {
    newParams[`populate[${index}]`] = p;
    if (newParams[`populate[${index}]`] !== params[`populate[${index}]`]) {
      paramsUpdated = true;
    }
  });

  sorts?.forEach((s: any) => {
    newParams.sort = s;
    if (newParams.sort !== params.sort) {
      paramsUpdated = true;
    }
  });

  if (paramsUpdated) {
    setParams(newParams);
  }

  const getUrl = () => {
    const esc = encodeURIComponent;
    const fullparams = { ...params };
    fullparams[`pagination[limit]`] = 5000;
    const queryParams = Object.keys(fullparams)
      .filter((key) => fullparams[key] != null)
      .map((key) => `${esc(key)}=${esc(fullparams[key])}`)
      .join("&");

    return `${url}${queryParams ? "?" : ""}${queryParams}`;
  };

  const { data, isFetching }: any = useFetch(getUrl(), {
    postExecute,
    sharePromise: true,
  });

  useEffect(() => {
    let newData = data || [];

    if (onDataLoaded) {
      onDataLoaded(newData);
    }

    if (additionnalOptions.length && Array.isArray(newData)) {
      newData = [...additionnalOptions, ...newData];
    }

    const opts = processOptions ? processOptions(newData) : newData;

    if (JSON.stringify(options) !== JSON.stringify(opts)) {
      setOptions(opts);
    }
  }, [
    additionnalOptions,
    data,
    onDataLoaded,
    options,
    processOptions,
    setOptions,
  ]);

  return (
    <Select
      options={options}
      multiple={multiple}
      isLoading={isFetching}
      alsoOnChange={alsoOnChange}
      {...rest}
    />
  );
}

export default RemoteSelect;
