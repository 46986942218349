interface IPublicationHeaderSubTitle {
  siteName: string;
  location: string;
}

function PublicationHeaderSubTitle({
  siteName,
  location,
}: IPublicationHeaderSubTitle) {
  return (
    <>
      <span className="font-bold">{siteName}</span>
      {location && <span> | {location}</span>}
    </>
  );
}

export default PublicationHeaderSubTitle;
