// import Loader from 'common/components/Loader/Loader';
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useOrganization from "common/hooks/useOrganization";
import useAuth from "common/hooks/useAuth";
import OrganizationForm from "./OrganizationForm";

function Organization() {
  const { t } = useTranslation();
  const router = useRouter();
  const { user: currentUser } = useAuth();
  const { organization: currentOrganization } = useOrganization();
  const { refreshOrganization } = useOrganization();
  const { setAlert } = useContext(AlertsContext);

  const id = currentOrganization?.id || (router.query.id as string);

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    "organizations",
    id,
    {
      populate: [
        "threadTypes",
        "siteTypes",
        "allowedGroups",
        "applicationLogo",
      ],
    }
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate(
        currentUser?.isSuperadmin && !currentOrganization?.id
          ? "/organizations"
          : "/"
      );
    }
  }, [setAlert, router, error, currentUser, currentOrganization, t]);

  const onSubmit = useCallback(
    async (dataOrganization: any) => {
      const newOrganization = { ...dataOrganization };

      try {
        if (newOrganization.id) {
          await updateItem(newOrganization.id, newOrganization);
          await refreshOrganization(newOrganization.id);
        } else {
          await saveItem(newOrganization);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [updateItem, back, saveItem, refreshOrganization]
  );

  return isFetching || (id && !item) ? null : (
    <OrganizationForm onSubmit={onSubmit} organization={item} />
  );
}

export default Organization;
