/* eslint-disable react/no-array-index-key */
import DebounceInput from "common/components/DebounceInput/DebounceInput";
import React, {
  HTMLAttributes,
  ClassAttributes,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { usePagination, useSortBy, useTable } from "react-table";
import "react-activity/dist/library.css";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import useRouter from "common/hooks/use-router";
import Pagination from "./Pagination";
import PageLoading from "../PageLoading/PageLoading";
import StickyWrapper from "../StickyWrapper/StickyWrapper";
// import Loader from '../Loader/Loader';

type Props = {
  data: any[];
  columns: any[];
  isFetching: boolean;
  pageCount: number;
  itemsPerPage?: number;
  itemsCount?: number;
  filters: { [key: string]: any };
  setFilter: (filter: any, value: any) => void;
  setPageIndex: (pageIndex: number) => void;
  pageIndex: number;
  RenderItem: any;
  RenderEmptyBlock?: any;
  onDelete: any;
  onNavigate: any;
  onDuplicate?: any;
  onPin?: any;
  onResendNotification?: any;
  onOpenThreads?: any;
  noHeader?: boolean;
  backLink?: string;
  pinnedSection?: boolean;
  leftColumnItems?: any[] | null;
  leftColumnAddLink?: string;
  leftColumnEditLink?: string;
  leftColumnTitle?: string;
  leftColumnItemLabelKey?: string;
};

function PinnedTopSectionComponent() {
  return (
    <div className="text-secondary-400 border-b-2 border-secondary-400 mb-4 font-bold">
      Section épinglée
    </div>
  );
}

function PinnedBottomSectionComponent() {
  return <div className="h-[2px] w-full bg-secondary-400 mt-4 mb-4" />;
}

function FilteredList({
  data,
  columns,
  isFetching,
  pageCount: controlledPageCount,
  itemsCount = 0,
  itemsPerPage = 0,
  filters,
  setFilter,
  pageIndex,
  setPageIndex,
  RenderItem,
  RenderEmptyBlock,
  onDelete,
  onNavigate,
  onDuplicate,
  onPin,
  onResendNotification,
  onOpenThreads,
  noHeader = false,
  backLink,
  pinnedSection = false,
  leftColumnItems,
  leftColumnAddLink,
  leftColumnEditLink,
  leftColumnTitle,
  leftColumnItemLabelKey = "label",
}: Props) {
  const { t } = useTranslation();
  const {
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex: currentPageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      initialState: { pageIndex },
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );
  const { navigate } = useRouter();

  useEffect(() => {
    if (pageIndex !== currentPageIndex) {
      setPageIndex(currentPageIndex);
    }
  }, [currentPageIndex, pageIndex, setPageIndex]);

  const handleChangeFilter = useCallback(
    (name: any, value: any) => {
      // TODO ca va faire deux fetchData, mais super compliqué a optimiser,
      // il faut un debounce mais aussi modifier le fetchData pour que la fonction ne change jamais
      gotoPage(0);
      setFilter(name, value);
    },
    [gotoPage, setFilter]
  );

  const emptyPage = !isFetching && !page.length;

  return (
    <div className="flex flex-col relative min-h-[300px]">
      {!noHeader && (
        <StickyWrapper isFilters disabledOnMobile>
          <div
            className=" flex w-full
      justify-center px-4 py-3 border border-t-0 border-slate-150 rounded-b-lg mb-2 z-10 bg-slate-100"
          >
            {headerGroups.map(
              (
                headerGroup: {
                  getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                    ClassAttributes<HTMLTableRowElement> &
                    HTMLAttributes<HTMLTableRowElement>;
                  headers: any[];
                },
                index: any
              ) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  key={`header-${index}`}
                  className="w-full flex flex-col gap-4 lg:flex-row lg:flex-wrap align-center"
                >
                  {backLink && (
                    <Link
                      to={backLink}
                      className="flex justify-center items-center w-8 h-8 duration-200 ease-in-out relative top-1 right-0 hover:right-2"
                    >
                      <ArrowLeftIcon />
                    </Link>
                  )}
                  {headerGroup.headers.map((column: any, key: any) =>
                    column.hidden ? null : (
                      <div
                        key={`column-${key}`}
                        className={`py-0 flex flex-col ${column.columnClasses} maxLg:w-full`}
                      >
                        {/* <span
                          className={`text-xxs text-secondary uppercase ${
                            column.headerClasses || "mb-1"
                          }`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <i className="ml fas fa-sort-down" />
                            ) : (
                              <i className="ml fas fa-sort-up" />
                            ))}
                            </span> */}
                        {column.filterName &&
                          (column.filterComponent ? (
                            <column.filterComponent
                              placeholder={column.render("Header")}
                              value={filters[column.filterName]}
                              data-cy={column.filterName}
                              onChange={(value: any) => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                column.customFormat
                                  ? handleChangeFilter(
                                      column.filterName,
                                      column.customFormat(value)
                                    )
                                  : handleChangeFilter(
                                      column.filterName,
                                      value
                                    );
                              }}
                            />
                          ) : (
                            <DebounceInput
                              className="text-input-filter"
                              // placeholder={column.filterLabel}
                              placeholder={column.render("Header")}
                              value={filters[column.filterName]}
                              onChange={(value) =>
                                handleChangeFilter(column.filterName, value)
                              }
                            />
                          ))}
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </div>
        </StickyWrapper>
      )}

      <div className="w-full relative">
        {emptyPage ? (
          RenderEmptyBlock ? (
            <RenderEmptyBlock />
          ) : (
            <div className="table-no-result white-box mt-4 p-4 lg:p-8 flex flex-col justify-center items-center gap-3">
              <p className="text-lg text-center">{t("common.noResult")}</p>
            </div>
          )
        ) : null}
        {leftColumnItems && !emptyPage && !isFetching ? (
          <div className="w-full flex flex-col xl:flex-row gap-4">
            <div className="flex flex-col md:flex-[1] max-w-[300px]">
              <div className="flex flex-row gap-4 mt-4 mb-2">
                <span className="font-bold text-xl text-black">
                  {leftColumnTitle}
                </span>
                <div
                  className="flex w-6 h-6 rounded-full bg-green-400 items-center justify-center cursor-pointer"
                  onClick={() => {
                    navigate(`${leftColumnAddLink}`);
                  }}
                >
                  <span className="text-center mb-1 mr-[0.5px] text-white cursor-pointer">
                    +
                  </span>
                </div>
              </div>
              {leftColumnItems.map((item: any) => {
                return (
                  <span
                    className="cursor-pointer my-0.5"
                    onClick={() => {
                      navigate(`${leftColumnEditLink}${item.id}`);
                    }}
                  >
                    {item[leftColumnItemLabelKey]}
                  </span>
                );
              })}
            </div>
            <div className="flex flex-col md:flex-[4]">
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <RenderItem
                    item={row.original}
                    onDelete={onDelete}
                    onNavigate={onNavigate}
                    onOpenThreads={onOpenThreads}
                    onDuplicate={onDuplicate}
                    onPin={onPin}
                    onResendNotification={onResendNotification}
                  />
                );
              })}
            </div>
          </div>
        ) : pinnedSection ? (
          page.map((row: any, index) => {
            let topSectionComponent = null;
            prepareRow(row);
            if (index === 0 && row.original?.pinned === true) {
              topSectionComponent = PinnedTopSectionComponent();
            } else if (
              row.original?.pinned === false &&
              index > 0 &&
              (page[index - 1].original as any)?.pinned === true
            ) {
              topSectionComponent = PinnedBottomSectionComponent();
            }
            return (
              <>
                {topSectionComponent}
                <RenderItem
                  item={row.original}
                  onDelete={onDelete}
                  onNavigate={onNavigate}
                  onOpenThreads={onOpenThreads}
                  onDuplicate={onDuplicate}
                  onPin={onPin}
                  onResendNotification={onResendNotification}
                />
              </>
            );
          })
        ) : (
          page.map((row: any) => {
            prepareRow(row);
            return (
              <RenderItem
                item={row.original}
                onDelete={onDelete}
                onNavigate={onNavigate}
                onOpenThreads={onOpenThreads}
                onDuplicate={onDuplicate}
                onPin={onPin}
                onResendNotification={onResendNotification}
              />
            );
          })
        )}

        {page.length > 0 && controlledPageCount !== 1 && (
          <StickyWrapper isFooter>
            <Pagination
              goToPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              totalPage={controlledPageCount}
              disabled={isFetching}
              // new
              pageIndex={pageIndex}
              itemsPerPage={itemsPerPage}
              itemsCount={itemsCount}
            />
          </StickyWrapper>
        )}
      </div>

      {isFetching === true && (
        <div className="w-full fixed left-72 max-main-box top-thirty-vh">
          <PageLoading customStyles="absolute top-[75px] left-[calc(50%-100px)] w-[200px] rounded-full h-16 mx-auto flex justify-center items-center bg-slate-100/80 z-10" />
        </div>
      )}
    </div>
  );
}

export default FilteredList;
