import { useTranslation } from "react-i18next";
import Button from "common/components/Button/Button";

interface IHeaderButtons {
  onClose?: () => void;
  onOpenThread?: () => void;
  isClosing?: boolean;
  onPublish?: () => void;
}
function HeaderButtons({
  onClose,
  onOpenThread,
  isClosing,
  onPublish,
}: IHeaderButtons) {
  const { t } = useTranslation();
  return (
    <div className="flex gap-1 items-center justify-self-end">
      {onClose && (
        <Button
          title={t("actions.close")}
          type="neutral"
          icon="BsClipboardX"
          onClick={onClose}
          loading={isClosing}
        />
      )}
      {onOpenThread && (
        <Button
          title={t("actions.openThread")}
          type="neutral"
          icon="IoRefreshCircle"
          onClick={onOpenThread}
          loading={isClosing}
        />
      )}
      {onPublish && (
        <Button
          title={t("actions.publishThread")}
          type="primary"
          icon="IoMdMegaphone"
          onClick={onPublish}
        />
      )}
    </div>
  );
}

export default HeaderButtons;
