import classNames from "classnames";
import { SiteUser as SiteUserType } from "types";

import Icon from "components/Icon/Icon";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import Avatar from "../Avatar/Avatar";

interface ISiteUser {
  item: SiteUserType;
}
function SiteUser({ item }: ISiteUser) {
  const { isUser, jobPosition } = item;
  const element = isUser ? item.user : item.contact;
  const name = isUser ? item.user?.name : item.contact?.name;
  const image = element?.avatar;

  const getAvatar = useCallback(
    (username: string, avatar: any) => (
      <Avatar name={username} image={avatar} size="14" />
    ),
    []
  );

  return (
    <div className="flex p-3 border mt-2 border-slate-200 rounded-lg gap-3 items-center">
      <div className="h-14 w-14">{getAvatar(name, image)}</div>
      <div className="w-full flex flex-col">
        <div className="font-bold text-primary">{name}</div>
        <div className="font-bold text-primary/60">{jobPosition?.name}</div>

        {!isUser && item.contact?.notes && (
          <div className="flex gap-1 text-secondary uppercase text-sm mb-1 leading-4 items-center">
            <Icon name="UserIcon" className="w-4 h-4" />
            {item.contact?.notes}
          </div>
        )}

        <div className="flex gap-2">
          {element?.phoneNumber && (
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = `tel:${element?.phoneNumber}`;
                e.preventDefault();
              }}
              className="flex gap-1 items-center underline hover:text-purple-800"
            >
              <Icon name="PhoneIcon" className="w-4 h-4" />
              {element?.phoneNumber}
            </Link>
          )}

          {element?.email && (
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = `mailto:${element?.email}`;
                e.preventDefault();
              }}
              className={classNames(
                "flex gap-1 items-center hover:underline  hover:text-purple-800",
                element?.phoneNumber && "pl-2 border-l border-l-slate-200"
              )}
            >
              <Icon name="EnvelopeIcon" className="w-4 h-4" />
              {element?.email}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default SiteUser;
