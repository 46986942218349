import dayjs from "dayjs";
import { orderBy } from "lodash";

import { UserCircleIcon } from "@heroicons/react/24/outline";

import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import truncateString from "common/utils/truncateString";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import CommentItemSmall from "common/components/CommentItem/CommentItemSmall";
import { CgAttachment } from "react-icons/cg";
import AvatarsStack from "common/components/AvatarsStack/AvatarsStack";
import { Tag } from "types/Tag";
import TooltipWrapper from "common/components/TooltipWrapper/TooltipWrapper";
import ThreadIcon from "../../common/components/PostsAndActivities/ThreadIcon";
import Icon from "../../components/Icon/Icon";
import Status from "../../common/components/Status/Status";

function ThreadItem({ item }: any) {
  const { t } = useTranslation();

  const { messages, createdAt, updatedAt, status } = item as any;

  const messagesNumber = messages.length;
  const lastMessage = messages[messages.length - 1];

  const firstMessage = messages[0];
  const { content, author } = firstMessage;

  const privateMessages = messages.reduce((acc: any, message: any) => {
    if (message.privateThreadIntern?.messages?.length) {
      return [...acc, ...(message.privateThreadIntern?.messages || [])];
    }
    return acc;
  }, []);
  const lastPrivateMessage = orderBy(privateMessages, "createdAt", "desc")[0];

  return (
    <Link to={`/threads/${item.id}`}>
      <div className="responsive-flex-lg px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 hover:border-primary transition-all">
        <div className="flex mb-4 lg:mb-0 flex-1 w-full">
          {/* very left */}
          <div className="lg:border-r mr-2 pr-3 relative">
            <div className="responsive-flex-lg relative top-1 w-16 h-16">
              <div className="rounded-lg overflow-hidden">
                <ImageComponent
                  image={
                    item.site?.logo || item.site?.mainImage
                      ? ImageFromStrapiMedia(
                          item.site.logo ?? item.site.mainImage
                        )?.uri
                      : undefined
                  }
                />
              </div>
            </div>

            <div className="flex items-center gap-2 mr-2 absolute left-[-8px] top-[-8px]">
              <div className="text-sm">
                <ThreadIcon type={item?.type} size="small" />
              </div>
            </div>
          </div>

          {/* main */}
          <div className="flex flex-col flex-1 overflow-hidden lg:pl-2 lg:pr-4 gap-2">
            <div className="responsive-flex-lg gap-3 items-start justify-between">
              <div className="flex flex-col gap-2 w-full">
                <h4 className="text-xl font-bold text-primary leading-6 w-full max-w-[700px]">
                  <span className="font-medium">{t("threads.object")} : </span>
                  <span
                    className={
                      status !== "answered"
                        ? "font-bold text-black"
                        : "text-slate-800"
                    }
                  >
                    {content?.length > 0
                      ? truncateString(content, 90)
                      : t("threads.noType")}
                  </span>
                </h4>
                <div className="responsive-flex w-full md:items-center gap-1 text-sm">
                  <div className="flex items-center gap-1">
                    <WorkSiteIcon className="w-4 h-4 text-slate-400" />
                    <span className="font-bold">{item?.site?.name}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <UserCircleIcon className="ml-3 w-4 h-4 text-slate-400" />
                    <span className="font-bold">{author?.name}</span>,
                    {` ${
                      item?.userType?.name ? `${item?.userType?.name},` : ""
                    } `}
                    {dayjs(createdAt).fromNow()}
                  </div>
                  {Boolean(item?.tags?.length) && (
                    <div className="flex items-center gap-1 pl-2 border-l ml-2 border-slate-200">
                      <TooltipWrapper
                        className="mr-1 my-1"
                        content={
                          item?.tags?.length > 3 ? (
                            <div className="flex flex-col gap-2">
                              {item?.tags?.map((tag: Tag) => (
                                <span>{tag.label}</span>
                              ))}
                            </div>
                          ) : null
                        }
                      >
                        <div className="flex flex-row items-center">
                          <Icon name="TagIcon" className="w-4 h-4 mr-2" />
                          {item?.tags?.map((tag: Tag, index: number) =>
                            index < 3 ? (
                              <span>{(index > 0 ? ", " : "") + tag.label}</span>
                            ) : null
                          )}

                          {item?.tags?.length > 3 && <div>...</div>}
                        </div>
                      </TooltipWrapper>
                    </div>
                  )}
                  {Boolean(item?.assignedUsers?.length) && (
                    <div className="flex items-center gap-1 border-l ml-2 border-slate-200">
                      <AvatarsStack users={item?.assignedUsers} size="7" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {Boolean(
              lastMessage.content?.length || lastMessage.documents?.length
            ) && (
              <div className="flex flex-col  gap-2 pt-2 mt-2 border-t border-slate-200">
                <div className="text-sm flex gap-2 text-gray-500 ">
                  {messages?.length > 1 ? (
                    lastMessage.author?.role?.name === "Authenticated" ? (
                      <span className="font-bold text-yellow-600">
                        {t("threads.lastCommentReceived")}
                      </span>
                    ) : (
                      <span className="font-bold text-emerald-600">
                        {t("threads.lastCommentSent")}
                      </span>
                    )
                  ) : (
                    <span className="font-bold text-yellow-600">
                      {t("threads.lastCommentFirst")}
                    </span>
                  )}
                  {Boolean(lastMessage.content?.length) && (
                    <p>{truncateString(lastMessage.content, 90)}</p>
                  )}
                  {Boolean(lastMessage.documents?.length) && (
                    <p className="flex gap-2 items-center font-bold text-primary">
                      <CgAttachment />
                      {t("threads.joinedFiles", {
                        count: lastMessage.documents.length,
                      })}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* right */}
        <div className="flex flex-col text-xs lg:border-l lg:pl-4 min-w-[25em] border-slate-150 gap-4">
          <div className="flex flex-col w-full h-full justify-between text-slate-500 gap-3">
            <div className="flex gap-1 justify-between">
              <Status status={status} label={dayjs(updatedAt).fromNow(true)} />

              <div className="flex gap-1 items-center">
                <Icon name="ChatBubbleLeftRightIcon" className="w-4 h-4" />
                {messagesNumber}
              </div>
            </div>

            {lastPrivateMessage && (
              <CommentItemSmall comment={lastPrivateMessage} />
            )}
            {item?.name && (
              <div className="flex flex-col gap-3 mt-2">
                <div className="flex items-center gap-1 text-sm">
                  <WorkSiteIcon className="w-4 h-4" />
                  <span className="font-bold">{item?.name}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ThreadItem;
