import useClickOutside from "common/hooks/useClickOutside";
import Icon, { IconNames } from "components/Icon/Icon";
import { useRef, useState } from "react";
import ConfirmWrap, { ConfirmWrapProps } from "../ConfirmWrap/ConfirmWrap";

export type DropDownChild = {
  label: string;
  onClick: () => any;
  icon?: IconNames;
  disabled?: boolean;
  confirmMessage?: string;
};

type Props = {
  buttonComponent: React.ReactNode;
  items: DropDownChild[];
  hasActionArrow?: boolean;
};

function DropDown({ items, buttonComponent, hasActionArrow = true }: Props) {
  const [visible, setVisible] = useState<boolean>(false);
  const ref = useRef(null);
  const modalRef = useRef<ConfirmWrapProps>(null);
  const buttonRef = useRef(null);

  useClickOutside(
    ref,
    () => {
      setVisible((prev) => (prev ? false : prev));
    },
    visible,
    modalRef,
    [buttonRef]
  );

  return (
    <>
      <button
        ref={buttonRef}
        className={`font-medium rounded-lg text-sm px-0.5 ${
          hasActionArrow && "px-2.5"
        } text-center inline-flex items-center border bg-white text-slate-500 border-slate-200`}
        type="button"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        {buttonComponent ?? <>Dropdown button </>}
        {hasActionArrow && (
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        )}
      </button>

      {visible && (
        <div
          ref={ref}
          className="absolute bottom-[-115px] z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
        >
          <ul
            className="py-2 text-sm text-gray-700"
            aria-labelledby="dropdownDefaultButton"
          >
            {items?.map((child) => {
              const btn = (
                <li
                  key={child.label}
                  onClick={() => {
                    if (!child.disabled) {
                      setVisible(false);
                      child.onClick();
                    }
                  }}
                  className={`flex flex-row items-cente px-4 py-2 gap-4 ${
                    child.disabled
                      ? "bg-gray-200 text-gray-400"
                      : "hover:bg-gray-100 cursor-pointer"
                  }`}
                >
                  {child.icon && <Icon name={child.icon} className="w-4 h-4" />}
                  <span>{child.label}</span>
                </li>
              );
              if (child.confirmMessage) {
                return (
                  <ConfirmWrap
                    ref={modalRef}
                    message={child.confirmMessage}
                    key={child.label}
                  >
                    {btn}
                  </ConfirmWrap>
                );
              }
              return btn;
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export default DropDown;
