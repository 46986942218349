import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "common/components/Button/Button";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import useAuth from "common/hooks/useAuth";
import { AlertType } from "types/AlertType";

function AlertTypeItem({ item, onDelete, onNavigate }: any) {
  const { id, name, icon } = item as AlertType;

  const { user: currentUser } = useAuth();
  const { t } = useTranslation();

  const canEditItem =
    currentUser && (currentUser?.isAdmin || currentUser?.isSuperadmin);

  const detailLink = `/alert-types/edit/${id}`;

  return (
    <div className="responsive-flex-lg px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link to={detailLink} className="responsive-flex-lg flex-1">
        <div className="hidden lg:grid relative w-8 h-8">
          <div className="rounded-full lg:rounded-lg overflow-hidden">
            <ImageComponent
              image={icon ? ImageFromStrapiMedia(icon)?.uri : undefined}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 justify-start lg:px-6">
          <div className="flex gap-3">
            <div className="flex-1">
              <div className="flex flex-row justify-between">
                <div>
                  <h4 className="text-xl font-bold text-primary leading-6 mt-1">
                    {name}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-between items-end text-xs lg:border-l pl-4 lg:min-w-[22em] border-slate-150 gap-2">
        <div className="flex w-full items-center justify-end text-slate-500 gap-1">
          <Button
            type="error-line"
            icon="TrashIcon"
            compact
            onClick={() => onDelete(item.id)}
            disabled={!canEditItem}
            confirmMessage={t("actions.confirmDelete")}
          />

          <Button
            title={t("actions.edit")}
            type="warning-line"
            icon="PencilSquareIcon"
            onClick={() => onNavigate(item.id)}
            disabled={!canEditItem}
          />
        </div>
      </div>
    </div>
  );
}

export default AlertTypeItem;
