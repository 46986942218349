import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Site } from "types";
import useOrganization from "common/hooks/useOrganization";
import { Post } from "types/Post";

export type ProcessOptionsType = (options: Site[]) => {
  value: string;
  label: string;
}[];

function PostSelect(props: any) {
  const { organization } = useOrganization();

  const processOptions = (options: Post[]) =>
    options.map(({ id, title }) => ({
      value: id,
      label: title,
    }));

  const filters = [{ name: "site][organization", value: organization?.id }];
  if (props.filterOnSiteId) {
    filters.push({ name: "site][id", value: props.filterOnSiteId });
  }

  return (
    <RemoteSelect
      url="posts"
      processOptions={processOptions}
      icon="CalendarDaysIcon"
      filters={filters}
      {...props}
    />
  );
}

export default PostSelect;
