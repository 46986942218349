// import AlertsContext from 'common/contexts/alerts';
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import useList from "common/hooks/useList";
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { PhoneIcon } from "@heroicons/react/24/outline";

import { Contact } from "types";
import Header from "common/components/Header/Header";
import FilteredList from "common/components/Table/FilteredList";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import checkRights from "common/utils/checkRights";

import AlertsContext from "common/providers/alerts";
import ImportContactsModal from "common/components/ImportContactsModal/ImportContactsModal";
import ContactItem from "./ContactItem";

function Contacts() {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const canManageContact = checkRights(
    currentUser,
    "canManageContact",
    currentUser?.mySitesId,
    ["isAdmin"]
  );

  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error,
  } = useList<Contact>("contacts", {
    defaultFilters: organization?.id
      ? {
          organization: organization.id,
        }
      : {},
    defaultSort: "name:ASC",
    populate: [
      "avatar",
      "siteUsers",
      "siteUsers.site",
      "siteUsers.site.mainImage",
      "siteUsers.site.logo",
      "siteUsers.jobPosition",
    ],
    cachePrefix: `Contacts_${organization?.id || "all"}`,
  });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  useEffect(() => {
    setFilter("organization", organization?.id || false);
  }, [organization, setFilter]);

  const { removeItem } = useItem<Contact>("contacts", "");

  const { navigate } = useRouter();

  // const { isAdmin } = useAuth();

  /* const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]); */

  const handleNavigate = (id: any) => {
    navigate(`/contacts/edit/${id}`);
  };

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("forms.name"),
        filterName: "name$contains",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("forms.email"),
        filterName: "email$contains",
        columnClasses: "md:flex-1",
      },
      {
        Header: t("forms.phoneNumber"),
        filterName: "phoneNumber$contains",
        columnClasses: "md:flex-1",
      },
    ],
    [t]
  );

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.contacts")}
        subtitle={t("titleLegends.contacts")}
        btnTitle={t("newItem.contact")}
        btnSubtitle={t("newItem.contactLegend")}
        Icon={PhoneIcon}
        addLink="/contacts/add"
        addLinkDisabled={!canManageContact}
        isBeforeFilters
        canImport={currentUser?.isAdmin || currentUser?.isSuperadmin}
        importText={t("import.contacts")}
        ImportModal={ImportContactsModal}
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={ContactItem}
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
}

export default Contacts;
