import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { PostType } from "types/PostType";

const processOptions = (options: PostType[]) =>
  options.map(({ id, name, icon }) => ({
    value: id,
    label: name,
    icon,
  }));

function PostTypeSelect(props: any) {
  return (
    <RemoteSelect url="post-types" processOptions={processOptions} {...props} />
  );
}

export default PostTypeSelect;
