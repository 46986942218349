import Icon, { IconNames } from "components/Icon/Icon";
import StickyWrapper from "../StickyWrapper/StickyWrapper";

interface IEditHeader {
  icon: IconNames;
  title: string;
  subtitle: string;
  status?: any;
  inModal?: boolean;
  errorMessage?: string;
  HeaderButtonsEdit?: React.ReactElement;
  shouldBeCol?: boolean;
}

function EditHeader({
  icon,
  title,
  subtitle,
  status,
  inModal = false,
  errorMessage,
  HeaderButtonsEdit,
  shouldBeCol = false,
}: IEditHeader) {
  return (
    <StickyWrapper isHeader disabledOnMobile inModal={inModal}>
      <div className="z-50 white-box mb-6">
        <div
          className={`${
            shouldBeCol && "flex-col md:flex-col gap-4"
          } relative flex flex-col md:flex-row lg:items-stretch justify-between ${
            errorMessage ? "pb-7" : ""
          }`}
        >
          <div className="flex lg:items-center gap-4 max-w-full">
            <div className="hidden lg:flex items-center justify-center border border-primary w-14 min-w-[3.5rem] h-14 rounded-lg">
              <Icon name={icon} className="text-primary w-7 h-7" />
            </div>
            <div className="flex flex-col gap-1 justify-center items-start overflow-hidden">
              <h1 className="text-3xl font-bold text-primary leading-9">
                {title}
              </h1>
              <span className="text-sm uppercase overflow-hidden text-ellipsis max-w-full whitespace-nowrap">
                {subtitle}
              </span>
            </div>
          </div>

          <div
            className={`flex flex-col gap-2 lg:items-end ${
              status ? "lg:justify-between" : "lg:justify-end"
            }`}
          >
            {status && (
              <div className="hidden lg:flex gap-4 my-3 lg:my-0">{status}</div>
            )}

            {/* BUTTONS */}
            {HeaderButtonsEdit}
          </div>

          {errorMessage && (
            <div className="absolute -bottom-3 left-0 text-red-500">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </StickyWrapper>
  );
}

export default EditHeader;
