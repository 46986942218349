import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { User } from "types";
import useOrganization from "common/hooks/useOrganization";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

function UserSelect(props: any) {
  const { organization } = useOrganization();

  const { user } = useAuth();
  const { t } = useTranslation();

  const processOptions = useCallback(
    (options: User[]) => {
      const meIndex = options.findIndex((option) => option.id === user?.id);

      const list = options.map(({ id, name }) => ({
        value: id,
        label: id === user?.id ? t("forms.me") : name,
      }));

      if (meIndex === -1) {
        return list;
      }

      const userToMove = list[meIndex];
      userToMove.label = `${t("forms.me")} (${userToMove.label})`;
      list.splice(meIndex, 1); // Supprime l'utilisateur de son emplacement actuel
      list.unshift(userToMove); // Ajoute l'utilisateur au début du tableau

      return list;
    },
    [user, t]
  );

  return (
    <RemoteSelect
      url="users"
      processOptions={processOptions}
      filters={
        props.noPublicUsers
          ? [
              { name: "organizations][id", value: organization?.id },
              { name: "role][name][$ne", value: "Authenticated" },
            ]
          : [{ name: "organizations][id", value: organization?.id }]
      }
      sorts={["name:ASC"]}
      {...props}
    />
  );
}

export default UserSelect;
