import { ConfirmWrapProps } from "common/components/ConfirmWrap/ConfirmWrap";
import { useEffect, RefObject } from "react";

const useClickOutside = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  isOpen: boolean | null,
  modalRef?: RefObject<ConfirmWrapProps>,
  avoidingRefs?: RefObject<T>[]
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        if (
          !modalRef ||
          !modalRef?.current ||
          !modalRef?.current?.getModalVisible()
        ) {
          if (
            !avoidingRefs ||
            !avoidingRefs?.length ||
            avoidingRefs?.find(
              (r) =>
                !r || !r.current || !r.current.contains(event.target as Node)
            )
          ) {
            callback();
          }
        }
      }
    };

    if (isOpen) {
      document.addEventListener("mouseup", handleClickOutside);
    } else {
      document.removeEventListener("mouseup", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref, callback, isOpen, modalRef, avoidingRefs]);
};

export default useClickOutside;
