import React from "react";

function PollutionIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="511"
      height="500"
      version="1"
      viewBox="0 0 511 500"
      fill="currentColor"
      {...props}
    >
      <g>
        <path
          d="M452.9,457c-130.4,0-260.8,0-391.2,0c-6.8-3.3-8.9-8.7-8.9-16.1c0.1-125.7,0.1-251.5,0.1-377.2c0-11.5,4.1-15.6,15.6-15.6
		c125.7,0,251.4,0,377.1-0.1c7.4,0,12.9,2.1,16.1,8.9c0,130.4,0,260.9,0,391.3C458.8,451.1,455.8,454.1,452.9,457z M77,432.7
		c37.3,0,74,0,111.2,0c-0.6-1.7-1.1-3-1.6-4.3c-8-19.4-16-38.8-24-58.2c-5.3-12.9-10.7-25.8-15.9-38.7c-2.7-6.8,0.2-13.7,6.5-16.2
		s12.7,0.3,15.8,6.9c0.6,1.2,1,2.5,1.5,3.7c3.1,7.5,6.2,14.9,9.4,22.7c2.9-2,5.5-3.8,8.1-5.6c12.3-8.6,24.5-17.4,36.9-25.9
		c8-5.5,17.9-1.5,19.3,7.7c0.9,5.5-1.7,9.4-6.1,12.5c-15.1,10.6-30.2,21.3-45.3,31.9c-1.2,0.8-2.3,1.7-3.4,2.6
		c8,19.5,16,38.6,23.7,57.8c1.2,2.9,2.6,3.6,5.6,3.6c71.5-0.1,142.9-0.1,214.4-0.1c1.4,0,2.8-0.1,4.3-0.2c0-40.2,0-80,0-120.3
		c-1.7,1.1-2.9,1.8-4.1,2.6c-11.9,7.9-23.7,15.7-35.6,23.6c-8.4,5.6-16.8,11.3-25.3,16.7c-6.5,4.1-13.7,2.7-17.5-3.2
		c-3.7-5.7-2-12.8,4.3-17c7.5-5.1,15.1-10,22.6-15c2.9-1.9,5.7-3.8,8.8-5.8c-0.9-1.5-1.6-2.8-2.4-4c-6-9.2-12.1-18.4-18.1-27.7
		c-4.2-6.6-2.9-13.7,3-17.5s12.9-2.1,17.2,4.4c5.5,8.2,10.8,16.5,16.2,24.7c1.4,2.2,2.9,4.4,4.5,6.7c8.1-5.3,15.7-10.6,23.6-15.5
		c2.5-1.6,3.2-3.3,3.2-6.2c-0.1-67.5-0.1-135-0.1-202.4c0-1.4-0.1-2.8-0.2-4.1c-22.7,0-44.9,0-67.6,0c1,1.7,1.7,3,2.4,4.2
		c7,11.9,13.9,23.9,21.1,35.7c2.9,4.7,3.2,9.3,1,14.4c-4.2,9.3-8.2,18.7-12.4,28.1c-0.9,1.9-2,3.8-3.4,5.3c-3.9,4.1-10.3,4.7-15,1.6
		c-4.9-3.2-6.8-9.1-4.5-14.7c3.1-7.5,6.3-14.9,9.8-22.2c1.3-2.7,1-4.8-0.5-7.3c-8.3-13.8-16.5-27.6-24.5-41.6
		c-1.6-2.7-3.3-3.8-6.5-3.8c-85.2,0.1-170.3,0.1-255.5,0.1c-1.4,0-2.8,0-4.5,0c0,41.6,0,82.8,0,124.5c1.6-0.9,2.8-1.6,4-2.3
		c24.6-15.3,49.3-30.5,73.9-45.9c5.5-3.5,10.6-3.8,16.5-0.7c14.8,7.8,29.8,15.3,44.7,22.9c1.3,0.6,2.5,1.2,3.9,1.8
		c5.1-7.1,10.1-13.8,14.9-20.7c3.1-4.4,7.1-6.5,12.5-6.4c13,0.2,26.1,0,39.1,0.1c1.6,0,3.2,0.1,4.7,0.5c5.8,1.7,9.1,7.2,8.3,13.3
		c-0.8,5.9-5.6,10.1-11.9,10.2c-10.9,0.1-21.8,0-32.7,0.1c-1.4,0-3.2,0.6-4,1.6c-3.2,3.9-6,8.1-9.1,12.4c2.2,1.2,4.2,2.2,6.2,3.3
		c13.6,7,27.2,13.8,40.8,20.9c5.6,2.9,7.9,7.9,6.7,13.6c-1,5-5.3,9.1-10.7,9.1c-3,0-6.3-1-9-2.4c-36-18.2-72-36.6-108-55
		c-2.5-1.3-4.3-1.3-6.8,0.2c-26.8,16.8-53.7,33.4-80.7,50c-2.6,1.6-3.5,3.3-3.5,6.3c0.1,66.1,0.1,132.3,0.1,198.4
		C77,429.5,77,430.9,77,432.7z"
        />
      </g>
    </svg>
  );
}

export default PollutionIcon;
