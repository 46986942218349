import { isArray, isEmpty } from "lodash";
import { IRadioBlockItem } from "common/components/RadioBlockItem/RadioBlockItem";

type IValue = number | number[];
interface IRadioBlock {
  items: IRadioBlockItem[];
  onChange?: (id: IValue) => void;
  multiple?: boolean;
  value?: IValue;
  Component: React.ElementType;
  horizontal?: boolean;
  noGap?: boolean;
  disabled?: boolean;
}

const getSelected = (itemValue: number, value?: IValue, multiple?: boolean) => {
  if (!multiple && !value) {
    return isEmpty(itemValue);
  }
  if (!multiple) {
    return itemValue === value;
  }

  return isArray(value) && value.includes(itemValue);
};

function RadioBlock({
  items,
  value,
  onChange,
  multiple,
  Component,
  horizontal = false,
  noGap = false,
  disabled = false,
}: IRadioBlock) {
  const onClick = (itemValue: number) => {
    if (disabled) {
      return;
    }

    if (!onChange) {
      return;
    }
    if (!multiple) {
      onChange(itemValue);
      return;
    }

    if (isArray(value) && value?.includes(itemValue)) {
      onChange(value.filter((v) => v !== itemValue));
      return;
    }

    if (isArray(value)) {
      onChange([...value, itemValue]);
    }
  };

  return (
    <div
      className={`${horizontal ? "flex flex-wrap w-full" : "flex flex-col"} ${
        !noGap && "gap-3 w-full"
      } ${disabled ? "opacity-75" : ""}`}
    >
      {items.map((item: IRadioBlockItem) => (
        <Component
          onClick={() => onClick(item.id)}
          selected={getSelected(item.id, value, multiple)}
          disabled={disabled}
          {...item}
        />
      ))}
    </div>
  );
}

export default RadioBlock;
