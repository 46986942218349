/* eslint-disable no-console */
import useRouter from "common/hooks/use-router";
import fetchJSON from "common/utils/fetchJSON";
import {
  getStoredData,
  removeStoredData,
  storeData,
} from "common/utils/fnAsyncStorage";
import { Constants } from "constants/Constants";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SiteUser } from "types";
import { User } from "../../types/User";
// import useNotification from './NotificationProvider';

type Auth = {
  user?: User;
  token?: string;
  signin: (identifier: string, password: string) => Promise<boolean | User>;
  refreshUser: () => Promise<User>;
  register: (values: any) => Promise<boolean>;
  updateUser: (payload: any) => Promise<void>;
  isFetching: boolean;
  isSignInError: boolean;
  isUnauthorized: boolean;
  errorMessage: string | undefined;
  cleanError: () => void;
  signout: (userId?: string | number) => Promise<void>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (password: string, code: string) => Promise<boolean>;
  emailErrorMessage: string;
  emailDone: boolean;
  setEmailDone: any;
};

export const AuthContext = createContext<Auth>({} as Auth);

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const { t } = useTranslation();
  const [user, setUser] = useState<User>();
  const [currentJwt, setCurrentJwt] = useState<string | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [isSignInError, setIsSignInError] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [emailDone, setEmailDone] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const { navigate } = useRouter();
  // const { pushToken } = useNotification();

  const signout = useCallback(
    async (/* userId?: string | number */) => {
      /* if (pushToken && userId) {
        fetchJSON({
          url: `users/${userId}`,
          method: 'PUT',
          payload: { pushToken: '' },
        });
      } */
      setUser(undefined);
      setCurrentJwt(undefined);
      await removeStoredData("token");
      await removeStoredData("user");
      await removeStoredData("organization");
      /* navigate("/login"); */
    },
    []
  );

  const refreshUser = useCallback(async () => {
    try {
      setIsFetching(true);
      const token = await getStoredData("token");

      if (token) {
        const res = await fetchJSON({
          url: "users/me",
          method: "GET",
        });
        if (res) {
          if (
            !["SuperAdmin", "Administrateur", "Pro"].includes(res.role?.name)
          ) {
            signout();
            navigate("/login");
            setIsUnauthorized(true);
            return null;
          }

          /** *********************************************************** MAIN ROLES ****** */
          res.isSuperadmin = res.role?.name === "SuperAdmin";
          res.isAdmin = res.role?.name === "Administrateur";
          // res.isAdmin = true; // set true to hack it
          // res.isAuthor = res.role?.name === "Auteur";
          res.isPro = res.role?.name === "Pro";
          // res.isAuthenticated = res.role?.name === "Authenticated";
          res.isPublic = res.role?.name === "Public";

          res.checkMyRight = (
            siteId: number,
            field: string,
            jokerRoles: string[]
          ) => {
            return (
              !!res?.siteUsers?.find(
                (su: any) =>
                  su.site.id === siteId && su.jobPosition[field] === true
                // J'ai ajouté ça car les valeurs à null passaient dedans
              ) ||
              !!["isSuperadmin", ...jokerRoles]?.find(
                (jr: string) => res[jr] === true
              )
            );
          };

          res.mySitesId = res.siteUsers?.map((su: SiteUser) => su.site.id);

          if ((res.isAdmin || res.isPro) && !res.organizations[0]) {
            /** TODO: Alert ? */
            throw new Error("No organization assigned to this user !");
          }

          setUser(res);
          setCurrentJwt(token);
          return res;
        }
        signout();
      }

      return false;
    } catch (e) {
      // if users/me is not accessible then the token expired
      signout();
      navigate("/login");
      setIsUnauthorized(true);
      return false;
    } finally {
      setIsFetching(false);
    }
  }, [navigate, signout]); // pushToken

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  const signin = useCallback(
    async (email: string, password: string): Promise<boolean | User> => {
      const payload = {
        identifier: email,
        password,
      };

      setIsSignInError(false);
      setIsUnauthorized(false);
      setIsFetching(true);
      try {
        const res = await fetch(`${Constants.API_URL}/auth/local`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const resJson = await res.json();

        if (res.status === 200) {
          try {
            await storeData("token", resJson.jwt);
            setCurrentJwt(resJson.jwt);
            const u = await refreshUser();

            return u;
          } catch {
            setIsSignInError(true);
            setErrorMessage(resJson.msg_err);
            return true;
          }
        }
        setIsSignInError(true);
        setErrorMessage(resJson.msg_err);
        return true;
      } catch {
        setIsSignInError(true);
        return true;
      } finally {
        setIsFetching(false);
      }
    },
    [refreshUser]
  );

  const register = useCallback(
    async (values: User): Promise<boolean> => {
      const payload = {
        ...values,
      };

      setIsFetching(true);
      try {
        const res = await fetch(`${Constants.API_URL}/auth/local/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const resJson = await res.json();
        if (res.status === 200) {
          return resJson;
        }
        let errorMsg: any = "Erreur serveur";
        if (resJson?.error?.message === "Email is already taken") {
          errorMsg = t("auth.emailAlreadyTaken");
        } else if (
          resJson?.error?.message ===
          "An error occurred during account creation"
        ) {
          errorMsg = t("auth.pseudoAlreadyTaken");
        }
        throw new Error(errorMsg);
      } finally {
        setIsFetching(false);
      }
    },
    [t]
  );

  const cleanError = useCallback(() => {
    setIsSignInError(false);
    setIsUnauthorized(false);
    setErrorMessage(undefined);
  }, []);

  const updateUser = useCallback(
    async (payload: any) => {
      setIsFetching(true);
      try {
        await fetchJSON({
          url: `users/${user?.id}`,
          method: "PUT",
          payload,
        });

        refreshUser();
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    },
    [user, refreshUser]
  );

  const forgotPassword = useCallback(
    async (email: string): Promise<boolean> => {
      const payload = {
        email,
        origin: import.meta.env.VITE_BACKOFFICE_URL,
      };

      setIsFetching(true);
      try {
        const res = await fetch(`${Constants.API_URL}/auth/forgot-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const resJson = await res.json();
        if (res.status === 200) {
          setEmailDone(true);
          setEmailErrorMessage("");
          return false;
        }
        console.log("Erreur lors de l'envoi");
        setEmailErrorMessage(t("common.emailError"));
        setErrorMessage(resJson.msg_err);

        return true;
      } catch {
        setEmailErrorMessage(t("common.emailError"));
        console.log("Erreur lors de l'envoi");
        return true;
      } finally {
        setIsFetching(false);
      }
    },
    [t]
  );

  const resetPassword = useCallback(
    async (password: string, code: string): Promise<boolean> => {
      const payload = {
        code,
        password,
        passwordConfirmation: password,
      };

      setIsFetching(true);
      try {
        const res = await fetch(`${Constants.API_URL}/auth/reset-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        await res.json();
        if (res.status === 200) {
          return false;
        }
        throw new Error("Erreur lors du changement de mot de passe");
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  const value: Auth = useMemo(
    () => ({
      user,
      token: currentJwt,
      signin,
      refreshUser,
      register,
      updateUser,
      isFetching,
      isSignInError,
      isUnauthorized,
      errorMessage,
      cleanError,
      signout,
      forgotPassword,
      resetPassword,
      emailErrorMessage,
      emailDone,
      setEmailDone,
    }),
    [
      user,
      currentJwt,
      signin,
      refreshUser,
      register,
      updateUser,
      isFetching,
      isSignInError,
      isUnauthorized,
      errorMessage,
      cleanError,
      signout,
      forgotPassword,
      resetPassword,
      emailErrorMessage,
      emailDone,
      setEmailDone,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
