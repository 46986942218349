import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import PostTypeForm from "./PostTypeForm";

function PostType() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { updateItem, item, error, isFetching }: any = useItem(
    "post-types",
    id,
    {
      populate: ["imageLightMode", "imageDarkMode"],
    }
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/post-types");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataPostType: any) => {
      const postType = { ...dataPostType };

      try {
        if (postType.id) {
          await updateItem(postType.id, postType);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back]
  );

  return isFetching || (id && !item) ? null : (
    <PostTypeForm onSubmit={onSubmit} postType={item} />
  );
}

export default PostType;
