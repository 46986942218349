import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AlertsContext from "common/providers/alerts";
import UserTypeForm from "./UserTypeForm";

function UserType() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    "user-types",
    id,
    { populate: ["icon", "organization"] }
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/user-types");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataUserType: any) => {
      const userType = { ...dataUserType };

      try {
        if (userType.id) {
          await updateItem(userType.id, userType);
        } else {
          await saveItem(userType);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <UserTypeForm onSubmit={onSubmit} userType={item} />
  );
}

export default UserType;
