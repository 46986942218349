// import Card from 'common/components/Card/Card';
import Button from "common/components/Button/Button";
import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import RadioBlockItem, {
  IRadioBlockItem,
} from "common/components/RadioBlockItem/RadioBlockItem";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import useRouter from "common/hooks/use-router";
import useAuth from "common/hooks/useAuth";
import useList from "common/hooks/useList";
import useOrganization from "common/hooks/useOrganization";
import getDefaultFilters from "common/utils/getDefaultFilters";
import truncateString from "common/utils/truncateString";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { ContactGroup } from "types/ContactGroup";
import { SiteType } from "types/SiteType";
import { ThreadType } from "types/ThreadType";
import arrayMutators from "final-form-arrays";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import { isTrue } from "common/utils/getEnv";
import imgSizes from "conf/imgSizes.json";
import getWording from "common/utils/wording";

function OrganizationForm({ onSubmit, organization }: any) {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();
  const { organization: currentOrganization } = useOrganization();

  const defaultFilters = getDefaultFilters({
    currentUser,
    sitesIdsPath: "site][id",
  });

  const {
    items: contactGroups,
    // fetchItems: fetchContacts,
    // setFilter: setFilterContact,
  } = useList<ContactGroup>("contact-groups", {
    populate: ["site"],
    defaultFilters,
    defaultItemsPerPage: 5000,
    cachePrefix: `OrganizationAllowedContactGroupForm_${
      currentOrganization?.id || "all"
    }`,
  });

  const contactGroupsList = useMemo(
    () =>
      contactGroups?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
    [contactGroups]
  );

  const initialValues = useMemo(
    () =>
      organization?.id
        ? {
            ...organization,
            threadTypes:
              organization?.threadTypes?.map((type: any) => type.id) || [],

            siteTypes:
              organization?.siteTypes?.map((type: any) => type.id) || [],
            allowedGroups: organization?.allowedGroups?.map(
              (a: ContactGroup) => a.id
            ),
          }
        : {
            name: "",
            vocabularySite: {
              Sg_main: "site",
              Pl_main: "sites",
            },
            threadTypes: [],
            siteTypes: [],
            front_url: import.meta.env.VITE_FRONT_URL,
            isPublicByDefault: true,
          },
    [organization]
  );

  const { items: threadTypes } = useList<ThreadType>("thread-types", {
    cachePrefix: `ThreadTypes-in-organizationEdit_${organization?.id || "all"}`,
    populate: ["imageLightMode", "imageDarkMode"],
  });
  const { items: siteTypes } = useList<SiteType>("site-types", {
    cachePrefix: `SiteTypes-in-organizationEdit_${organization?.id || "all"}`,
  });

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  const threadTypesOptions: IRadioBlockItem[] =
    threadTypes?.map((type: ThreadType) => ({
      id: parseInt(type.id, 10),
      imageLightMode: type.imageLightMode
        ? ImageFromStrapiMedia(type.imageLightMode)?.uri
        : undefined,
      imageDarkMode: type.imageDarkMode
        ? ImageFromStrapiMedia(type.imageDarkMode)?.uri
        : undefined,
      title: type.name,
      subtitle: "",
      color: type.mainColor,
    })) || [];

  const siteTypesOptions: IRadioBlockItem[] =
    siteTypes?.map((type: SiteType) => ({
      id: parseInt(type.id, 10),
      icon: type.icon,
      titleKey: t(`sites.type_${type.name}`),
      subtitleKey: type.shouldBeDisplayedInApp
        ? type.isGeo
          ? t("sites.isGeo")
          : t("sites.isNotGeo")
        : t("sites.isHidden"),
      color: type.color,
    })) || [];

  const removeContactGroup = (form: any, values: any, deleteId: number) => {
    const newContactGroups = values.allowedGroups.filter(
      (cgId: number) => cgId !== deleteId
    );
    form.mutators.setFieldValue({
      field: "allowedGroups",
      value: newContactGroups,
    });
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({ handleSubmit, values, form }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(
                currentOrganization?.id
                  ? "editItem.myOrganization"
                  : organization?.id
                  ? "editItem.organization"
                  : "newItem.organization"
              )}
              subtitle={values.name}
              icon="BuildingOfficeIcon"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={
                    currentOrganization?.id
                      ? null
                      : () => navigate("/organizations")
                  }
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex-lg gap-2 mt-2">
                <div className="lg:flex-1 flex flex-col gap-2">
                  <div className="flex flex-row gap-4 white-box">
                    <div className="responsive-flex gap-4">
                      <FormControl
                        name="applicationLogo"
                        titleKey={t("forms.applicationLogo")}
                        type="image"
                        maxWidth={imgSizes.logo.width}
                        maxHeight={imgSizes.logo.height}
                        labelIcon="PhotoIcon"
                        className="border border-slate-200 rounded-lg overflow-hidden"
                      />
                    </div>

                    <div className="flex flex-col w-full gap-2">
                      <FormControl
                        name="name"
                        title={t("common.name")}
                        required
                      />
                    </div>
                  </div>

                  <div className="white-box flex flex-col gap-2">
                    <SectionTitle
                      // icon="CogIcon"
                      title={t("titleDividers.activity")}
                      className="text-xl"
                    />

                    <div className="">
                      <FormControl
                        type="custom"
                        title={t("vocabularySite.vocabulary")}
                        name="siteTypes"
                        inputContainerStyle="flex gap-3 w-full"
                        containerStyle="w-full"
                      >
                        <RadioBlock
                          Component={RadioBlockItem}
                          items={siteTypesOptions}
                          multiple
                          horizontal
                        />
                      </FormControl>
                    </div>

                    <div className="grid grid-1or2 gap-4 mt-2">
                      <FormControl
                        name="vocabularySite.Sg_main"
                        title={t("vocabularySite.Sg_main")}
                        placeholder={t("vocabularySite.Sg_main_ex")}
                      />

                      <FormControl
                        name="vocabularySite.Pl_main"
                        title={t("vocabularySite.Pl_main")}
                        placeholder={t("vocabularySite.Pl_main_ex")}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="white-box flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <SectionTitle
                          // icon="StarIcon"
                          title={t("forms.diffusion")}
                          className="mb-2 text-xl"
                        />
                        {currentUser?.isSuperadmin && (
                          <div className="flex items-center">
                            <FormControl type="custom" name="isPublicByDefault">
                              <ToggleSwitch
                                rightLabel={t("common.isPublicByDefault")}
                              />
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="white-box flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <SectionTitle
                          // icon="StarIcon"
                          title={t("forms.modules")}
                          className="mb-2 text-xl"
                        />
                        {currentUser?.isSuperadmin && (
                          <div className="flex items-center">
                            <FormControl type="custom" name="activeModuleSms">
                              <ToggleSwitch
                                rightLabel={t("forms.activeModuleSms")}
                              />
                            </FormControl>
                          </div>
                        )}
                      </div>

                      <div className="flex items-center">
                        <FormControl type="custom" name="activeModuleMap">
                          <ToggleSwitch
                            rightLabel={t("forms.activeModuleMap", {
                              wording: getWording(true, false),
                            })}
                          />
                        </FormControl>
                      </div>
                      <FormControl type="custom" name="disableThreads">
                        <ToggleSwitch rightLabel={t("forms.disableThreads")} />
                      </FormControl>

                      {!values?.disableThreads && (
                        <div className="flex flex-col gap-2 mt-2">
                          <SectionTitle
                            // icon="CogIcon"
                            title={t("forms.threadTypes")}
                          />
                          <div className="mt-[-0.5rem]">
                            <FormControl
                              type="custom"
                              name="threadTypes"
                              inputContainerStyle="flex gap-3 w-full mt-0"
                              containerStyle="w-full"
                            >
                              <RadioBlock
                                Component={RadioBlockItem}
                                items={threadTypesOptions}
                                multiple
                                horizontal
                              />
                            </FormControl>
                          </div>
                        </div>
                      )}
                    </div>

                    {isTrue("VITE_IS_LOGIN_MANDATORY_APP") && (
                      <div className="white-box flex flex-col gap-2">
                        <SectionTitle
                          // icon="CogIcon"
                          title={t("titleDividers.diffusion")}
                          className="mb-2 text-xl"
                        />

                        <div
                          className={`${
                            values?.allowedGroups?.length > 0 ? "mb-0" : "mb-4"
                          }`}
                        >
                          <FormControl
                            title={t("forms.allowedGroups")}
                            type="select"
                            labelIcon="WorkSiteIcon"
                            options={contactGroupsList}
                            name="allowedGroups"
                            multiple
                          />
                        </div>

                        {/* TODO <div className="flex-1 mt-2 xl:mt-4">
                             <FormAddButton
                               label={t("newItem.contact")}
                               onClick={() => setCreateContact(true)}
                             />
                        </div> */}
                        {values?.allowedGroups?.length > 0 && (
                          <div className="my-4 grid grid-cols-1 lg:grid-cols-3 gap-2">
                            {values?.allowedGroups.map((cgId: number) => {
                              const cg = contactGroups?.find(
                                (c: ContactGroup) => c.id === cgId
                              );

                              return (
                                cg && (
                                  <div className="responsive-flex gap-4 items-center justify-start border bg-slate-50 border-slate-200 p-3 rounded-lg text-slate-700">
                                    <div className="flex w-full gap-4">
                                      {/* middle */}
                                      <div className="flex flex-col xl:flex-row w-full">
                                        <div className="flex flex-col flex-1">
                                          <h4 className="text-xl font-bold text-primary leading-6">
                                            {cg?.name}
                                          </h4>

                                          {cg?.site && (
                                            <div className="flex items-center gap-1 text-sm">
                                              <WorkSiteIcon className="w-4 h-4 text-slate-400" />
                                              <span className="font-bold">
                                                {cg?.site?.name}
                                              </span>
                                            </div>
                                          )}
                                          {cg?.type && (
                                            <div className="flex flex-col  gap-2 pt-2 mt-2 border-t border-slate-200">
                                              <div className="text-sm text-gray-500">
                                                {truncateString(cg.type, 150)}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="w-full md:w-auto flex justify-end">
                                      <Button
                                        type="error-line"
                                        onClick={() =>
                                          removeContactGroup(
                                            form,
                                            values,
                                            Number(cg.id)
                                          )
                                        }
                                        icon="TrashIcon"
                                        className="px-3 py-3"
                                        compact
                                      />
                                    </div>
                                  </div>
                                )
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* !currentOrganization && currentUser?.role?.name === 'SuperAdmin' && (
                <FormInput
                  type="custom"
                  name="organization"
                  label={t('common.organization')}
                >
                  <OrganizationSelect />
                </FormInput>
                ) */}
              {/* <FormControl name="code" title={t("common.code")} required />
            <FormControl name="address" title={t("common.address")} required />
            <FormControl
              name="postalCode"
              title={t("common.postalCode")}
              required
            />
            <FormControl name="city" title={t("common.city")} required />
            <FormControl
                  name="logo"
                  title={t('common.logo')}
                  required
                /> 
            */}

              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/organizations")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default OrganizationForm;
